/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flatlist, FlatlistPlans } from '../../../components/List';
import { PackageCard, PlansCard } from '../../../components/ListItens';
import api from '../../../services/api';
import { Hr } from '../../Public/Professionals/components/styles';
import {
  MainContainer,
  MenuHeader,
  MenuOption,
  OptionText,
  ScheduleWrapper,
} from './styles';

const options = ['Pacotes', 'Planos de Assinaturas'];

export function TopMenuBar({ ...props }) {
  const { options, setSelected, selected } = props;
  return (
    <MenuHeader>
      {options?.map((item) => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => setSelected(item)}
          type="button"
        >
          <OptionText active={selected === item}>{item}</OptionText>
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

export default function PackagesPlans() {
  const [selected, setSelected] = useState('Pacotes');
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [packages, setPackages] = useState([]);

  const getPlans = useCallback(async () => {
    try {
      const { data } = await api.get('clients/appointment_subscriptions');
      if (data?.data) {
        setPlans(data.data);
      }
    } catch (error) {
      toast.error('Não foi possível carregar os planos de assinaturas ativos');
    } finally {
      setLoading(false);
    }
  }, [selected]);

  const getPackages = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/clients/client_credits');
      if (data?.data) {
        setPackages(data.data);
      }
    } catch (error) {
      toast.error('Não foi possível carregar os pacotes ativos');
    } finally {
      setLoading(false);
    }
  }, [selected]);

  useEffect(() => {
    getPlans();
    getPackages();
  }, [getPlans, getPackages]);

  const handleDelete = useCallback(async (id) => {
    setLoading(true);
    try {
      await api.delete(`clients/appointment_subscriptions/${id}`);
      toast.success('Plano de assinatura cancelado com sucesso');
      getPlans();
    } catch (error) {
      toast.error('Não foi possível excluir o plano de assinatura');
      setLoading(false);
    }
  }, []);

  return (
    <MainContainer>
      <TopMenuBar
        options={options}
        setSelected={setSelected}
        selected={selected}
      />

      <Hr />

      {selected === 'Pacotes' && (
        <ScheduleWrapper>
          <Flatlist
            data={packages}
            dateSearch
            filterObject={(packageData) => packageData.attributes.search}
            placeholder="Buscar Pacotes"
            renderItem={(packageData, index) => (
              <PackageCard
                key={`${packageData.id} ${index}`}
                packageData={packageData.attributes}
              />
            )}
            loading={loading}
          />
        </ScheduleWrapper>
      )}

      {selected === 'Planos de Assinaturas' && (
        <ScheduleWrapper>
          <FlatlistPlans
            data={plans}
            dateSearch
            filterObject={(plan) => plan.attributes.search}
            placeholder="Buscar Planos de Assinaturas"
            renderItem={(plan, index) => (
              <PlansCard
                key={`${plan.id} ${index}`}
                plan={{ ...plan.attributes, id: plan.id }}
                handleDelete={handleDelete}
              />
            )}
            loading={loading}
          />
        </ScheduleWrapper>
      )}
    </MainContainer>
  );
}
