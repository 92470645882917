import React, { Fragment, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { StyledPage } from './styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function AllPages({ ...props }) {
  const { pdf } = props;

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <StyledPage>
      <Document
        file={pdf}
        onLoadError={console.error}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Fragment key={`page_${index + 1}`}>
            <Page
              size="A4"
              key={`page_${index + 1}`}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              pageNumber={index + 1}
              height={window.innerHeight}
              width={window.innerWidth}
            />
            <p
              style={{
                fontFamily: 'Poppins',
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'right',
                width: '90%',
              }}
            >
              Página {index + 1} de {numPages}
            </p>
          </Fragment>
        ))}
      </Document>
    </StyledPage>
  );
}
