/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Modal } from '@material-ui/core';
import { Typography } from '@mui/material';
import { FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PDF from '../../../assets/contrato_profissional_allminds_2024.pdf';
import AllPagesPDFViewer from '../../../components/AllPagesPDF';
import useAuth from '../../../hooks/auth';
import IconWarning from '../../../images/alert-triangle-icon.svg';
import HeaderPublic from '../../../screens/Public/Header';
import { TermTitle } from '../../../screens/Register/AcceptanceTerm/styles';
import api from '../../../services/api';
import { TextError } from '../../../styles/globalStyles';
import FormikControl from '../../FormikControl';
import LoaderSpinner from '../../LoaderSpinner';
import { ModalAlert } from '../ModalAlerts';
import {
  BoxWrapper,
  ButtonWrapper,
  Icon,
  IconArea,
  InputWrapper,
  Main,
  Paragraph,
  TermContainer,
  TitleWrapper,
} from './styles';

export function AcceptanceNewTerm({ ...props }) {
  const { user, deleteProfessional } = useAuth();
  const { form } = props;
  const { errors, touched, setFieldValue, handleSubmit, values } = form;
  const [loading, setLoading] = useState(true);
  const [notReadTerm, setNotReadTerm] = useState(true);
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPDF = async () => {
      try {
        const { data } = await api.get('/terms', {
          signal: controller.signal,
        });
        if (data.data) {
          setFieldValue('termId', data.data.attributes.id);
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar o termo');
      } finally {
        setLoading(false);
      }
    };
    fetchPDF();

    return () => controller.abort();
  }, []);

  return (
    <Main>
      <HeaderPublic term />
      <InputWrapper>
        <FormikProvider value={form}>
          <TermTitle>Termo do aplicativo</TermTitle>
          {loading ? (
            <LoaderSpinner />
          ) : (
            <TermContainer
              onScroll={({ target }) => {
                const { scrollHeight, scrollTop, clientHeight } = target;
                const READ =
                  scrollHeight - Math.ceil(scrollTop + clientHeight) <= 10;
                if (READ) {
                  setNotReadTerm(false);
                  setFieldValue('read', true);
                }
              }}
            >
              <AllPagesPDFViewer pdf={PDF} />
            </TermContainer>
          )}
          <Grid
            alignItems="flex-start"
            container
            direction="column"
            justifyContent="flex-start"
            sx={{ mb: 2, ml: 2 }}
          >
            <ButtonWrapper>
              <FormikControl
                labelSize="0.9rem"
                labelWeight="600"
                labelFamily="Poppins"
                control="checkbox"
                name="read"
                label="Li o termo"
                disabled={notReadTerm}
              />
              <TextError>
                {errors.read && touched.read && errors.read}
              </TextError>
              <FormikControl
                labelSize="0.9rem"
                labelWeight="600"
                labelFamily="Poppins"
                shadow
                control="checkbox"
                name="accepted"
                label="Aceito o termo"
              />
              <TextError>
                {errors.accepted && touched.accepted && errors.accepted}
              </TextError>
            </ButtonWrapper>
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ width: '100%', mb: 2 }}
          >
            <Button
              sx={{ m: 1, mr: 5 }}
              onClick={() => setModalDelete(true)}
              size="small"
              color="error"
              variant="contained"
            >
              Recusar e excluir conta
            </Button>
            <Button
              sx={{ m: 1 }}
              onClick={handleSubmit}
              size="small"
              color="success"
              variant="contained"
              disabled={!values.accepted}
            >
              Finalizar
            </Button>
          </Grid>
        </FormikProvider>
      </InputWrapper>

      {modalDelete && (
        <ModalAlert
          message="Essa ação não pode ser desfeita! quer mesmo continuar?"
          goBack={() => setModalDelete(false)}
          confirm={async () => await deleteProfessional(user?.id)}
        />
      )}
    </Main>
  );
}

export default function ModalAcceptanceTerm({ ...props }) {
  const { handleModal, setNewTerm } = props;

  return (
    <Modal open>
      <BoxWrapper>
        <TitleWrapper>
          <IconArea>
            <Icon src={IconWarning} alt="Atenção" />
          </IconArea>
          <Paragraph>
            Para continuar, solicitamos que você aceite os nossos novos termos
            de uso!
          </Paragraph>
        </TitleWrapper>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Caro Profissional, realizamos atualizações nos nossos termos de
          serviço. Por favor, reserve um momento para revisar as alterações e
          aceitar os novos termos. Assim, você poderá voltar a desfrutar dos
          nossos serviços com uma experiência contínua e segura. Agradecemos
          pela sua compreensão e colaboração!
        </Typography>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          sx={{ width: '90%', mb: 2 }}
        >
          <Button
            sx={{ m: 1 }}
            onClick={() => {
              setNewTerm(true);
              handleModal(false);
            }}
            size="small"
            color="success"
            variant="contained"
          >
            Continuar
          </Button>
        </Grid>
      </BoxWrapper>
    </Modal>
  );
}
