import { ArrowUpCircle } from 'react-feather';
import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  SmallFont,
} from '../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  column-gap: 3rem;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
`;

export const ListContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const NotiflyContainer = styled.section`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color10};
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  height: 30rem;
  min-height: 500px;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0 1rem;
  }
`;

export const EmptyImage = styled.img`
  height: 250px;
  padding: 30px 0;
`;

export const NotiflyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const NotiflyContent = styled.div`
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
  padding: 1rem;
  width: 90%;
`;

export const SFontDark = styled(SmallFont)`
  color: ${({ theme, date }) => (date ? theme.primary_blue : theme.black)};
  font-weight: ${({ date }) => (date ? '900' : 'normal')};
  font-size: ${({ date }) => date && '0.7rem'};
  overflow-y: hidden;
  white-space: pre-line;
  word-wrap: break-word;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 0.6rem;
  }
`;

export const BFontBold = styled(BigFont)`
  max-width: 300px;
  max-height: 60px;
  overflow-y: hidden;
  word-wrap: break-word;
  white-space: pre-line;
  color: ${({ theme }) => theme.secondary};

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 1rem;
    max-height: 1.2rem;
  }
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const IconAreaFixed = styled(IconArea)`
  cursor: pointer;
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0.5rem;
  z-index: 1;
`;

export const IconAreaWhatsApp = styled.a`
  align-items: center;
  background: #25d366;
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  margin-bottom: 0.7rem;
`;

export const IconAreaInstagram = styled(IconAreaWhatsApp)`
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
  margin-bottom: 0;
`;

export const ArrowUpCircleIcon = styled(ArrowUpCircle)`
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.ExtralightBlack};
  color: ${({ theme }) => theme.ExtralightBlack};
  height: 2rem;
  margin-top: 2rem;
  width: 2rem;
`;
