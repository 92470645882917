export default {
  newPrimary: '#F7F7FF',
  newPrimaryAlt: '#EAECF4',
  newSecondary: '#F4F5FA',
  primary: '#426AFF',
  primaryAlt: '#F5F5F5',
  secondary: '#0C1B5C',
  third: '#02BCC8',

  // new colors
  newGray: '#999EBC',

  // blue
  primary_blue: '#426AFF',
  secondary_blue: '#0C1B5C',

  blue_1: '#0DC9FF',
  blue_1_15: '#CEF1F9',
  blue_2_100: '#426AFF',
  blue_2_80: '#6888FF',

  ExtraLightBlue: 'rgba(33, 215, 203, 0.1)',
  ExtraLightWhite: 'rgba(255, 255, 255, 0.2)',
  ExtralightBlack: 'rgba(0, 0, 0, 0.5)',
  ExtralightGray: '#BDD2D2',
  LightWhite: '#E5E5E5',
  black: '#040817',
  darkBlue: '#2C4678',
  darkGray: '#81989D',
  lightBlue: '#0DC9FF',
  lightGray: '#99B6B6',
  offWhite: '#F0F8F8',

  red: '#9e0e15',
  pink: '#FD6492',
  smallBlue: '#f7f7ff',
  white: '#FFFFFF',
  blue_link: '#0F3DDE',

  color10: '#E5EDED', // light gray bottom border
  color11: '#BDD2D2', // light gray icon
  color12: '#BCC1E0', // gray button
  colorRed: '#EB5757', // red
  colorGreen: '#27AE60', // green
  smallPurple: '#BCC1E0',
};
