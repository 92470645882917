/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SubscribeFormik } from '../../../components/FormikElements/SubscribeForm';
import { CreditCardListItem } from '../../../components/ListItens';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { ModalSubscription } from '../../../components/Modals/ModalSubscription';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { PLAN_LEVEL_CANCEL_ACCESS } from '../../../utils/constantsLevelAccess';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import { ButtonCancelPlan } from '../../Professional/ProfessionalUpdate/Subscribe/styles';
import {
  ButtonPlan,
  CardClinicContainer,
  CardClinicText,
  CardClinicTitle,
  Container,
  ContainerCardPayment,
  ContainerPayment,
  ContainerSignature,
  ListContainer,
  Main,
  MainPayment,
  PlansContainer,
} from './styles';

function ListCardPayment({ ...props }) {
  const { data, renderItem } = props;
  const [result, setResult] = useState(data);

  useEffect(() => {
    if (data) {
      data.sort((a, b) => b?.attributes?.default - a?.attributes?.default);
      setResult(data);
    }
  }, [data]);

  return (
    <Container>
      <ListContainer>{result?.map(renderItem)}</ListContainer>
    </Container>
  );
}

export function CardsList({ ...props }) {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { planType, finance } = props;
  const [loading, setLoading] = useState(true);
  const [creditCards, setCreditCards] = useState([]);
  const [filterDefaults, setFilterDefaults] = useState([]);
  const [patchCreditCard, setPatchCreditCard] = useState({});
  const [selected, setSelected] = useState(null);

  const handleDeleteCard = async (id) => {
    try {
      await api.delete(`${user.type}s/credit_cards/${id}`);
      toast.success('Cartão deletado com sucesso.');
      const newCreditCards = creditCards?.filter((item) => item.id !== id);
      setCreditCards(newCreditCards);
    } catch (error) {
      toast.error('Não foi possível deletar o cartão');
    }
  };

  const handleClickDefault = async (id) => {
    try {
      const { data } = await api.patch(
        `${user?.type}s/credit_cards/${id}/set_default`
      );
      setPatchCreditCard(data.data);
      toast.success('Cartão de crédito definido como padrão');
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao definir cartão como padrão');
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getCreditCards = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`${user.type}s/credit_cards`, {
          signal: controller.signal,
        });
        setCreditCards(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao buscar histórico de cartões');
      } finally {
        setLoading(false);
      }
    };
    getCreditCards();

    return () => controller.abort();
  }, [patchCreditCard]);

  useEffect(() => {
    setFilterDefaults(
      creditCards?.filter((item) => item.attributes.default === true)
    );
  }, [creditCards]);

  return (
    <ContainerCardPayment>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <ContainerPayment justify={finance && 'flex-start'}>
          <h3
            style={{
              fontSize: '1.3rem',
              fontWeight: '700',
              marginBottom: '0.7rem',
              width: '100%',
              textAlign: 'center',
            }}
          >
            Formas de pagamento
          </h3>
          <ListCardPayment
            data={creditCards}
            renderItem={(item, index) => (
              <CreditCardListItem
                key={index}
                data={item}
                handleClickDefault={handleClickDefault}
                handleDeleteCard={handleDeleteCard}
                filterDefaults={filterDefaults}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          />
          <ButtonPlan
            secondary={'true'}
            onClick={() =>
              history.push({
                pathname: `/${user?.type}/subscription/add_new_payment`,
                state: { planType: planType, url: location?.pathname },
              })
            }
          >
            Adicionar forma de pagamento
          </ButtonPlan>
        </ContainerPayment>
      )}
    </ContainerCardPayment>
  );
}

export function NewCardPayment() {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const { infoCardCredit } = useEvent();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    if (!infoCardCredit) {
      values.setDefault = true;
    }
    try {
      setLoading(true);
      if (
        values?.cardNumber?.length < 16 ||
        values?.cardCvv?.length < 3 ||
        values?.cardExpiration_date?.length < 4
      )
        return toast.error('Dados do cartão são requeridos.');

      const request = {
        data: {
          attributes: {
            cardNumber: values?.cardNumber,
            cardExpiration_date: values?.cardExpiration_date,
            cardHolderName: values?.cardHolderName,
            cardCvv: values?.cardCvv,
            default: values?.setDefault,
          },
        },
      };
      await api.post(`${user.type}s/credit_cards`, request);
      toast.success('Cartão cadastrado com sucesso.');
      if (location?.state?.url.includes('finances')) {
        return history.push('/clinic/finances');
      }
      return history.push({
        pathname: `/clinic/subscription/${location?.state?.planType}`,
        state: { planType: location?.state?.planType },
      });
    } catch (error) {
      toast.error('Não foi possível adicionar o cartão');
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainPayment>
      <SubscribeFormik handleContinue={handleSubmit} loading={loading} />
    </MainPayment>
  );
}

function CardClinic({ data }) {
  const { tPlanName, message, minProf, maxProf, gridArea } = data;

  return (
    <CardClinicContainer gridarea={gridArea}>
      <CardClinicTitle>{tPlanName}</CardClinicTitle>
      <CardClinicText>{message}</CardClinicText>
      <CardClinicText>{`${minProf} a ${maxProf} profissionais`}</CardClinicText>
    </CardClinicContainer>
  );
}

export default function SubscriptionPlans() {
  const history = useHistory();
  const { user } = useAuth();
  const { creditCards } = useEvent();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(0);
  const [modalCancelPlan, setModalCancelPlan] = useState(false);
  const [modalAddPlan, setModalAddPlan] = useState(false);

  const dataCardsPlans = [
    {
      tPlanName: 'Clínica Pequena',
      planName: 'plan_mini',
      message: 'para até 10 profissionais cadastrados',
      minProf: 1,
      maxProf: 10,
      gridArea: 2,
    },
    {
      tPlanName: 'Clínica Média',
      planName: 'plan_pleno',
      message: 'de 11 a 20 profissionais cadastrados ',
      minProf: 11,
      maxProf: 20,
      gridArea: 3,
    },
    {
      tPlanName: 'Clínica Grande',
      planName: 'plan_super',
      message: 'a partir de 20 profissionais cadastrados',
      minProf: 21,
      maxProf: 999,
      gridArea: 4,
    },
  ];

  useEffect(() => {
    const controller = new AbortController();
    const getSubscriptions = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`${user.type}s/plan`, {
          signal: controller.signal,
        });
        setPlan(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao buscar histórico de pagamentos');
      } finally {
        setLoading(false);
      }
    };
    getSubscriptions();

    return () => controller.abort();
  }, [user]);

  const handleCancelPlans = async () => {
    try {
      setLoading(true);
      await api.post('/clinics/subscription/cancel');
      toast.success('Plano cancelado com sucesso.');
      localStorage.setItem('user', JSON.stringify({ ...user, planLevel: 0 }));
    } catch (error) {
      toast.error('Não foi possível cancelar o plano');
    } finally {
      setModalCancelPlan(false);
      setLoading(false);
    }
  };

  const handleAddPlans = async () => {
    try {
      setLoading(true);

      const request = {
        data: {
          type: 'subscription',
          attributes: {
            creditCardId: creditCards[0].attributes.id,
          },
        },
      };
      await api.post('/clinics/subscriptions', request);
      toast.success('Plano assinado com sucesso.');
      localStorage.setItem('user', JSON.stringify({ ...user, planLevel: 1 }));
      history.push('/clinic/dashboard');
    } catch (error) {
      toast.error('Não foi possível assinar o plano!');
    } finally {
      setModalAddPlan(false);
      setLoading(false);
    }
  };

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <Main style={{ alignItems: 'flex-start' }}>
      <PlansContainer>
        <ContainerSignature>
          <p className="--signature-title">Uso corrente:</p>
          <p className="--signature-text">
            {plan?.attributes?.registeredProfessionals <= 1
              ? `${plan.attributes.registeredProfessionals} Profissional`
              : `${plan.attributes?.registeredProfessionals} Profissionais`}
          </p>
          <p className="--signature-price">
            {plan?.attributes?.registeredProfessionals &&
            !plan?.attributes?.trialing > 0
              ? convertCurrencyPtBr(plan?.attributes?.value)
              : plan?.attributes?.trialDaysLeft && (
                  <Fragment>
                    <p style={{ marginBottom: '0.5rem' }}>Teste Grátis</p>
                    <p>
                      {`${plan?.attributes?.trialDaysLeft} dia(s) restante(s)`}
                    </p>
                  </Fragment>
                )}
          </p>
          <ButtonCancelPlan
            onClick={() => {
              PLAN_LEVEL_CANCEL_ACCESS.includes(user?.planLevel)
                ? setModalAddPlan(true)
                : setModalCancelPlan(true);
            }}
            style={{
              marginTop: '2rem',
            }}
          >
            {PLAN_LEVEL_CANCEL_ACCESS.includes(user?.planLevel)
              ? 'Reativar Plano'
              : 'Cancelar Plano'}
          </ButtonCancelPlan>
        </ContainerSignature>

        {dataCardsPlans.map((dataCard) => (
          <CardClinic data={dataCard} />
        ))}
      </PlansContainer>

      {modalCancelPlan && (
        <ModalSubscription
          goBack={() => setModalCancelPlan(false)}
          message="Ao cancelar seu plano você perderá acesso a algumas funcionalidades, os profissionais não irão aparecer nas buscar do cliente e seu link personalizado deixara de funcionar. Deseja continuar?"
          confirm={handleCancelPlans}
          type="cancel"
        />
      )}

      {modalAddPlan && (
        <ModalSubscription
          goBack={() => setModalCancelPlan(false)}
          message="Ao reativar o plano a cobrança será realizada automaticamente no cartão de crédito cadastrado. Deseja continuar?"
          confirm={handleAddPlans}
        />
      )}
    </Main>
  );
}
