/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StaticButton } from '../../../../components/Button';
import FormikControl from '../../../../components/FormikControl';
import { Input } from '../../../../components/FormikElements/styles';
import { ImageCropper } from '../../../../components/ImageInput';
import {
  CheckBoxInput,
  FixedTextInput,
  NewCheckBoxInput,
} from '../../../../components/Input';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { NewSelect, SelectList } from '../../../../components/SelectList';
import useAuth from '../../../../hooks/auth';
import { brasilApi, viaCepApi } from '../../../../services/api';
import { PtBr } from '../../../../services/pt_br';
import theme from '../../../../theme';
import { PLAN_LEVEL_FULL_ACCESS } from '../../../../utils/constantsLevelAccess';
import getProfessionName from '../../../../utils/getProfessionName';
import {
  AddButton,
  ButtonLabel,
  ButtonRow,
  Escolher,
  Fechar,
  Label,
  LabelContainer,
  LabelInput,
  LabelWrapper,
  ModalContainer,
  ModalHeader,
  ModalSubTitle,
  ModalTitle,
  ModalWrapper,
  SelectButton,
  StyledModal,
} from '../../../Register/PublicProfile/styles';
import { addLeftZeros } from '../../BankAccount';
import {
  ConfigButtonContainer,
  ConfigDataForm,
  ConfigWrapper,
  ContainerAccConfig,
  ContainerImage,
  ContainerInput,
  ContainerPhoneBirth,
  ContainerTitleBank,
  ContentContainer,
  ContentLabel,
  RowLine,
  RowWrapper,
  TextError,
  TitleH1,
} from '../styles';

function InputCertificate({ form }) {
  const { values, setFieldValue, errors, touched } = form;

  useEffect(() => {
    setFieldValue('job_register', '');
  }, []);

  const storeImageAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setFieldValue('professionalDocumentFile', reader.result);
      setFieldValue('professionalDocumentFileName', file.name);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        fontFamily: 'Poppins',
        marginBottom: '1rem',
        width: '100%',
      }}
    >
      <label htmlFor="professionalDocumentFile">
        <input
          id="professionalDocumentFile"
          name="professionalDocumentFile"
          onChange={(e) => {
            let file = e.target.files[0];
            storeImageAsBase64(file);
          }}
          type="file"
          accept="image/jpeg,image/png,application/pdf,image/x-eps"
          style={{ display: 'none' }}
        />
        <AddButton variant="contained" component="span" fullWidth>
          {values?.profession === 'professional_advisor'
            ? 'Especialização em orientação profissional'
            : 'Certificação de psicopedagogia'}
        </AddButton>
      </label>
      {values?.professionalDocumentFileName && (
        <div>
          <TextField
            id="fileName"
            variant="outlined"
            value={values?.professionalDocumentFileName}
            disabled={true}
            fullWidth
          />
        </div>
      )}
      <TextError>
        {errors?.professionalDocumentFile &&
          touched?.professionalDocumentFile &&
          errors?.professionalDocumentFile}
      </TextError>
    </div>
  );
}

function InputEducationCertificate({ form }) {
  const { values, setFieldValue, errors, touched, handleBlur } = form;

  useEffect(() => {
    setFieldValue('job_register', '');
  }, []);

  const storeImageAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setFieldValue('professionalEducationDocumentFile', reader.result);
      setFieldValue('professionalEducationDocumentFileName', file.name);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        fontFamily: 'Poppins',
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '100%',
      }}
    >
      <label htmlFor="professionalEducationDocumentFile">
        <input
          id="professionalEducationDocumentFile"
          name="professionalEducationDocumentFile"
          onChange={(e) => {
            let file = e.target.files[0];
            storeImageAsBase64(file);
          }}
          onBlur={handleBlur}
          type="file"
          accept="image/jpeg,image/png,application/pdf,image/x-eps"
          style={{ display: 'none' }}
        />
        <AddButton variant="contained" component="span" fullWidth>
          Carregar Diploma
        </AddButton>
      </label>
      {values?.professionalEducationDocumentFileName && (
        <div>
          <TextField
            id="fileName"
            variant="outlined"
            value={values?.professionalEducationDocumentFileName}
            disabled={true}
            fullWidth
          />
        </div>
      )}
      <TextError>
        {errors?.professionalEducationDocumentFile &&
          touched?.professionalEducationDocumentFile &&
          errors?.professionalEducationDocumentFile}
      </TextError>
    </div>
  );
}

function InputNRegister({ form }) {
  const { user } = useAuth();
  const { values, handleChange, setFieldValue, handleBlur, errors, touched } =
    form;

  useEffect(() => {
    setFieldValue('certificate', '');
    setFieldValue('certificateFileName', '');
    setFieldValue('educationCertificate', '');
    setFieldValue('educationCertificateFileName', '');
  }, []);

  return (
    <FormikControl
      control="input"
      disabled={user?.professionalDocumentNumber}
      label={PtBr.register_input_job_register}
      labelFamily="Poppins"
      labelSize="0.9rem"
      labelWeight="600"
      name="professionalDocumentNumber"
      onBlur={handleBlur}
      onChange={handleChange}
      upLabel
      value={values?.professionalDocumentNumber}
      errors={errors?.professionalDocumentNumber}
      touched={touched?.professionalDocumentNumber}
    />
  );
}

export default function AccountConfig({ ...props }) {
  const { loading, setLoading } = props;
  const { user } = useAuth();
  const [icon, setIcon] = useState(<ContentCopyRoundedIcon />);
  const [copyLabel, setCopyLabel] = useState('Clique para Copiar');
  const history = useHistory();
  const referralLink = `${window.location.host}/ref=${user?.email}`;

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleClick = async () => {
    setIcon(<CheckRoundedIcon />);
    setCopyLabel('Copiado');

    await delay(10000);

    setIcon(<ContentCopyRoundedIcon />);
    setCopyLabel('Clique para Copiar');
  };

  return (
    <Fragment>
      {loading ? (
        <LoaderSpinner setLoading={setLoading} />
      ) : (
        <Fragment>
          <ContentContainer>
            <ContentLabel>Configurações pessoais</ContentLabel>
            <ConfigButtonContainer>
              <StaticButton
                disabled
                title="Dados básicos"
                onClick={() =>
                  history.push({
                    pathname: '/professional/availability',
                  })
                }
              />
            </ConfigButtonContainer>
            <ConfigButtonContainer>
              <StaticButton
                title="Conta Bancaria"
                onClick={() =>
                  history.push({
                    pathname: '/professional/payment_account',
                  })
                }
              />
            </ConfigButtonContainer>
          </ContentContainer>
          <ContentContainer>
            <ContentLabel>Configurações de atendimento</ContentLabel>
            <ConfigButtonContainer>
              <StaticButton
                disabled
                title="Endereço comercial"
                onClick={() =>
                  history.push({
                    pathname: '/professional/availability',
                  })
                }
              />
            </ConfigButtonContainer>
            <ConfigButtonContainer>
              <StaticButton
                title="Configurações de atendimento"
                onClick={() =>
                  history.push({
                    pathname: '/professional/availability',
                    state: { firstStep: 3 },
                  })
                }
              />
            </ConfigButtonContainer>
            <ConfigButtonContainer>
              <StaticButton
                title="Cupons"
                onClick={() =>
                  history.push({
                    pathname: '/professional/cupons',
                  })
                }
              />
            </ConfigButtonContainer>
          </ContentContainer>
          <ContentContainer>
            <ContentLabel> Link de convite para a plataforma </ContentLabel>
            <ConfigButtonContainer>
              <Button
                variant="outlined"
                sx={{ width: '100%', height: '100%', fontSize: 'medium' }}
                endIcon={icon}
                onClick={() => {
                  handleClick();
                  navigator.clipboard.writeText(referralLink);
                }}
              >
                {copyLabel}
              </Button>
            </ConfigButtonContainer>
          </ContentContainer>
        </Fragment>
      )}
    </Fragment>
  );
}

export function UpdateBasicData({
  form,
  user,
  validateInfos,
  validateNeedLegalGuardian,
}) {
  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);

  const formateDate = (date) => moment(date).format('DD/MM/YYYY');

  useEffect(() => {
    const validateInfo =
      user?.avatar === values?.avatar &&
      user?.firstName === values?.firstName &&
      user?.lastName === values?.lastName &&
      user?.socialName === values?.socialName &&
      formateDate(user?.birthday) === values?.birthday &&
      user?.documentNumber === values?.documentNumber &&
      user?.phone === values?.phone;
    const validateErrors =
      errors?.firstName ||
      errors?.lastName ||
      errors?.socialName ||
      errors?.birthday ||
      errors?.phone ||
      errors?.documentNumber;
    setValidateForm(validateErrors || validateInfo);

    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.firstName,
      values?.lastName,
      values?.birthday,
      values?.phone,
    ]?.every((value) => value === '');
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Informações Pessoais</TitleH1>
        <RowLine />
      </div>
      <ContainerImage>
        <ImageCropper
          src={values?.avatar}
          name="avatar"
          acceptType=".jpeg, .jpg"
          onCrop={(crop) => setFieldValue('avatar', crop)}
        />

        <TextError>{errors?.avatar}</TextError>
      </ContainerImage>

      <FormikControl
        control="input"
        errors={errors?.firstName}
        label="Nome"
        name="firstName"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.firstName}
        type="text"
        upLabel
        value={values?.firstName}
      />

      <FormikControl
        control="input"
        errors={errors?.lastName}
        label="Sobrenome"
        name="lastName"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.lastName}
        type="text"
        upLabel
        value={values?.lastName}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '0.5rem 0',
        }}
      >
        <CheckBoxInput
          name="hasSocialName"
          checked={values?.hasSocialName}
          onChange={() => {
            setFieldValue('hasSocialName', !values?.hasSocialName);
            setFieldValue('socialName', '');
          }}
          onBlur={handleBlur}
          label="Tem nome social?"
        />

        {values?.hasSocialName && (
          <div
            style={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '0.5rem',
              width: '100%',
            }}
          >
            <FormikControl
              upLabel
              control="input"
              name="socialName"
              value={values?.socialName}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Nome Social"
            />
          </div>
        )}
      </div>

      <ContainerPhoneBirth>
        <FormikControl
          control="input"
          label="Telefone"
          mask="(99) 99999-9999"
          name="phone"
          onChange={handleChange}
          type="text"
          upLabel
          value={values?.phone}
          errors={errors?.phone}
          onBlur={handleBlur}
          touched={touched?.phone}
        />

        <FormikControl
          control="input"
          disabled={user?.needLegalGuardian}
          errors={
            errors?.birthday ||
            (!errors?.birthday &&
              validateNeedLegalGuardian === true &&
              moment(values?.birthday, 'DD/MM/YYYY').isAfter(
                moment().subtract(18, 'years')
              ) &&
              'Você precisara cadastrar um responsável legal')
          }
          label="Data de nascimento"
          mask="99/99/9999"
          name="birthday"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched?.birthday}
          type="text"
          upLabel
          value={values?.birthday}
        />
      </ContainerPhoneBirth>

      <FormikControl
        control="input"
        disabled={user?.documentNumber}
        errors={errors?.documentNumber}
        label="CPF"
        mask="999.999.999-99"
        name="documentNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.documentNumber}
        type="text"
        upLabel
        value={values?.documentNumber}
      />
    </ConfigWrapper>
  );
}

export function UpdateAddress({ form, user, validateInfos }) {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);
  const [cep, setCep] = useState('');
  const onlyNumbers = (str) => str.replace(/[_]/g, '');

  useEffect(() => {
    const controller = new AbortController();
    const handleCEP = async () => {
      if (onlyNumbers(cep)?.length === 9) {
        try {
          const { data } = await viaCepApi.get(`${values?.cep}/json/`, {
            signal: controller.signal,
          });
          if (data) {
            setFieldValue('cep', onlyNumbers(cep));
            setFieldValue('street', data.logradouro);
            setFieldValue('complement', 'N/A');
            setFieldValue('neighborhood', data.bairro);
            setFieldValue('city', data.localidade);
            setFieldValue('state', data.uf);
            setFieldValue('country', 'Brasil');
          }
        } catch (error) {
          if (!controller.signal.aborted) toast.error('CEP inválido');
        }
      }
    };
    handleCEP();

    return () => controller.abort();
  }, [cep]);

  const validateComplement = () => {
    if (values?.complement === '') {
      setFieldValue('complement', 'N/A');
    }
  };

  useEffect(() => {
    const validateInfo =
      user?.address.street === values?.street &&
      user?.address.street_number === values?.street_number &&
      user?.address.complement === values?.complement &&
      user?.address.neighborhood === values?.neighborhood &&
      user?.address.cep === values?.cep &&
      user?.address.country === values?.country &&
      user?.address.state === values?.state &&
      user?.address.city === values?.city;
    const validateErrors = [
      errors?.street,
      errors?.street_number,
      errors?.complement,
      errors?.neighborhood,
      errors?.cep,
      errors?.country,
      errors?.state,
      errors?.city,
    ]?.some((error) => error !== undefined);
    setValidateForm(validateErrors || validateInfo);
    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.street,
      values?.street_number,
      values?.complement,
      values?.neighborhood,
      values?.cep,
      values?.country,
      values?.state,
      values?.city,
    ]?.every((value) => value === '');
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>
          {user?.type === 'client' ? 'Endereço' : 'Endereço Comercial'}
        </TitleH1>
        <RowLine />
      </div>

      <FormikControl
        control="input"
        errors={errors?.cep}
        label="CEP"
        mask="99999-999"
        name="cep"
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={({ target }) => setCep(target?.value)}
        touched={touched?.cep}
        type="text"
        upLabel
        value={values?.cep}
      />

      <FormikControl
        control="input"
        errors={errors?.street}
        label="Endereço"
        name="street"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.street}
        type="text"
        upLabel
        value={values?.street}
      />

      <FormikControl
        control="input"
        errors={errors?.street_number}
        label="Número"
        name="street_number"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.street_number}
        type="text"
        upLabel
        value={values?.street_number}
      />

      <FormikControl
        control="input"
        errors={errors?.neighborhood}
        label="Bairro"
        name="neighborhood"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.neighborhood}
        type="text"
        upLabel
        value={values?.neighborhood}
      />

      <FormikControl
        control="input"
        errors={errors?.complement}
        label={PtBr.register_input_complement}
        name="complement"
        onBlur={(e) => {
          handleBlur(e);
          validateComplement();
        }}
        onChange={handleChange}
        touched={touched?.complement}
        upLabel
        value={values?.complement}
      />

      <FormikControl
        control="input"
        errors={errors?.city}
        label="Cidade"
        name="city"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.city}
        type="text"
        upLabel
        value={values?.city}
      />

      <FormikControl
        control="input"
        errors={errors?.state}
        label="Estado"
        name="state"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.state}
        type="text"
        upLabel
        value={values?.state}
      />

      <FormikControl
        control="input"
        errors={errors?.country}
        label="País"
        name="country"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.country}
        type="text"
        upLabel
        value={values?.country}
      />
    </ConfigWrapper>
  );
}

export function UpdateLegalGuardian({ form, validateInfos, user }) {
  const { handleChange, handleBlur, values, errors, touched } = form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);

  useEffect(() => {
    const validateInfo =
      user?.legalGuardianFullName === values?.legalGuardianFullName &&
      user?.legalGuardianEmail === values?.legalGuardianEmail &&
      user?.legalGuardianDocumentNumber ===
        values?.legalGuardianDocumentNumber &&
      user?.legalGuardianBirthday === values?.legalGuardianBirthday &&
      user?.legalGuardianPhone === values?.legalGuardianPhone;
    const validateErrors =
      errors?.legalGuardianFullName ||
      errors?.legalGuardianEmail ||
      errors?.legalGuardianDocumentNumber ||
      errors?.legalGuardianBirthday ||
      errors?.legalGuardianPhone;
    setValidateForm(validateErrors || validateInfo);

    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.legalGuardianFullName,
      values?.legalGuardianEmail,
      values?.legalGuardianDocumentNumber,
      values?.legalGuardianBirthday,
      values?.legalGuardianPhone,
    ]?.every((value) => value === '');
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Responsável Legal</TitleH1>
        <RowLine />
      </div>

      <FormikControl
        control="input"
        label="Nome completo do responsável"
        name="legalGuardianFullName"
        onChange={handleChange}
        type="text"
        upLabel
        value={values?.legalGuardianFullName}
        errors={errors?.legalGuardianFullName}
        onBlur={handleBlur}
        touched={touched?.legalGuardianFullName}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianEmail}
        label="Email do responsável"
        name="legalGuardianEmail"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianEmail}
        type="email"
        upLabel
        value={values?.legalGuardianEmail}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianDocumentNumber}
        label="CPF do responsável"
        name="legalGuardianDocumentNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianDocumentNumber}
        type="text"
        upLabel
        value={values?.legalGuardianDocumentNumber}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianBirthday}
        label="Data de nascimento do responsável"
        mask="99/99/9999"
        name="legalGuardianBirthday"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianBirthday}
        type="text"
        upLabel
        value={values?.legalGuardianBirthday}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianPhone}
        label="Telefone do responsável"
        mask="(99) 99999-9999"
        name="legalGuardianPhone"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianPhone}
        type="text"
        upLabel
        value={values?.legalGuardianPhone}
      />
    </ConfigWrapper>
  );
}

export function UpdateBankAccount({ form, validateInfos }) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    form;
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [bank, setBank] = useState(null);

  useEffect(() => {
    const fetchBankCodes = async () => {
      try {
        await brasilApi
          .get()
          .then(({ data, errors }) => {
            if (errors) {
              throw new Error('Erro ao carregar bancos');
            }
            setBankList(
              data?.map((bank) => ({
                label: bank.fullName,
                value: { code: bank.code, fullName: bank.fullName },
              }))
            );

            const bankFullName = data?.filter(
              (bank) => addLeftZeros(bank.code, 3) === values?.bankCode
            )[0]?.fullName;

            setBank({
              label: bankFullName,
              value: bankFullName,
            });
          })
          .catch((error) => toast.error(error));
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBankCodes();
  }, []);

  useEffect(() => {
    const validateInfo =
      user?.paymentAccount?.agenciaDv === values?.agenciaDv &&
      user?.paymentAccount?.bankAg === values?.bankAg &&
      user?.paymentAccount?.bankCc === values?.bankCc &&
      user?.paymentAccount?.bankCode === values?.bankCode &&
      user?.paymentAccount?.contaDv === values?.contaDv;
    validateInfos(validateInfo);
  }, [values]);

  useEffect(() => {
    setFieldValue('accountType', user?.paymentAccount?.accountType);
    setFieldValue('agenciaDv', user?.paymentAccount?.agenciaDv);
    setFieldValue('bankAg', user?.paymentAccount?.bankAg);
    setFieldValue('bankCc', user?.paymentAccount?.bankCc);
    setFieldValue('bankCode', user?.paymentAccount?.bankCode);
    setFieldValue('contaDv', user?.paymentAccount?.contaDv);
    setFieldValue('paymentName', user?.paymentAccount?.name);
    setFieldValue(
      'updateBank',
      user?.paymentAccount?.pagarmeAccountId ? false : true
    );
  }, [user]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Dados Bancários</TitleH1>
        <RowLine />
      </div>

      {user?.paymentAccount?.pagarmeAccountId && (
        <ContainerTitleBank>
          <NewCheckBoxInput
            label="Atualizar dados bancários"
            checked={values?.updateBank}
            onChange={() => setFieldValue('updateBank', !values?.updateBank)}
          />
        </ContainerTitleBank>
      )}

      {loading ? (
        <p>Carregando dados bancários...</p>
      ) : (
        <Fragment>
          <SelectList
            defaultValue={bank}
            errors={errors?.bankCode}
            isDisabled={!values?.updateBank}
            label="Banco"
            name="bankName"
            onChange={(e) => {
              const { value } = e;
              const code = bankList?.filter(
                (bank) => bank.value.fullName === value
              )[0]?.value?.code;
              setFieldValue('bankCode', addLeftZeros(code, 3));
              setBank(e);
            }}
            options={bankList?.map((bank) => ({
              label: bank.label,
              value: bank.value.fullName,
            }))}
            sign="true"
            placeholder="Selecione o banco"
            touched={touched?.bankCode}
          />

          <RowWrapper>
            <FormikControl
              control="input"
              disabled={!values?.updateBank}
              errors={errors?.bankAg}
              label="Agência"
              name="bankAg"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.bankAg}
              type="text"
              upLabel
              value={values?.bankAg}
            />

            <FormikControl
              control="input"
              disabled={!values?.updateBank}
              errors={errors?.agenciaDv}
              label="Dígito"
              name="agenciaDv"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.agenciaDv}
              type="text"
              upLabel
              value={values?.agenciaDv}
            />
          </RowWrapper>

          <RowWrapper>
            <FormikControl
              control="input"
              disabled={!values?.updateBank}
              errors={errors?.bankCc}
              label="Conta"
              name="bankCc"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.bankCc}
              type="text"
              upLabel
              value={values?.bankCc}
            />

            <FormikControl
              control="input"
              disabled={!values?.updateBank}
              errors={errors?.contaDv}
              label="Dígito"
              name="contaDv"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.contaDv}
              type="text"
              upLabel
              value={values?.contaDv}
            />
          </RowWrapper>

          <ContainerInput>
            <NewSelect
              placeholder="Selecione o tipo de conta"
              isDisabled={!values?.updateBank}
              label="Tipo de conta"
              name="accountType"
              defaultValue={values?.accountType}
              onChange={(e) => {
                setFieldValue('accountType', e.value);
              }}
              options={[
                {
                  label: 'Conta Corrente',
                  value: 'conta_corrente',
                },
                {
                  label: 'Conta Poupança',
                  value: 'conta_poupança',
                },
              ]}
            />
            <TextError>
              {touched?.accountType &&
                errors?.accountType &&
                errors?.accountType}
            </TextError>
          </ContainerInput>

          <FormikControl
            name="paymentName"
            label="Nome completo do titular da conta"
            upLabel
            type="text"
            control="input"
            disabled={!values?.updateBank}
            value={values?.paymentName}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors?.paymentName}
            touched={touched?.paymentName}
          />
        </Fragment>
      )}
    </ConfigWrapper>
  );
}

export function UpdateProfessionalInfos({ form, user, validateInfos }) {
  const { setFieldValue, handleChange, handleBlur, values, errors, touched } =
    form;
  const [approachesModal, setApproachesModal] = useState(false);
  const [specializationsModal, setSpecializationsModal] = useState(false);

  const getJobsTypes = (jobName) => {
    const job = values.jobs?.find((job) => job.name === jobName);
    setFieldValue(
      'jobApproaches',
      job?.approaches?.length > 0 ? job?.approaches : ['N/A']
    );
    setFieldValue(
      'jobSpecializations',
      job?.specializations?.length > 0 ? job?.specializations : ['N/A']
    );
  };

  useEffect(() => {
    switch (values?.profession) {
      case 'Psicólogo(a)': {
        return getJobsTypes('Psicólogo(a)');
      }
      case 'Orientador(a) Profissional': {
        return getJobsTypes('Orientador(a) Profissional');
      }
      default: {
        return getJobsTypes('Psicólogo(a)');
      }
    }
  }, [values?.profession]);

  const groups = [
    { name: 'Infantil' },
    { name: 'Adulto' },
    { name: 'Adolescente' },
    { name: 'Idoso' },
  ];

  useEffect(() => {
    const validateInfo =
      JSON.stringify(user?.specializations) ===
        JSON.stringify(values?.specializations) &&
      JSON.stringify(user?.approaches) === JSON.stringify(values?.approaches) &&
      user?.sessionPrice === values?.sessionPrice &&
      user?.preferentialPayment === values?.preferentialPayment &&
      user?.bio === values?.bio &&
      user?.presentationVideo === values?.presentationVideo &&
      JSON.stringify(user?.ageGroups) === JSON.stringify(values?.ageGroups) &&
      user?.yearsOfExperience === values?.yearsOfExperience &&
      JSON.stringify(user?.academicBackground) ===
        JSON.stringify(values?.academicBackground) &&
      user?.ownMeetingLink === values?.ownMeetingLink &&
      JSON.stringify(user?.availabilityRule?.appointmentTypes) ===
        JSON.stringify(values?.appointmentTypes);

    validateInfos(validateInfo);
  }, [errors, values]);

  const verifyJob = (job) => {
    if (['Orientador(a) Profissional', 'professional_advisor'].includes(job)) {
      return (
        <Fragment>
          <InputEducationCertificate form={form} />
          <InputCertificate form={form} />
        </Fragment>
      );
    }
    if (['Orientador(a) Profissional', 'professional_advisor'].includes(job)) {
      return <InputEducationCertificate form={form} />;
    }
    if (['Psicólogo(a)', 'psychologist'].includes(job)) {
      return <InputNRegister form={form} />;
    }
  };

  const handleChangeCurrency = (_event, value) => {
    setFieldValue('sessionPrice', value);
  };

  const deleteItemArray = (item, array, type) => {
    const newArray = array.filter((i) => i !== item);
    setFieldValue(type, newArray);
  };

  return (
    <ConfigWrapper>
      <ContainerAccConfig>
        <TitleH1>Informações Profissionais</TitleH1>

        <RowLine />

        {PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel) && (
          <div style={{ width: '100%' }}>
            <TitleH1>Link Personalizado</TitleH1>

            <FormControlLabel
              name="secondaryBanner"
              control={<Checkbox checked={values?.newPreferOwnMeetingLink} />}
              label={
                <Tooltip title="Cadastre um link de uma sala de reunião de sua preferencia para utilizar nas chamadas de video. Exemplo: https://meet.google.com/ppp-nnnn-vvv">
                  <Typography>
                    Adicionar Link Personalizado para{' '}
                    <span
                      style={{
                        color: theme.pink,
                        fontWeight: 'bold',
                        marginLeft: '0.5rem',
                      }}
                    >
                      ?
                    </span>
                  </Typography>
                </Tooltip>
              }
              onChange={({ target }) => {
                setFieldValue('newPreferOwnMeetingLink', target.checked);
                setFieldValue('ownMeetingLink', '');
              }}
            />

            {values?.newPreferOwnMeetingLink && (
              <FormikControl
                control="input"
                errors={errors?.ownMeetingLink}
                label="Link da sala de reunião"
                name="ownMeetingLink"
                touched={touched?.ownMeetingLink}
                type="text"
                handleBlur={handleBlur}
                onChange={handleChange}
                upLabel
                value={values?.ownMeetingLink}
              />
            )}
          </div>
        )}

        <RowLine />

        <FormikControl
          disabled={user?.profession}
          errors={errors?.profession}
          control="select"
          defaultValue={getProfessionName(values?.profession, 'toPt')}
          id="select-profession"
          label="Profissão"
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          name="profession"
          onBlur={handleBlur}
          onChange={({ target }) => {
            setFieldValue(
              'profession',
              getProfessionName(target.value, 'toEng')
            );
            setFieldValue('specializations', []);
            setFieldValue('approaches', []);
          }}
          options={values?.jobs?.map((item) => item.name)}
          placeholder={PtBr.register_input_job}
          touched={touched?.profession}
          fullWidth
        />

        {verifyJob(values.profession)}

        <FormikControl
          control="textarea"
          id="formations"
          label={PtBr.register_input_formation}
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          name="formations"
          onBlur={handleBlur}
          onChange={(event) => {
            handleChange(event);
            setFieldValue('academicBackground', event.target.value.split('\n'));
          }}
          rows={5}
          touched={touched?.formations}
          upLabel
          value={values?.formations}
          errors={errors?.academicBackground}
        />

        {values?.profession && (
          <div
            style={{
              marginTop: '0.5rem',
              width: '100%',
            }}
          >
            <LabelInput>{PtBr.register_input_specialty}</LabelInput>
            <SelectButton
              id="specializations-button"
              type="button"
              disabled={values?.profession ? false : true}
              onClick={() => setSpecializationsModal(true)}
            >
              {PtBr.register_button_select}
            </SelectButton>
            {values?.specializations?.length > 0 && (
              <LabelContainer>
                {values?.specializations?.map((item, index) => (
                  <LabelWrapper>
                    <Label key={item + index}>{item}</Label>
                    <ButtonLabel
                      type="button"
                      onClick={() =>
                        deleteItemArray(
                          item,
                          values?.specializations,
                          'specializations'
                        )
                      }
                    >
                      X
                    </ButtonLabel>
                  </LabelWrapper>
                ))}
              </LabelContainer>
            )}
            <TextError>
              {errors?.specializations &&
                touched?.specializations &&
                errors?.specializations}
            </TextError>
          </div>
        )}

        {values?.profession && (
          <div
            style={{
              marginTop: '1.5rem',
              width: '100%',
            }}
          >
            <LabelInput>{PtBr.register_input_approaches}</LabelInput>
            <SelectButton
              id="approaches-button"
              type="button"
              disabled={values?.profession ? false : true}
              onClick={() => setApproachesModal(true)}
            >
              {PtBr.register_button_select}
            </SelectButton>
            {values?.approaches?.length > 0 && (
              <LabelContainer>
                {values?.approaches?.map((item, index) => (
                  <LabelWrapper>
                    <Label key={item + index}>{item}</Label>
                    <ButtonLabel
                      type="button"
                      onClick={() =>
                        deleteItemArray(item, values?.approaches, 'approaches')
                      }
                    >
                      X
                    </ButtonLabel>
                  </LabelWrapper>
                ))}
              </LabelContainer>
            )}
            <TextError>
              {errors?.approaches && touched?.approaches && errors?.approaches}
            </TextError>
          </div>
        )}

        <FormikControl
          upLabel
          control="input-currency"
          errors={errors?.sessionPrice}
          id="sessionPrice"
          label="Valor da sessão"
          labelWeight="700"
          name="sessionPrice"
          onChange={handleChangeCurrency}
          style={{ backgroundColor: theme.newPrimaryAlt }}
          touched={touched?.sessionPrice}
          value={Number(values?.sessionPrice)}
        />

        <FormikControl
          control="textarea"
          height="160px"
          label="Mini Bio"
          labelWeight="700"
          name="bio"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={PtBr.register_input_about_you}
          rows={5}
          touched={touched?.bio}
          type="text"
          upLabel
          value={values?.bio}
          errors={errors?.bio}
        />

        <ContainerInput>
          <NewSelect
            placeholder={PtBr.register_input_age_group}
            label={PtBr.register_input_age_group}
            name="ageGroups"
            isMulti={true}
            defaultValue={values?.ageGroups?.map((age) => ({
              label: age,
              value: age,
            }))}
            onChange={(e) => {
              setFieldValue(
                'ageGroups',
                e?.map((age) => `${age.value}`)
              );
            }}
            options={groups?.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
          />
          <TextError>
            {errors?.ageGroups && touched?.ageGroups && errors?.ageGroups}
          </TextError>
        </ContainerInput>

        <ContainerInput>
          <FixedTextInput
            type="number"
            name="yearsOfExperience"
            value={values?.yearsOfExperience}
            onChange={handleChange}
            onBlur={handleBlur}
            label={PtBr.register_input_experience_time}
            min={0}
          />
          <TextError>
            {errors?.yearsOfExperience &&
              touched?.yearsOfExperience &&
              errors?.yearsOfExperience}
          </TextError>
        </ContainerInput>

        <ContainerInput>
          <Input
            type="url"
            name="presentationVideo"
            value={values?.presentationVideo}
            onChange={handleChange}
            onBlur={handleBlur}
            label={PtBr.register_input_presetation_video}
            labelWeight="700"
            placeholder={'https://www.youtube.com/'}
          />
          <TextError>
            {errors?.presentationVideo &&
              touched?.presentationVideo &&
              errors?.presentationVideo}
          </TextError>
        </ContainerInput>
      </ContainerAccConfig>

      <StyledModal
        ariaHideApp={false}
        isOpen={specializationsModal}
        onRequestClose={() => setSpecializationsModal(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <ModalHeader>
              <ModalTitle>Especializações</ModalTitle>
              <ModalSubTitle>
                {values?.profession === 'Professor(a)'
                  ? 'Selecione até 2'
                  : 'Selecione até 8'}
              </ModalSubTitle>
            </ModalHeader>

            <FormikControl
              name="specializations"
              control="checkbox-group"
              options={values?.jobSpecializations?.map((item) => {
                return { key: item, value: item };
              })}
              disabled={values?.specializations?.length >= 8 ? true : false}
            />

            <ButtonRow>
              <Fechar
                title="Cancelar"
                onClick={() => setSpecializationsModal(false)}
              />
              <Escolher
                id="specializations-confirm"
                title="Confirmar"
                onClick={() => setSpecializationsModal(false)}
              />
            </ButtonRow>
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>

      <StyledModal
        ariaHideApp={false}
        isOpen={approachesModal}
        onRequestClose={() => setApproachesModal(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <ModalHeader>
              <ModalTitle>{PtBr.register_input_approaches}</ModalTitle>
              <ModalSubTitle>Selecione até 8</ModalSubTitle>
            </ModalHeader>

            <FormikControl
              name="approaches"
              control="checkbox-group"
              options={values?.jobApproaches?.map((item) => {
                return { key: item, value: item };
              })}
              disabled={values?.approaches?.length >= 8 ? true : false}
            />

            <ButtonRow>
              <Fechar
                title="Cancelar"
                onClick={() => setApproachesModal(false)}
              />
              <Escolher
                id="approaches-confirm"
                title="Confirmar"
                onClick={() => setApproachesModal(false)}
              />
            </ButtonRow>
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>
    </ConfigWrapper>
  );
}

export function UpdatePassword({ form, validateInfos }) {
  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    form;

  const handleErrors = (errors, values) => {
    let pageHasErrors = false;
    if (values?.length > 0) {
      values.forEach((value) => {
        if (errors[`${value}`]) {
          pageHasErrors = true;
        }
      });
    } else {
      Object.keys(errors).forEach(() => {
        pageHasErrors = true;
      });
    }
    return pageHasErrors;
  };

  const validatePassword = [
    values?.password,
    values?.newPassword,
    values?.confirmPassword,
  ].includes('');

  useEffect(() => {
    validateInfos(validatePassword || handleErrors(errors, values));
  }, [errors, values]);

  return (
    <ConfigWrapper>
      <ConfigDataForm onSubmit={handleSubmit}>
        <div style={{ padding: '0 10px 20px 0' }}>
          <TitleH1>Alterar minha senha</TitleH1>
          <RowLine />
        </div>
        <FormikControl
          control="input"
          label="Senha atual"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel
          value={values?.password}
        />
        <TextError>
          {errors?.password && touched?.password && errors?.password}
        </TextError>
        <RowLine />
        <FormikControl
          control="input"
          label="Nova senha"
          name="newPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel
          value={values?.newPassword}
        />
        <TextError>
          {errors?.newPassword && touched?.newPassword && errors?.newPassword}
        </TextError>
        <FormikControl
          control="input"
          label="Repetir nova senha"
          name="confirmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel
          value={values?.confirmPassword}
        />
        <TextError>
          {errors?.confirmPassword &&
            touched?.confirmPassword &&
            errors?.confirmPassword}
        </TextError>
      </ConfigDataForm>
    </ConfigWrapper>
  );
}

export function DeleteAccount({ form }) {
  const { values, handleBlur, handleChange } = form;

  return (
    <ConfigWrapper>
      <ConfigDataForm>
        <div style={{ padding: '0 10px 20px 0' }}>
          <TitleH1>Excluir minha conta</TitleH1>
          <RowLine />
        </div>
        <FormikControl
          control="textarea"
          label="Motivo da exclusão"
          name="reason"
          onBlur={handleBlur}
          onChange={handleChange}
          rows="5"
          upLabel
          value={values?.reason}
        />
      </ConfigDataForm>
    </ConfigWrapper>
  );
}
