/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderSpinner from '../../../components/LoaderSpinner';
import {
  ArrowUpCircleIcon,
  IconAreaInstagram,
} from '../../../components/NotificationList/styles';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { InstagramIcon } from '../../../styles/globalStyles';
import camelize from '../../../utils/camelize';
import { pageViewEvent } from '../../../utils/facebookPixel';
import { getProfessions } from '../../../utils/getProfessionsFilter';
import { Professional } from '../../Client/Professionals/ProfessionalsList';
import { ProfessionalFilter } from './components';
import {
  EmptyContainer,
  EmptyImg,
  EmptyLabel,
  ListGrid,
  ListProfessionalContainer,
  PublicListMain,
  PublicSearchContainer,
  SocialIconsWrapper,
} from './styles';

export function EmptyList({ ...props }) {
  const { text } = props;
  return (
    <EmptyContainer>
      <EmptyLabel>{text}</EmptyLabel>
      <EmptyImg />
    </EmptyContainer>
  );
}

export default function PublicProfileList() {
  const { getProfessionals, professionals, eventFilter, setEventFilter } =
    useEvent();
  const location = useLocation();
  const [loadingProfessionals, setLoadingProfessionals] = useState(true);
  const [loadingProfessions, setLoadingProfessions] = useState(true);
  const [professionalsFilter, setProfessionalsFilter] = useState([]);
  const [professionalsSearch, setProfessionalsSearch] = useState([]);
  const [professions, setProfessions] = useState([]);
  const professionalsRef = useRef(null);

  useEffect(() => {
    hotjar.initialize(3506248);
    hotjar.event('page_view');
    pageViewEvent();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessionals = async () => {
      try {
        await getProfessionals(controller);
      } catch (error) {
        toast.error('Não foi possível buscar lista de profissionais');
      } finally {
        setLoadingProfessionals(false);
      }
    };
    fetchProfessionals();

    const fetchProfessions = async () => {
      try {
        const { data } = await api.get('/professions', {
          signal: controller.signal,
        });
        const professionList = getProfessions(
          data?.data?.attributes?.professions
        );
        setProfessions(professionList);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível buscar profissões');
      } finally {
        setLoadingProfessions(false);
      }
    };
    fetchProfessions();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const getProfessionSearch = (professions) => {
      const search = location.search.split('&');

      const professionSearch = search[0]?.split('profession=')[1];

      const professionFilter =
        professionSearch !== undefined ? [professionSearch] : [];

      const ageGroups = search[1]
        ?.split('group=')[1]
        ?.split(':')
        ?.map((age) => camelize(age));

      const speciality = [
        search[1]?.split('speciality=')[1]?.split(':')?.join('_'),
      ];

      speciality[0] === 'orientador_profissional' &&
        professionFilter.push('professional_advisor');
      const ageGroupFilter = ageGroups?.length > 0 ? ageGroups : [];

      const specializations =
        speciality[0] !== undefined ? ['Orientação Profissional'] : [];

      const eventSearch = {
        ...eventFilter,
        professionFilter,
        ageGroupFilter,
        specializations,
        labels: specializations,
        professions: professions,
      };

      const data = filterProfessionalsSearch(
        specializations,
        ageGroupFilter,
        professionSearch
      );
      setEventFilter(eventSearch);
      setProfessionalsSearch(data);
    };

    if (location.search !== '' && professions.length > 0) {
      getProfessionSearch(professions);
    }
  }, [location.search, professions, professionals]);

  const scrollToTop = () => {
    return professionalsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  function resetFilters() {
    setProfessionalsFilter(professionals);
    setEventFilter({
      address: '',
      approaches: [],
      labels: [],
      price: '0',
      profession: {},
      professions: [],
      professionFilter: [],
      reasons: [],
      search: '',
      sessionType: [],
      specializations: [],
    });
  }

  function isEqual(a, b, type) {
    if (b?.length === 0 || b === '' || b === 0 || b === null) return true;
    if (type === 0) {
      if (parseInt(b, 10) === 0) return true;
      return parseInt(a, 10) < parseInt(b, 10);
    }
    if (type === 1) return a?.toLowerCase()?.includes(b?.toLowerCase());
    if (type === 2) return filterProfessions(a, b);
    return FilterArray(a, b);
  }

  function isDefault(filter) {
    let isDefaultValue = true;
    Object.keys(filter).forEach((key) => {
      if (
        filter[`${key}`]?.length !== 0 &&
        filter[`${key}`] !== '' &&
        filter[`${key}`] !== 0 &&
        filter[`${key}`] !== null
      ) {
        isDefaultValue = false;
      }
    });
    return isDefaultValue;
  }

  function FilterArray(a, b) {
    let tmp = [];
    tmp = b?.filter((item) => a?.includes(item));
    return tmp?.length > 0;
  }

  function filterProfessions(a, b) {
    let tmp = [];
    tmp = b?.filter((item) => a?.includes(item));

    return tmp?.length > 0;
  }

  const filterProfessionals = async (filter) => {
    const address =
      filter.address === 'todas as cidades' ||
      filter.address === undefined ||
      filter.address === null
        ? (filter.address = '')
        : filter.address;

    const data = professionals?.filter(
      (item) =>
        isDefault(filter) ||
        (isEqual(item.attributes.firstName, filter.search, 1) &&
          isEqual(item.attributes.profession, filter.professionFilter, 2) &&
          isEqual(
            item.attributes.availabilityRule.appointmentTypes,
            filter.sessionType,
            3
          ) &&
          isEqual(item.attributes.address.city, address, 1) &&
          isEqual(item.attributes.specializations, filter.specializations, 3) &&
          isEqual(item.attributes.approaches, filter.approaches, 3) &&
          (isEqual(item.attributes.sessionPrice, filter.price, 0) ||
            parseInt(item.attributes.sessionPrice, 10) < filter.price))
    );

    return setProfessionalsFilter(data);
  };

  const filterProfessionalsSearch = (
    specializations,
    ageGroups,
    profession
  ) => {
    let professionalsSearchFilter = [];
    if (specializations.length > 0 && profession === 'psychologist') {
      professionalsSearchFilter = professionals?.filter(
        (prof) =>
          (prof.attributes.profession === profession &&
            prof.attributes.specializations.includes(
              'Orientação Profissional'
            )) ||
          prof.attributes.profession === 'professional_advisor'
      );
    } else if (ageGroups.length > 0) {
      professionalsSearchFilter = professionals?.filter(
        (prof) =>
          prof.attributes.profession === profession &&
          prof.attributes.ageGroups.some((age) => ageGroups.includes(age))
      );
    } else {
      professionalsSearchFilter = professionals?.filter(
        (prof) => prof.attributes.profession === profession
      );
    }

    return professionalsSearchFilter;
  };

  const renderProfessionals =
    professionalsSearch.length > 0
      ? professionalsSearch
      : professionalsFilter.length > 0
        ? professionalsFilter
        : professionals;

  return (
    <PublicListMain>
      <div ref={professionalsRef} />

      <PublicSearchContainer>
        <ProfessionalFilter
          onSubmit={filterProfessionals}
          professions={professions}
          resetFilters={resetFilters}
          loading={loadingProfessionals || loadingProfessions}
        />
      </PublicSearchContainer>

      {loadingProfessionals || loadingProfessions ? (
        <LoaderSpinner logo />
      ) : (
        <ListProfessionalContainer>
          {renderProfessionals?.length === 0 ? (
            <EmptyList text="Nenhum profissional foi encontrado. Tente alterar os filtros de busca!" />
          ) : (
            <ListGrid>
              {renderProfessionals?.map((prof) => (
                <Professional
                  key={prof.id}
                  professional={prof.attributes}
                  id={prof.id}
                  search
                />
              ))}
            </ListGrid>
          )}
        </ListProfessionalContainer>
      )}

      <SocialIconsWrapper>
        {/* <Tooltip title="Fale com a gente">
          <IconAreaWhatsApp href="https://wa.me/5571999960614" target="_blank">
            <WhatsAppIcon fontSize="medium" sx={{ color: '#fff' }} />
          </IconAreaWhatsApp>
        </Tooltip> */}
        <Tooltip title="Siga-nos no Instagram">
          <IconAreaInstagram
            href="https://www.instagram.com/allminds.app/"
            target="_blank"
          >
            <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
          </IconAreaInstagram>
        </Tooltip>
        <Tooltip title="Voltar o topo">
          <ArrowUpCircleIcon onClick={scrollToTop} />
        </Tooltip>
      </SocialIconsWrapper>
    </PublicListMain>
  );
}
