import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CuponForm from '../../../../../components/FormikElements/CouponForm';
import LoaderSpinner from '../../../../../components/LoaderSpinner';
import useAuth from '../../../../../hooks/auth';
import api from '../../../../../services/api';
import { PtBr } from '../../../../../services/pt_br';
import convertCurrencyPtBr from '../../../../../utils/convertCurrencyPtBr';
import trimString from '../../../../../utils/trimString';
import {
  ActiveCoupons,
  ActiveCouponsContainer,
  CouponCard,
  CouponDashboard,
  CouponInfoContainer,
  CouponMain,
  CouponName,
  CouponStatus,
  CouponStatusText,
  CouponsActionsContainer,
  EmptyContainerLabel,
  NewCouponButton,
} from './styles';

export function isSameCupon(A, B) {
  if (A.code !== B.code) {
    return false;
  }
  if (A.discountPrice !== B.discountPrice) {
    return false;
  }
  if (A.ilimited !== B.ilimited) {
    return false;
  }
  if (A.dateLimitStart !== B.dateLimitStart) {
    return false;
  }
  if (A.dateLimitEnd !== B.dateLimitEnd) {
    return false;
  }
  if (A.amountLimit !== B.amountLimit) {
    return false;
  }
  return true;
}

export function CreateCoupon({ ...props }) {
  const { user } = useAuth();
  const sessionPriceFree = (parseFloat(user?.sessionPrice) / 100) * 80;
  const sessionPricePlatinum = parseFloat(user?.sessionPrice);
  const {
    coupons,
    selectedCoupon,
    setCoupons,
    setLoading,
    setSelectedCoupon,
    setShowCoupon,
  } = props;
  const history = useHistory();

  const createCupom = async (formValues) => {
    try {
      setLoading(true);
      const request = {
        data: {
          type: 'coupon',
          attributes: {
            title: formValues.title,
            code: formValues.code,
            couponType: formValues.couponType,
            discountPrice: formValues.discountPrice,
            discountPercentage: formValues.discountPercentage,
            dateLimitStart: formValues.dateLimitStart,
            dateLimitEnd: formValues.dateLimitEnd,
            amountLimit: formValues.amountLimit,
          },
        },
      };
      const { data } = await api.post('/professionals/coupons', request);
      setCoupons([data.data, ...coupons]);
      toast.success('Cupom cadastrado com sucesso.');
      setSelectedCoupon(null);
      setShowCoupon(false);
      return { success: true };
    } catch (error) {
      return { success: false, error: error?.response?.data?.errors[0].title };
    } finally {
      setLoading(false);
    }
  };

  const deleteCupom = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.delete(`/professionals/coupons/${id}`);
      if (data) {
        const tmp = coupons?.filter((item) => item.id !== id);
        setCoupons(tmp);
      }
      toast.success('Cupom excluído com sucesso.');
      setSelectedCoupon(null);
      setShowCoupon(false);
    } catch (error) {
      toast.error('Erro ao apagar o cupom.');
    } finally {
      setLoading(false);
    }
  };

  async function submitForm(values) {
    const request = {
      title: values.title,
      code: values.code,
      couponType: values.couponType,
      discountPrice: values.discountPrice,
      discountPercentage: values.discountPercentage,
      dateLimitStart:
        values.dateLimitStart === ''
          ? null
          : moment(values.dateLimitStart, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dateLimitEnd:
        values.dateLimitEnd === ''
          ? null
          : moment(values.dateLimitEnd, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      amountLimit: values.amountLimit === '' ? null : values.amountLimit,
    };
    try {
      if (!selectedCoupon?.id) {
        const { success, error } = await createCupom(request);
        if (!success) {
          throw new Error(error);
        }
      } else {
        if (selectedCoupon?.id) {
          await deleteCupom(selectedCoupon?.id);
        }
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else
        toast.error(
          'Erro! Não foi possível criar o cupom, revise os dados e tente novamente'
        );
    } finally {
      setSelectedCoupon(null);
      history.push({
        pathname: '/professional/config',
        state: { selected: 'Cupons' },
      });
    }
  }

  const schema = Yup.object({
    title: Yup.mixed().optional(),
    code: Yup.mixed().required('Código é um campo obrigatório!'),
    couponType: Yup.mixed().required('Tipo de cupom é um campo obrigatório!'),
    planType: Yup.mixed().required('Tipo de plano é um campo obrigatório!'),
    discountPrice: Yup.number().when('planType', {
      is: (planType) => planType === 'platinum',
      then: Yup.number()
        .typeError('O valor deve ser um número')
        .min(0, 'O valor deve ser maior que 0')
        .max(
          sessionPricePlatinum,
          `O valor máximo permitido é ${convertCurrencyPtBr(
            sessionPricePlatinum
          )}`
        )
        .required('O valor de desconto é obrigatório')
        .nullable(),
      otherwise: Yup.number()
        .typeError('O valor deve ser um número')
        .min(0, 'O valor deve ser maior que 0')
        .max(
          sessionPriceFree,
          `O valor máximo permitido é ${convertCurrencyPtBr(sessionPriceFree)}`
        )
        .required('O valor de desconto é obrigatório')
        .nullable(),
    }),
    discountPercentage: Yup.number().when('planType', {
      is: (planType) => planType === 'platinum',
      then: Yup.number()
        .typeError('O valor deve ser um número')
        .min(0, 'O valor deve ser maior que 0')
        .max(100, 'O valor máximo permitido é 100%')
        .required('O valor de desconto é obrigatório')
        .nullable(),
      otherwise: Yup.number()
        .typeError('O valor deve ser um número')
        .min(0, 'O valor deve ser maior que 0')
        .max(80, 'O valor máximo permitido é 80%')
        .required('O valor de desconto é obrigatório')
        .nullable(),
    }),
    dateLimitStart: Yup.mixed().optional(),
    dateLimitEnd: Yup.mixed().optional(),
    amountLimit: Yup.mixed().optional(),
  });

  const form = useFormik({
    initialValues: {
      title:
        selectedCoupon?.attributes?.title ||
        selectedCoupon?.attributes?.code ||
        null,
      code: selectedCoupon?.attributes?.code || null,
      couponType: 'professional',
      planType: user?.subscription?.pagarmePlan || 'platinum',
      discountPrice: selectedCoupon?.attributes?.discountPrice || 0,
      discountPercentage: selectedCoupon?.attributes?.discountPercentage || 0,
      dateLimitStart: selectedCoupon?.attributes?.dateLimitStart || null,
      dateLimitEnd: selectedCoupon?.attributes?.dateLimitEnd || null,
      amountLimit: selectedCoupon?.attributes?.amountLimit || null,
      ilimited:
        selectedCoupon?.attributes?.dateLimitStart === null &&
        selectedCoupon?.attributes?.dateLimitEnd === null &&
        selectedCoupon?.attributes?.amountLimit === null,
    },
    validationSchema: schema,
    onSubmit: (values) => submitForm(values),
  });

  return (
    <CuponForm
      form={form}
      selectedCoupon={selectedCoupon}
      setSelectedCoupon={setSelectedCoupon}
      setShowCoupon={setShowCoupon}
      create={!selectedCoupon}
    />
  );
}

export function Coupon({ ...props }) {
  const { coupon, setSelectedCoupon, setShowCoupon } = props;

  function selectedCoupon() {
    setSelectedCoupon(coupon);
    setShowCoupon(true);
  }

  function handleStatus(coupon) {
    if (
      !coupon?.attributes.amountLimit &&
      !coupon?.attributes.dateLimitStart &&
      !coupon?.attributes.dateLimitEnd
    ) {
      return 'Cupom ilimitado';
    }
    if (coupon.attributes.amountLimit) {
      return `Faltam ${coupon?.attributes.amountLimit} cupons`;
    } else if (coupon.attributes.amountLimit === 0) {
      return 'Não há mais cupons';
    }
    const dateEnd = moment(coupon.attributes.dateLimitEnd);
    const today = moment();
    const diffDays = dateEnd.diff(today, 'days');
    return diffDays > 1
      ? `Faltam ${diffDays} dias`
      : diffDays === 0
        ? 'Termina hoje'
        : `Falta ${diffDays} dia`;
  }

  return (
    <CouponCard type="button" onClick={selectedCoupon}>
      <CouponInfoContainer>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <p style={{ fontWeight: '600' }}>{`# ${coupon?.id} •`}</p>
          <p style={{ marginLeft: '0.25rem' }}>{`${moment(
            coupon?.createdAt
          ).format('DD/MM/YYYY')}`}</p>
        </div>
        <CouponName>{trimString(coupon?.attributes.code, 13)}</CouponName>
      </CouponInfoContainer>
      <CouponStatus>
        <CouponStatusText>{handleStatus(coupon)}</CouponStatusText>
      </CouponStatus>
    </CouponCard>
  );
}

export default function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const [showCoupon, setShowCoupon] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const getCupons = async () => {
      try {
        const { data } = await api.get('/professionals/coupons', {
          signal: controller.signal,
        });
        setCoupons(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao listar os cupons.');
      }
    };
    getCupons();

    return () => controller.abort();
  }, []);

  return loading ? (
    <LoaderSpinner />
  ) : (
    <CouponMain>
      {showCoupon ? (
        <CreateCoupon
          coupons={coupons}
          selectedCoupon={selectedCoupon}
          setCoupons={setCoupons}
          setLoading={setLoading}
          setSelectedCoupon={setSelectedCoupon}
          setShowCoupon={setShowCoupon}
        />
      ) : (
        <CouponDashboard>
          <CouponsActionsContainer>
            <NewCouponButton type="button" onClick={() => setShowCoupon(true)}>
              {PtBr.button_text.create_cupon}
            </NewCouponButton>
          </CouponsActionsContainer>
          <ActiveCouponsContainer>
            {coupons && coupons?.length > 0 ? (
              <ActiveCoupons>
                {coupons?.map((coupon) => (
                  <Coupon
                    key={coupon.id}
                    coupon={coupon}
                    setSelectedCoupon={setSelectedCoupon}
                    setShowCoupon={setShowCoupon}
                  />
                ))}
              </ActiveCoupons>
            ) : (
              <EmptyContainerLabel>
                {PtBr.cupons_dashboard.empty_state_text}
              </EmptyContainerLabel>
            )}
          </ActiveCouponsContainer>
        </CouponDashboard>
      )}
    </CouponMain>
  );
}
