import {
  PLAN_LEVEL_FREE_ACCESS,
  PLAN_LEVEL_FULL_ACCESS,
} from './constantsLevelAccess';

export function handleVerifyMembers(user) {
  const visibility = [];
  if (user?.school) visibility.push('all-members');
  if (user?.schoolType === 'professor') visibility.push('members-professor');
  if (user?.type === 'client') visibility.push('all-clients');
  if (user?.type === 'professional') visibility.push('all-professionals');
  if (PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel))
    visibility.push('all-platinum');
  if (PLAN_LEVEL_FREE_ACCESS.includes(user?.planLevel))
    visibility.push('all-free');
  if (user?.type === 'client' && !user?.school) visibility.push('all-users');
  visibility.push('all');
  return visibility;
}
