/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import FormikControl from '../../../components/FormikControl';
import { handleUserNames } from '../../../components/UserElements';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import { SMFontPrimary } from '../../Client/Professionals/ProfessionalsList/styles';
import {
  BodyContainer,
  ButtonContainer,
  CancelButton,
  CloseModalButton,
  ContinueButton,
  InfoContainer,
  InputContainer,
  MedicalRecordCard,
  MenuHeader,
  MenuOption,
  ModalContainer,
  ModalWrapper,
  OptionText,
  ProfileName,
  RowItem,
  SMFontGray,
  SMFontLightGray,
  StyledButton,
  StyledModal,
  UserIcon,
  UserProfile,
} from './styles';

export function UserCard({ ...props }) {
  const { user, notes } = props;
  const [showMore, setShowMore] = useState(false);
  return (
    <UserProfile>
      <RowItem>
        <UserIcon />
      </RowItem>
      <ProfileName>{handleUserNames(user)}</ProfileName>
      <SMFontGray>{notes.lastSchedule.startDate}</SMFontGray>
      <StyledButton onClick={() => setShowMore(true)} title="+ Informações" />
      <StyledModal
        isOpen={showMore}
        style={{ overlay: { backgroundColor: 'transparent' } }}
        onRequestClose={() => setShowMore(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <CloseModalButton onClick={() => setShowMore(false)}>
              X
            </CloseModalButton>
            <InfoContainer>
              <InfoContainer></InfoContainer>
              <SMFontLightGray>
                {PtBr.register_input_legalGuardianFullName}
              </SMFontLightGray>
              <SMFontPrimary>{handleUserNames(user)}</SMFontPrimary>
            </InfoContainer>
            <InfoContainer>
              <SMFontLightGray>{PtBr.register_input_email}</SMFontLightGray>
              <SMFontPrimary>{user?.email}</SMFontPrimary>
            </InfoContainer>
            <InfoContainer>
              <SMFontLightGray>{PtBr.register_input_birthday}</SMFontLightGray>
              <SMFontPrimary>
                {moment(user?.birthday).format('DD/MM/YYYY')}
              </SMFontPrimary>
            </InfoContainer>
            <InfoContainer>
              <SMFontLightGray>
                {PtBr.register_input_legalGuardianPhone}
              </SMFontLightGray>
              <SMFontPrimary>{user?.phone}</SMFontPrimary>
            </InfoContainer>
            {user?.legal_guardian_full_name && (
              <Fragment>
                <InfoContainer>
                  <SMFontGray>Informações do(a) responsável</SMFontGray>
                  <SMFontLightGray>
                    {PtBr.register_input_legalGuardianFullName}
                  </SMFontLightGray>
                  <SMFontPrimary>
                    {user?.legal_guardian_full_name}
                  </SMFontPrimary>{' '}
                </InfoContainer>
                <InfoContainer>
                  <SMFontLightGray>
                    {PtBr.register_input_legalGuardianEmail}
                  </SMFontLightGray>
                  <SMFontPrimary>{user?.legal_guardian_email}</SMFontPrimary>
                </InfoContainer>
                <InfoContainer>
                  <SMFontLightGray>
                    {PtBr.register_input_legalGuardianPhone}
                  </SMFontLightGray>
                  <SMFontPrimary>
                    {user?.legal_guardian_document_number}
                  </SMFontPrimary>
                </InfoContainer>
              </Fragment>
            )}
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>
    </UserProfile>
  );
}

export function TopMenuBar({ ...props }) {
  const { options, setSelected, selected } = props;
  return (
    <MenuHeader>
      {options?.map((item) => (
        <MenuOption
          key={item}
          onClick={() => setSelected(item)}
          active={selected === item}
        >
          <OptionText active={selected === item}>{item}</OptionText>
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

export default function MedicalRecord() {
  const { id } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const [MenuOptions] = useState(['PRONTUÁTIO', 'ANOTAÇÕES']);
  const [selected, setSelected] = useState('PRONTUÁTIO');
  const [patient, setPatient] = useState(null);
  const [note, setNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const schema = Yup.object({
    comments: Yup.string(),
    notes: Yup.string(),
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const { data } = await api.get(`professionals/patients/${id}`, {
          signal: controller.signal,
        });
        setNote(data.data.attributes);
        setPatient(data.data.attributes.client);
      } catch (erro) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar dados do profissional');
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    return () => controller.abort();
  }, [id]);

  const handleContinue = async (values) => {
    try {
      await api.put(`/professionals/patients/${id}`, {
        data: {
          attributes: {
            medicalRecord: values.notes || note.medicalRecord || ' ',
            notes: values.comments || note.notes || ' ',
          },
        },
      });
      setLoading(false);
      toast.success('Suas alterações foram salvas');
      history.push('/professional/patients');
    } catch (error) {
      toast.error('Não foi possível editar prontuário');
    }
  };
  return (
    <BodyContainer>
      <TopMenuBar
        options={MenuOptions}
        setSelected={setSelected}
        selected={selected}
      />
      {loading || (
        <Formik
          initialValues={{
            comments: note.notes || '',
            notes: note.medicalRecord || '',
          }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleContinue(values);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {patient && (
                <MedicalRecordCard>
                  <UserCard user={patient} notes={note} />
                  {selected === MenuOptions[0] ? (
                    <InputContainer>
                      <FormikControl
                        control="textarea"
                        rows={5}
                        name="notes"
                        value={values.notes}
                        label="Observações"
                        onChange={handleChange}
                        placeholder="Não se preocupe, só você poderá ver essas observações."
                      />
                    </InputContainer>
                  ) : (
                    <InputContainer>
                      <FormikControl
                        control="textarea"
                        rows={5}
                        name="comments"
                        label="Comentários"
                        value={values.comments}
                        onChange={handleChange}
                        onBluer={handleBlur}
                        placeholder="Comente e compartilhe anotações com o seu cliente."
                      />
                    </InputContainer>
                  )}
                  <InputContainer>
                    {errors.comments && touched.comments && errors.comments}
                  </InputContainer>
                  {errors.notes && touched.notes && errors.notes}
                </MedicalRecordCard>
              )}
              <ButtonContainer>
                <CancelButton
                  title="Cancelar"
                  onClick={() => history.push('/professional/patients')}
                />
                <ContinueButton
                  title="Continuar"
                  type="submit"
                  buttonColor={theme.primary}
                  onClick={() => handleSubmit()}
                />
              </ButtonContainer>
            </div>
          )}
        </Formik>
      )}
    </BodyContainer>
  );
}
