import React from 'react';
import { Switch } from 'react-router-dom';
import NotificationList from '../../components/NotificationList';
import AllProfessional from '../../screens/Clinic/AllProfessionals';
import CalendarScreen from '../../screens/Clinic/Calendar';
import ClientList from '../../screens/Clinic/Clients';
import ClinicDashboard from '../../screens/Clinic/Dashboard';
import FinanceScreen from '../../screens/Clinic/Finance';
import ProfessionalList from '../../screens/Clinic/Professionals';
import ClinicProfileConfig from '../../screens/Clinic/Profile';

import StatementScreen from '../../components/Statement';
import PackagesPlans from '../../screens/Clinic/PackagesPlans';
import SchedulesReport from '../../screens/Clinic/SchedulesReport';
import SubscriptionPlans, {
  NewCardPayment,
} from '../../screens/Clinic/Subscribe';
import PublicProfessionalProfile from '../../screens/Public/Profile';
import { ClinicRoutes } from './clinicRoutes';

export function PrivateRouteClinic() {
  return (
    <Switch>
      <ClinicRoutes
        exact
        path="/clinic/dashboard"
        component={ClinicDashboard}
      />

      <ClinicRoutes exact path="/clinic/patients" component={ClientList} />

      <ClinicRoutes
        exact
        path="/clinic/professionals"
        component={ProfessionalList}
      />

      <ClinicRoutes
        exact
        path="/clinic/all-professionals"
        component={AllProfessional}
      />

      <ClinicRoutes
        exact
        path="/clinic/schedule/:id"
        component={PublicProfessionalProfile}
      />

      <ClinicRoutes
        exact
        path="/clinic/profile"
        component={ClinicProfileConfig}
      />

      <ClinicRoutes exact path="/clinic/calendar" component={CalendarScreen} />

      <ClinicRoutes
        exact
        path="/clinic/schedules_report"
        component={SchedulesReport}
      />

      <ClinicRoutes exact path="/clinic/finances" component={FinanceScreen} />

      <ClinicRoutes
        exact
        path="/clinic/subscription"
        component={SubscriptionPlans}
      />

      <ClinicRoutes
        exact
        path="/clinic/notifications"
        component={NotificationList}
      />

      <ClinicRoutes
        exact
        path="/clinic/subscription/add_new_payment"
        component={NewCardPayment}
      />

      <ClinicRoutes
        exact
        path="/clinic/statement"
        component={StatementScreen}
      />

      <ClinicRoutes
        exact
        path="/clinic/packages-assignments"
        component={PackagesPlans}
      />
    </Switch>
  );
}
