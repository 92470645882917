import React from 'react';
import useAuth from '../../../hooks/auth';
import { PLAN_LEVEL_FULL_ACCESS } from '../../../utils/constantsLevelAccess';
import DashboardFree from './DashboardFree';
import DashboardPlatinum from './DashboardPlatinum';

export default function Dashboard() {
  const { user } = useAuth();
  return PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel) ? (
    <DashboardPlatinum />
  ) : (
    <DashboardFree />
  );
}
