import Hotjar from '@hotjar/browser';
import React from 'react';
import { setDefaultLocale } from 'react-datepicker';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const siteId = 3516683;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

TagManager.initialize(tagManagerArgs);

setDefaultLocale('ptBR');

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
