import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  ScrollPageStyle,
} from '../../../styles/globalStyles';

export const Main = styled.form`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};
  column-gap: 0;
  display: grid;
  grid-template-columns: ${({ step }) =>
    [1, 5, 7].includes(step) ? '1fr' : 'repeat(2, 1fr)'};
  justify-content: center;
  position: relative;
  row-gap: 0;
  width: 100%;

  @media (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const LoginWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0;
  width: ${({ step }) => ([1, 5, 7].includes(step) ? '90%' : '60%')};

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const ProfessionalForm = styled(ScrollPageStyle)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  overflow: auto;
  width: 100%;
  z-index: 0;
`;
