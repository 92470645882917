import styled from 'styled-components';
import { ModalBoxWrapper } from '../../../styles/globalStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const TextAlert = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

export const TitleAlert = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const IconArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img`
  height: 2.5rem;
  width: 2.5rem;
`;

export const ModalContainer = styled(ModalBoxWrapper)`
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
