import styled from 'styled-components';
import { ReactComponent as CheckedIcon } from '../../../../images/checked.svg';

export const Main = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Container = styled.div`
  flex: 0.5;
  height: 100%;
  display: flex;
  width: 400px;
  padding: 50px 0;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
    color: ${({ theme }) => theme.secondary};
  }
`;

export const BigHeaderElement = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0px 0px 50% 50% / 0px 0px 15px 15px;

  .--wrapper {
    display: flex;
    max-width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .--title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    align-self: center;
    color: ${({ theme }) => theme.primaryAlt};
  }

  .--text {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    align-self: center;
    padding-top: 10px;
    color: ${({ theme }) => theme.primaryAlt};
  }
`;

export const CheckIcon = styled(CheckedIcon)`
  width: 85px;
  height: 85px;
  margin-bottom: 25px;
`;
