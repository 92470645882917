/* eslint-disable react-hooks/exhaustive-deps */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { CheckCircle, XCircle } from 'react-feather';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  BREAKPOINT_MD,
  BREAKPOINT_MD_PX,
} from '../../../../styles/globalStyles';
import getPlanName from '../../../../utils/getPlanName';
import useWindowSize from '../../../../utils/WindowSize';
import { UserSubscribe } from '../../../UserElements';

function createData(name, col1, col2) {
  return { name, col1, col2 };
}

const rows = [
  createData('Número de usuários', 1, 1),
  createData(
    'Agendamento online',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Cobranças automáticas',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Prontuário do paciente',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Controle financeiro',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Relatório de desempenho',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Pacote de consultas',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Política de cancelamento',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Lembretes automáticos via email',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Lembretes automáticos vis SMS e WhatsApp',
    <XCircle />,
    <CheckCircle color="green" />
  ),
  createData(
    'Consultas online com link do profissional',
    <CheckCircle color="green" />,
    <CheckCircle color="green" />
  ),
  createData(
    'Consultas online com ferramenta de video integrada',
    'R$1,50 por sessão',
    '30 sessões inclusas'
  ),
  createData(
    'Consultoria personalizada de implantação',
    <XCircle />,
    <CheckCircle color="green" />
  ),
  createData(
    'Consultoria personalizada de dicas e boas práticas',
    <XCircle />,
    <CheckCircle color="green" />
  ),
  createData(
    'Desconto na compra de serviços adicionais',
    <XCircle />,
    <CheckCircle color="green" />
  ),
];

export default function TablePlan({ ...props }) {
  const { handleModal, currentPlan, setFieldValue, values } = props;
  const { width } = useWindowSize();
  const [plan, setPlan] = useState(null);

  const dataCardsPlans = [
    {
      tPlanName: 'Prata',
      planName: 'silver',
      planPrice: 59.9,
      message: 'Para psicólogos que almejam alcançar um novo patamar',
    },
    {
      tPlanName: 'Ouro',
      planName: 'gold',
      planPrice: 89.9,
      message: 'Ideal para otimizar a rotina de todo psicólogo',
    },
  ];

  useEffect(() => {
    const handlePlanName = () => {
      const getPlan = dataCardsPlans?.find(
        (item) => item.planName === currentPlan?.attributes?.planName
      )?.planName;

      const handlePlan =
        getPlan || currentPlan?.attributes?.planName || 'silver';

      if (['silver', 'gold'].includes(handlePlan)) {
        setPlan(getPlanName(handlePlan));
        setFieldValue('planName', handlePlan);
      } else {
        toast.warning(
          'Seu plano não está mais disponível! Por gentileza atualize seu plano de assinatura.',
          {
            autoClose: false,
          }
        );
      }
    };
    handlePlanName();
  }, []);

  return (
    <TableContainer
      style={{
        boxShadow: 'none',
      }}
      component={Paper}
    >
      <WrapperCard>
        <Wrapper>
          Compare os planos e escolha a opção perfeita para potencializar sua
          gestão
        </Wrapper>
      </WrapperCard>

      {width <= BREAKPOINT_MD && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            marginBottom: '1rem',
            width: '100%',
          }}
        >
          {dataCardsPlans?.map((plans) => (
            <UserSubscribe
              key={plans.planName}
              plans={plans}
              plan={plan}
              handleModal={handleModal}
              setFieldValue={setFieldValue}
              values={values}
            />
          ))}
        </div>
      )}
      <Table size="small" aria-label="a dense table">
        {width > BREAKPOINT_MD && (
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              {dataCardsPlans?.map((plans) => (
                <TableCell key={plans.planName} align="center">
                  <UserSubscribe
                    plans={plans}
                    plan={plan}
                    handleModal={handleModal}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                {row.name}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                <IconArea>{row.col1}</IconArea>
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                <IconArea>{row.col2}</IconArea>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const IconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperCard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 2rem 5rem;
  width: 70%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 2rem;
    width: 100%;
  }
`;
