import { Button, Grid, Modal, Tooltip, Typography } from '@material-ui/core';
import {
  AccessTime,
  MonetizationOn,
  Place,
  RequestQuote,
} from '@mui/icons-material';
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth';
import { Box } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { Fragment, useState } from 'react';
import { User, X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/auth';
import useEvent from '../../hooks/event';
import { PayPix } from '../../screens/Client/Professionals/Payment';
import ReviewProfessional from '../../screens/Client/Review';
import { Hr } from '../../screens/Client/Review/styles';
import { ListCardPayment } from '../../screens/Professional/ProfessionalUpdate/Subscribe';
import api from '../../services/api';
import { PtBr } from '../../services/pt_br';
import {
  CloseIcon,
  IconAreaModal,
  ModalAddress,
  ModalReport,
} from '../../styles/globalStyles';
import theme from '../../theme';
import camelize from '../../utils/camelize';
import convertCurrencyPtBr from '../../utils/convertCurrencyPtBr';
import getProfessionName from '../../utils/getProfessionName';
import { LinkButton, PrimaryButton } from '../Button';
import { CreditCardListItem } from '../ListItens';
import LoaderSpinner from '../LoaderSpinner';
import { ModalAlert } from '../Modals/ModalAlerts';
import PdfGenerator from '../PDFDownload';
import { handleUserNames } from '../UserElements';
import {
  ArrowLeft,
  ArrowRight,
  BoxCalendarCard,
  BoxCalendarCardInfo,
  BoxCancelInformation,
  BoxEvaluate,
  ButtonsRow,
  ButtonsSchedule,
  ButtonsWrapper,
  CalendarIcon,
  CameraIcon,
  CardButtonMethod,
  Container,
  ContainerCardPayment,
  DeleteButton,
  DeleteButtonClinic,
  EvaluateButton,
  Event,
  EventCalendar,
  EventColumnItem,
  EventDate,
  EventProfessional,
  EventRowItem,
  EventSmallText,
  EventType,
  HeaderContent,
  HeaderTitle,
  IconArea,
  LinkArea,
  Main,
  MapPinIcon,
  MessageButton,
  MessageIcon,
  ModalAvatar,
  PayButton,
  PayIcon,
  PaymentButton,
  PaymentModal,
  PaymentTitle,
  PaymentWrapper,
  PlaceButton,
  PlaceButtonClinic,
  ReportBoxWrapper,
  ReportButton,
  ReportButtonEvent,
  ReportButtonWrapper,
  ReportIcon,
  ReportIconAlert,
  ReportIconArea,
  ReportIconCheck,
  ReportParagraph,
  ReportTitle,
  RowLine,
  ScheduleCard,
  ScheduleClient,
  ScheduleClientAvatar,
  ScheduleClientClinic,
  ScheduleClinicTime,
  ScheduleInfo,
  ScheduleInfoClinic,
  ScheduleProfessionalAvatar,
  ScheduleTime,
  ScheduleWrapperAvatar,
  ScheduleWrapperClinic,
  StyledList,
  TileLabel,
  TimeCalendar,
  TimeCalendarContainer,
  TimeTile,
  TrashIcon,
  TrashIconCalendar,
  WrapperButtonsClinic,
  WrapperList,
  WrapperPayment,
} from './styles';

const formatedHour = (hour) => moment(hour, 'HH:mm:ss').format('HH:mm');

function converDateHour(date, hour) {
  const dateHour = moment(date).toDate();
  dateHour.setHours(moment(hour, 'HH:mm:ss').toDate().getHours());
  dateHour.setMinutes(moment(hour, 'HH:mm:ss').toDate().getMinutes());
  dateHour.setSeconds(moment(hour, 'HH:mm:ss').toDate().getSeconds());
  return dateHour;
}

export function getCancellationStatus(date, time) {
  const today = moment().toDate();
  const dateTime = converDateHour(date, time);
  if (moment.duration(moment(dateTime).diff(today)).asHours() < -1) {
    return 'time_exceeded';
  } else if (moment.duration(moment(dateTime).diff(today)).asHours() <= -24) {
    return 'uncancellable_already_done';
  } else if (moment.duration(moment(dateTime).diff(today)).asHours() <= 24) {
    return 'uncancellable_less_than_24h';
  }
  return 'cancellable';
}

export function EmptyEventCard({ ...props }) {
  const history = useHistory();
  const { type, past } = props;
  return (
    <Main>
      <Container>
        <CalendarIcon />
        <EventSmallText>{`${PtBr.event_empty_description} ${type}.`}</EventSmallText>
      </Container>
      {past && (
        <ButtonsWrapper>
          <EventSmallText>
            Para ver o relatório completo de consultas:
          </EventSmallText>
          <PrimaryButton
            style={{ marginTop: '1.5rem' }}
            onClick={() => history.push('/professional/schedules_report')}
            iconEnd={<CalendarViewMonth />}
            width="10rem"
          >
            Clique Aqui
          </PrimaryButton>
        </ButtonsWrapper>
      )}
    </Main>
  );
}

export function Calendar({ ...props }) {
  const { dates, setDate, diary, ...rest } = props;

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  const tileDisabled = ({ date }) => {
    return dates?.find((dDate) => isSameDay(dDate, date));
  };

  return (
    <EventCalendar
      minDate={!diary ? moment().toDate() : null}
      tileDisabled={tileDisabled}
      onChange={(e) => setDate(e)}
      nextLabel={<ArrowRight />}
      prevLabel={<ArrowLeft />}
      prev2Label={null}
      next2Label={null}
      onActiveStartDateChange={({ activeStartDate }) =>
        setDate(activeStartDate)
      }
      showNeighboringMonth={false}
      tileClassName="--tile"
      diary={diary}
      view="month"
      {...rest}
    />
  );
}

export function CalendarCustomDates({ ...props }) {
  const { dates, diary, ...rest } = props;

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  const tileDisabled = ({ date }) => {
    return dates?.find((dDate) => isSameDay(dDate, date));
  };

  return (
    <EventCalendar
      minDate={!diary ? moment().toDate() : null}
      tileDisabled={tileDisabled}
      nextLabel={<ArrowRight />}
      prevLabel={<ArrowLeft />}
      prev2Label={null}
      next2Label={null}
      showNeighboringMonth={false}
      tileClassName="--tile"
      diary={diary}
      view="month"
      {...rest}
    />
  );
}

export function CalendarTime({ ...props }) {
  const { title, time, setValues, values, ...rest } = props;

  return (
    <TimeCalendar>
      <HeaderContent>
        <HeaderTitle>{title}</HeaderTitle>
      </HeaderContent>
      <TimeCalendarContainer>
        {time?.map((item) => {
          return (
            <TimeTile
              key={item.time}
              disabled={item.type === 'unavailable'}
              available={item.type}
              values={values?.startTime}
              selected={values?.startTime === `${item.time}:00`}
              index={`${item.time}:00`}
              onClick={() =>
                setValues({
                  ...values,
                  startTime: `${item.time}:00`,
                })
              }
              {...rest}
            >
              <AccessTime
                sx={{
                  color:
                    values?.startTime === `${item.time}:00`
                      ? theme.white
                      : theme.primary_blue,
                  mr: 2,
                }}
              />
              <TileLabel
                selected={values?.startTime === `${item.time}:00`}
                available={item.type}
              >
                {item.time}
              </TileLabel>
            </TimeTile>
          );
        })}
      </TimeCalendarContainer>
    </TimeCalendar>
  );
}

export function CalendarCard({ ...props }) {
  const { event, calendarProfessional } = props;
  const { attributes, type } = event;
  const { deleteEvent } = useEvent();
  const history = useHistory();
  const [scheduleInfo, setScheduleInfo] = useState(attributes?.client);
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const { user } = useAuth();

  const handleDelete = async (event) => {
    setModal(false);
    const status = getCancellationStatus(
      event.attributes.startDate,
      event.attributes.startTime
    );
    if (status === 'cancellable' || 'uncancellable_less_than_24h') {
      const response = await deleteEvent(event.id);
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else if (status === 'uncancellable_already_done') {
      toast.warning('Ops.. parece que este agendamento ja foi realizado');
    } else {
      toast.warning('Esse agendamento não pode ser cancelado!');
    }
    if (calendarProfessional) {
      history.push('/professional/calendar');
    }
  };

  const infoCard = () => {
    if (attributes?.client) {
      if (attributes?.client?.lastName) {
        return (
          attributes?.client?.firstName + ' ' + attributes?.client?.lastName
        );
      } else return attributes?.client?.firstName;
    } else if (type === 'blockedTime') {
      return 'Horário bloqueado';
    } else return 'Horário Livre';
  };

  return (
    <ScheduleCard
      type={attributes?.appointmentType ? attributes.appointmentType : type}
    >
      <ScheduleInfo>
        <ScheduleTime type={type}>
          {moment(attributes?.startTime, 'HH:mm:ss').format('HH:mm')} -{' '}
          {moment(attributes?.startTime, 'HH:mm:ss')
            .add(attributes?.duration, 'minutes')
            .format('HH:mm')}
        </ScheduleTime>
        <ScheduleClient type={type}>{infoCard()}</ScheduleClient>
      </ScheduleInfo>
      {type === 'schedule' && (
        <ButtonsSchedule type={type}>
          {getCancellationStatus(attributes.startDate, attributes.startTime) ===
            'uncancellable_less_than_24h' &&
            attributes.paymentStatus === 'paid' && (
              <Tooltip
                title={
                  attributes?.appointmentType === 'online'
                    ? 'Iniciar atendimento'
                    : 'Atendimento presencial'
                }
              >
                <LinkArea
                  target={
                    getCancellationStatus(
                      attributes.startDate,
                      attributes.startTime
                    )
                      ? null
                      : '_blank'
                  }
                  disabled={
                    getCancellationStatus(
                      attributes.startDate,
                      attributes.startTime
                    ) === 'cancellable' ||
                    getCancellationStatus(
                      attributes.startDate,
                      attributes.startTime
                    ) === 'time_exceeded' ||
                    getCancellationStatus(
                      attributes.startDate,
                      attributes.startTime
                    ) === 'uncancellable_already_done'
                  }
                  onClick={() => {
                    getCancellationStatus(
                      attributes.startDate,
                      attributes.startTime
                    ) === 'cancellable' &&
                      toast.warning(
                        'Esse agendamento ainda não está disponivel'
                      );
                    if (
                      getCancellationStatus(
                        attributes.startDate,
                        attributes.startTime
                      ) === 'cancellable'
                    ) {
                      toast.warning(
                        'Esse agendamento ainda não está disponivel'
                      );
                    } else {
                      if (attributes?.ownMeetingLink) {
                        window.open(attributes?.ownMeetingLink, '_blank');
                      } else {
                        history.push({
                          pathname: `/${user.type}/meeting`,
                          state: {
                            schedule: { id: attributes.id, ...attributes },
                          },
                        });
                      }
                    }
                  }}
                >
                  {attributes?.appointmentType === 'online' ? (
                    <CameraIcon />
                  ) : (
                    <MapPinIcon />
                  )}
                </LinkArea>
              </Tooltip>
            )}
          <Tooltip title="Cancelar agendamento">
            <LinkArea onClick={() => setModal(true)}>
              <TrashIconCalendar type={type} />
            </LinkArea>
          </Tooltip>
          {attributes.paymentStatus === 'paid' || (
            <Tooltip title="Não pago">
              <LinkArea
                onClick={() =>
                  toast.warning('Agendamento com pagamento não realizado')
                }
              >
                <PayIcon />
              </LinkArea>
            </Tooltip>
          )}
          <Tooltip title="Informações do agendamento.">
            <LinkArea
              onClick={() => {
                setModalInfo(true);
                setScheduleInfo(attributes);
              }}
            >
              <User color="#999EBC" />
            </LinkArea>
          </Tooltip>
        </ButtonsSchedule>
      )}
      {modal && (
        <Modal open>
          <BoxCalendarCard>
            <Typography variant="h6">
              {' '}
              O agendamento selecionado será cancelado. Essa ação é
              irreversível, deseja continuar?{' '}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setModal(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => handleDelete(event)}
                size="small"
                variant="contained"
              >
                Sim
              </Button>
            </Grid>
          </BoxCalendarCard>
        </Modal>
      )}

      {modalInfo && (
        <Modal open>
          <BoxCalendarCardInfo>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <Typography
              variant="h4"
              sx={{ mt: 1, textAlign: 'center', fontWeight: '700' }}
            >
              Detalhes do agendamento
            </Typography>
            <WrapperList>
              <StyledList primary={'true'}>
                <li>
                  <span className="--title">Paciente:</span>{' '}
                  <span className="--content">
                    {scheduleInfo?.client?.firstName}{' '}
                    {scheduleInfo?.client?.lastName}
                  </span>
                </li>
                <li>
                  <span className="--title">Dia:</span>{' '}
                  <span className="--content">
                    {moment(scheduleInfo?.startDate, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY'
                    )}
                  </span>
                </li>
                <li>
                  <span className="--title">Hora:</span>{' '}
                  <span className="--content">
                    {moment(scheduleInfo?.startTime, 'HH:mm:ss').format(
                      'HH:mm'
                    )}
                  </span>
                </li>
                <li>
                  <span className="--title">Valor:</span>{' '}
                  <span className="--content">
                    {convertCurrencyPtBr(scheduleInfo?.paymentAmount)}
                  </span>
                </li>
                <li>
                  <span className="--title">Status:</span>{' '}
                  <span>
                    {scheduleInfo?.paymentStatus === 'paid'
                      ? 'Pago'
                      : 'Pendente de pagamento'}
                  </span>
                </li>
                <li>
                  <span className="--title">Tipo:</span>{' '}
                  <span className="--content">
                    {scheduleInfo?.appointmentType === 'in_person'
                      ? 'Presencial'
                      : 'Online'}
                  </span>
                </li>
                <li>
                  <span className="--title">Endereço:</span>{' '}
                  <span className="--content">
                    {`${camelize(scheduleInfo?.client?.address?.street)},
                        Número ${
                          scheduleInfo?.client?.address?.street_number || 'S/N'
                        },
                        Bairro ${
                          camelize(
                            scheduleInfo?.client?.address?.neighborhood
                          ) || ''
                        },
                        Cidade ${
                          camelize(scheduleInfo?.client?.address?.city) || ''
                        },
                        Estado ${
                          camelize(scheduleInfo?.client?.address?.state) || ''
                        }`}
                  </span>
                </li>
              </StyledList>
            </WrapperList>
          </BoxCalendarCardInfo>
        </Modal>
      )}
    </ScheduleCard>
  );
}

export function CalendarClinicCard({ ...props }) {
  const { event } = props;
  const { attributes, type } = event;
  const { deleteEvent } = useEvent();
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);

  const handleDelete = async (event) => {
    setModal(false);
    const status = getCancellationStatus(
      event.attributes.startDate,
      event.attributes.startTime
    );
    if (status === 'cancellable' || 'uncancellable_less_than_24h') {
      const response = await deleteEvent(event.id);
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else if (status === 'uncancellable_already_done') {
      toast.warning('Ops.. parece que este agendamento ja foi realizado');
    } else {
      toast.warning('Esse agendamento não pode ser cancelado!');
    }
  };

  const infoCard = () => {
    if (attributes?.professional && attributes?.client) {
      return `${attributes?.professional?.firstName} - ${attributes?.client?.firstName}`;
    } else if (type === 'blockedTime') {
      return 'Horário bloqueado';
    } else return 'Horário Livre';
  };

  return (
    <ScheduleCard
      type={attributes?.appointmentType ? attributes.appointmentType : type}
    >
      <ScheduleClientClinic type={type}>{infoCard()}</ScheduleClientClinic>
      <ScheduleInfoClinic>
        <ScheduleWrapperAvatar>
          <ScheduleClientAvatar src={attributes?.client?.avatar} />
          <ScheduleProfessionalAvatar src={attributes?.professional?.avatar} />
        </ScheduleWrapperAvatar>
        <ScheduleWrapperClinic>
          <ScheduleClinicTime type={type}>
            {moment(attributes?.startTime, 'HH:mm:ss').format('HH:mm')} -{' '}
            {moment(attributes?.startTime, 'HH:mm:ss')
              .add(attributes?.duration, 'minutes')
              .format('HH:mm')}
          </ScheduleClinicTime>
          <WrapperButtonsClinic>
            {attributes?.appointmentType === 'online' ? (
              <Tooltip title="Atendimento Online">
                <PlaceButtonClinic
                  onClick={() => {
                    setModalInfo(true);
                  }}
                  style={{ padding: '0.25rem' }}
                >
                  <CameraIcon
                    style={{ color: '#fff', height: '20px', width: '20px' }}
                  />
                </PlaceButtonClinic>
              </Tooltip>
            ) : (
              <Tooltip title="Atendimento Presencial">
                <PlaceButtonClinic
                  onClick={() => {
                    setModalInfo(true);
                  }}
                >
                  <Place style={{ color: '#fff' }} />
                </PlaceButtonClinic>
              </Tooltip>
            )}

            <Tooltip title="Cancelar agendamento">
              <DeleteButtonClinic
                onClick={() => {
                  setModal(true);
                }}
              >
                <TrashIcon style={{ color: '#fff' }} />
              </DeleteButtonClinic>
            </Tooltip>
          </WrapperButtonsClinic>
        </ScheduleWrapperClinic>
      </ScheduleInfoClinic>

      {modal && (
        <Modal open>
          <BoxCalendarCard>
            <Typography variant="h6">
              {' '}
              O agendamento selecionado será cancelado. Essa ação é
              irreversível, deseja continuar?{' '}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
              sx={{ mt: 2 }}
            >
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setModal(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => handleDelete(event)}
                size="small"
                variant="contained"
              >
                Sim
              </Button>
            </Grid>
          </BoxCalendarCard>
        </Modal>
      )}

      {modalInfo && (
        <Modal open>
          <BoxCalendarCardInfo>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <Typography
              variant="p"
              sx={{
                fontWeight: '700',
                fontSize: '1.7rem',
              }}
            >
              Detalhes do agendamento
            </Typography>
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              sx={{ mt: 2 }}
            >
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ModalAvatar src={attributes?.client?.avatar} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ModalAvatar src={attributes?.professional?.avatar} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Paciente
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.client?.firstName}{' '}
                    {attributes?.client?.lastName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Profissional
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.professional?.firstName}{' '}
                    {attributes?.professional?.lastName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Email
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.client?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Email
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.professional?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Telefone
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.client?.phone}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Telefone
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.professional?.phone}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Dia
                  </Typography>
                  <Typography variant="body1">
                    {moment(attributes?.startDate, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY'
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Hora
                  </Typography>
                  <Typography variant="body1">
                    {moment(attributes?.startTime, 'HH:mm:ss').format('HH:mm')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Status
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.scheduleStatus}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Tipo
                  </Typography>
                  <Typography variant="body1">
                    {attributes?.appointmentType === 'online'
                      ? 'Online'
                      : 'Presencial'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </BoxCalendarCardInfo>
        </Modal>
      )}
    </ScheduleCard>
  );
}

export function EventCard({ ...props }) {
  const history = useHistory();
  const { deleteEvent } = useEvent();
  const {
    closeModal,
    dashboard,
    event,
    eventType,
    past,
    reportClient,
    setSchedulesModal,
  } = props;
  const { user } = useAuth();
  const { setLoadingEvent } = useEvent();
  const { attributes } = event;
  const [modalCancelStep1, setModalCancelStep1] = useState(false);
  const [modalCancelStep2, setModalCancelStep2] = useState(false);
  const [modalEvaluate, setModalEvaluate] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [typePayment, setTypePayment] = useState(null);
  const [modalAddress, setModalAddress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const [report, setReport] = useState(null);
  const [reportAlert, setReportAlert] = useState(false);
  const [pixData, setPixData] = useState(null);
  const [creditCards, setCreditCards] = useState(null);
  const [creditCardId, setCreditCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingPix, setLoadingPix] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const [modalPostPaymentAlert, setModalPostPaymentAlert] = useState(false);
  const { professional, startDate, startTime, paymentStatus, payment } =
    attributes;

  const handleDelete = async (event) => {
    setModalCancelStep1(false);
    setModalCancelStep2(false);
    if (
      getCancellationStatus(
        event.attributes.startDate,
        event.attributes.startTime
      ) === 'cancellable'
    ) {
      const response = await deleteEvent(event.id);
      if (response.status === 200) {
        if (closeModal) {
          setSchedulesModal({
            open: false,
            schedules: [],
          });
        }
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else if (
      getCancellationStatus(
        event.attributes.startDate,
        event.attributes.startTime
      ) === 'uncancellable_less_than_24h'
    ) {
      const response = await deleteEvent(event.id);
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.warning('Esse agendamento não pode ser cancelado!');
    }
    history.push('/client/dashboard');
  };

  const handleModal = (num) => {
    if (num === '1') {
      if (
        getCancellationStatus(
          event.attributes.startDate,
          event.attributes.startTime
        ) === 'uncancellable_less_than_24h'
      ) {
        setModalCancelStep1(false);
        setModalCancelStep2(true);
      } else {
        handleDelete(event);
      }
    } else if (num === '2') {
      handleDelete(event);
    }
  };

  const handleStatus = (status) => {
    if (
      [
        'refunded',
        'waiting_payment',
        'pending_refund',
        'refused',
        'pending',
        null,
      ].includes(status) &&
      !past
    ) {
      return (
        <Tooltip
          title={user.type === 'client' ? 'Realizar pagamento' : 'Não pago'}
        >
          <PayButton
            onClick={() => {
              if (user.type === 'client') {
                setTypePayment('payment');
                getCreditCards();
                getPixData();
              } else toast.warning('Pagamento não registrado');
            }}
          >
            <PayIcon />
          </PayButton>
        </Tooltip>
      );
    }
  };

  const handleAdvancePayment = (payment) => {
    if (!past && payment?.paymentStatus !== 'paid') {
      return (
        <Tooltip
          title={`Antecipar pagamento! Pagamento previsto ${moment(
            payment?.postPaidDate
          ).format('DD/MM/YYYY')}`}
        >
          <PayButton
            onClick={() => setModalPostPaymentAlert(true)}
            AdvancePayment
          >
            <MonetizationOn color="success" style={{ fontSize: '1.2rem' }} />
          </PayButton>
        </Tooltip>
      );
    }
  };

  const handleReportProfessional = async (id) => {
    try {
      setLoadingReport(true);
      const request = {
        data: {
          attributes: {
            message: 'Reportado por não comparecer na consulta',
            typeReport: 1,
            professionalId: id,
          },
        },
      };
      const { data } = await api.post('/clients/reports', request);
      if (data.data) {
        setReportAlert(true);
      }
    } catch (error) {
      toast.error('Erro ao reportar profissional');
    } finally {
      setLoadingReport(false);
      setReport(null);
    }
  };

  const submitPayment = async () => {
    setLoading(true);
    try {
      const couponId = event?.attributes?.payment?.couponId || '';

      const request = {
        data: {
          type: 'payment',
          attributes: {
            clientId: user.id,
            couponId,
            creditCardId,
            paymentMethod,
            scheduleId: event?.id,
          },
        },
      };

      const { data } = await api.post(`/clients/payments`, request);
      if (data.data) {
        toast.success('Agendamento foi Pago!');
      } else {
        toast.error('Não foi possível pagar o agendamento!');
      }
    } catch (error) {
      toast.error('Não foi possível pagar o agendamento!');
    } finally {
      setModalPayment(false);
      setTypePayment(null);
      setLoading(false);
      setLoadingEvent(true);
    }
  };

  const getCreditCards = async () => {
    try {
      setLoadingCard(true);
      const { data } = await api.get(`clients/credit_cards`);
      setCreditCards(data.data);
    } catch (error) {
      toast.error('Erro ao buscar dados do cartão');
    } finally {
      setLoadingCard(false);
    }
  };

  const getPixData = async () => {
    try {
      setModalPayment(true);
      setLoadingPix(true);
      const id = event?.id;
      const couponId = event?.attributes?.payment?.couponId || '';

      const { data } = await api.post('/clients/payments', {
        data: {
          type: 'payment',
          attributes: {
            clientId: user.id,
            couponId,
            paymentMethod: 'pix',
            scheduleId: id,
          },
        },
      });
      setPixData(data?.data);
    } catch (error) {
      toast.error('Erro ao buscar dados do pix');
    } finally {
      setLoadingPix(false);
    }
  };

  const [isReview] = useState(eventType === 'review');

  return (
    <Event>
      <EventRowItem>
        <EventColumnItem>
          <EventDate style={{ fontWeight: '700', fontSize: '1rem' }}>
            {`${moment(startDate).format('DD [de] MMMM [de] YYYY')}`}{' '}
          </EventDate>
          <EventDate>
            {`${formatedHour(startTime)} - ${moment(startTime, 'HH:mm')
              .add(1, 'hours')
              .format('HH:mm')}`}
          </EventDate>
        </EventColumnItem>
        {!dashboard && (
          <EventColumnItem>
            {['Assinatura de Consultas', 'Crédito de Consulta'].includes(
              payment.paymentMethod
            ) ? (
              <EventType>
                <EventProfessional>{payment?.paymentMethod}</EventProfessional>
              </EventType>
            ) : (
              <EventType>
                {`Valor: `}
                <EventProfessional>
                  {convertCurrencyPtBr(payment?.paymentAmount)}
                </EventProfessional>
              </EventType>
            )}
          </EventColumnItem>
        )}
        {!isReview && (
          <ButtonsRow>
            {past ? (
              <Fragment>
                <Tooltip title="Reportar profissional">
                  <ReportButtonEvent
                    onClick={() => {
                      setReport(professional);
                    }}
                  >
                    <ReportIcon />
                  </ReportButtonEvent>
                </Tooltip>
                <EvaluateButton
                  onClick={() => {
                    setModalEvaluate(true);
                  }}
                >
                  Avaliar
                </EvaluateButton>
                {/* PDF */}
                <PdfGenerator id={event.id} />
              </Fragment>
            ) : (
              <Fragment>
                {attributes?.appointmentType === 'in_person' && (
                  <Tooltip title="Endereço do profissional">
                    <PlaceButton
                      onClick={() => {
                        setModalAddress(true);
                      }}
                    >
                      <Place />
                    </PlaceButton>
                  </Tooltip>
                )}
                <Tooltip title="Cancelar agendamento">
                  <DeleteButton
                    onClick={() => {
                      setModalCancelStep1(true);
                    }}
                  >
                    <TrashIcon />
                  </DeleteButton>
                </Tooltip>
              </Fragment>
            )}
            {!reportClient && (
              <Tooltip title="Enviar mensagem">
                <MessageButton
                  onClick={() => history.push(`/${user.type}/messages`)}
                >
                  <MessageIcon />
                </MessageButton>
              </Tooltip>
            )}
            {payment?.postPaid
              ? handleAdvancePayment(payment)
              : handleStatus(paymentStatus)}
            {payment?.externalPayment && paymentStatus !== 'paid' && (
              <Tooltip title="Pagamento deve realizado diretamente com a clínica.">
                <PayButton>
                  <RequestQuote color="error" style={{ fontSize: '1.2rem' }} />
                </PayButton>
              </Tooltip>
            )}
          </ButtonsRow>
        )}
      </EventRowItem>

      <EventRowItem>
        <EventType>
          {`${getProfessionName(professional?.profession, 'toPt')}: `}
          <EventProfessional>{handleUserNames(professional)}</EventProfessional>
        </EventType>
      </EventRowItem>

      {isReview ? (
        <div>
          <LinkButton
            slim
            onClick={() => setModalEvaluate(true)}
            label={'Avaliar'}
          />
          <div style={{ paddingTop: '5px' }}>
            <LinkButton
              slim
              onClick={() =>
                history.push(
                  `/client/schedule/${event.attributes.professional.id}`
                )
              }
              label={'Novo agendamento'}
            />
          </div>
        </div>
      ) : (
        <LinkButton
          slim
          target="_blank"
          disabled={
            past
              ? false
              : attributes.appointmentType === 'in_person' ||
                getCancellationStatus(
                  attributes.startDate,
                  attributes.startTime
                ) === 'cancellable' ||
                getCancellationStatus(
                  attributes.startDate,
                  attributes.startTime
                ) === 'time_exceeded' ||
                paymentStatus !== 'paid'
          }
          onClick={() => {
            const today = moment().toDate();
            const dateTime = converDateHour(
              attributes.startDate,
              attributes.startTime
            );

            if (
              !past &&
              moment.duration(moment(dateTime).diff(today)).asHours() < -1
            )
              return toast.warning('Esse agendamento não está mais disponivel');
            if (past) {
              history.push(
                `/client/schedule/${event.attributes.professional.id}`
              );
            } else {
              if (attributes?.ownMeetingLink) {
                window.open(attributes.ownMeetingLink, '_blank');
              } else {
                history.push({
                  pathname: `/client/meeting`,
                  state: { schedule: { id: event.id, ...attributes } },
                });
              }
            }
          }}
          label={
            past
              ? 'Novo Agendamento'
              : attributes.appointmentType === 'in_person'
                ? 'Presencial'
                : getCancellationStatus(
                      attributes.startDate,
                      attributes.startTime
                    ) === 'cancellable'
                  ? 'Agendado'
                  : getCancellationStatus(
                        attributes.startDate,
                        attributes.startTime
                      ) === 'time_exceeded'
                    ? 'Consulta Expirada'
                    : 'Iniciar Atendimento'
          }
        />
      )}

      {modalCancelStep1 && (
        <Modal open>
          <BoxCancelInformation>
            <Typography variant="h6">
              {' '}
              O agendamento selecionado será cancelado. Essa ação é
              irreversível, deseja continuar?{' '}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setModalCancelStep1(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => handleModal('1')}
                size="small"
                variant="contained"
              >
                Sim
              </Button>
            </Grid>
          </BoxCancelInformation>
        </Modal>
      )}

      {modalCancelStep2 && (
        <Modal
          open
          onClose={() => setModalCancelStep2(false)}
          ariaHideApp={false}
        >
          <BoxCancelInformation>
            <Typography variant="h6">
              {' '}
              Agendamentos cancelados dentro de 24 horas não serão estornados,
              deseja continuar?{' '}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setModalCancelStep2(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => handleModal('2')}
                size="small"
                variant="contained"
              >
                Sim
              </Button>
            </Grid>
          </BoxCancelInformation>
        </Modal>
      )}

      {modalPayment && (
        <Modal open>
          <PaymentModal>
            <IconArea>
              <X
                style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                onClick={() => setModalPayment(false)}
              />
            </IconArea>
            {loadingPix || loading ? (
              <LoaderSpinner />
            ) : (
              <PaymentWrapper>
                <PaymentTitle>
                  {typePayment === 'payment' ? 'Realizar' : 'Antecipar'}{' '}
                  Pagamento
                </PaymentTitle>
                <Fragment>
                  <RowLine style={{ justifyContent: 'center' }}>
                    <CardButtonMethod
                      checked={paymentMethod === 'pix'}
                      onClick={() => setPaymentMethod('pix')}
                    >
                      PIX
                    </CardButtonMethod>
                    {/* <CardButtonMethod
                      checked={paymentMethod === 'credit_card'}
                      onClick={() => setPaymentMethod('credit_card')}
                    >
                      Cartão de Crédito
                    </CardButtonMethod> */}
                  </RowLine>

                  <Hr style={{ marginTop: '1rem', width: '90%' }} />

                  {paymentMethod !== 'pix' ? (
                    <WrapperPayment>
                      <ContainerCardPayment>
                        {loadingCard ? (
                          <LoaderSpinner />
                        ) : (
                          <ListCardPayment
                            data={creditCards}
                            renderItem={(item, index) => (
                              <CreditCardListItem
                                key={index}
                                data={item}
                                selected={creditCardId}
                                setSelected={setCreditCardId}
                                exclude
                              />
                            )}
                          />
                        )}
                      </ContainerCardPayment>

                      <PaymentButton
                        disabled={creditCards?.length === 0}
                        onClick={() => {
                          if (!creditCardId) {
                            toast.error('Cadastre um cartão');
                          } else {
                            submitPayment();
                          }
                        }}
                        checked={paymentMethod}
                      >
                        Realizar pagamento
                      </PaymentButton>
                    </WrapperPayment>
                  ) : (
                    pixData && <PayPix data={pixData} />
                  )}
                </Fragment>
              </PaymentWrapper>
            )}
          </PaymentModal>
        </Modal>
      )}

      {modalEvaluate && (
        <Modal open>
          <BoxEvaluate>
            <IconArea>
              <X
                style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                onClick={() => setModalEvaluate(false)}
              />
            </IconArea>
            <ReviewProfessional eventId={event.id} />
          </BoxEvaluate>
        </Modal>
      )}

      {modalAddress && (
        <Modal open>
          <ModalAddress>
            <IconArea>
              <X
                style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                onClick={() => setModalAddress(false)}
              />
            </IconArea>
            <div className="--modal-wrapper">
              <h2 className="--title">Dados do Profissional</h2>
              <h4 className="--text">{`Nome: ${camelize(
                professional?.firstName
              )}`}</h4>
              <h4 className="--text">{`Sobrenome: ${camelize(
                professional?.lastName
              )}`}</h4>
              <h4 className="--text --end">{`Especialidade: ${getProfessionName(
                professional?.profession,
                'toPt'
              )}`}</h4>
              <h2 className="--title">Endereço de Atendimento</h2>
              <h4 className="--text">{`Rua/Av: ${camelize(
                professional?.address?.street
              )}`}</h4>
              <h4 className="--text">{`Número: ${
                professional?.address?.street_number || 'S/N'
              }`}</h4>
              <h4 className="--text">{`Bairro: ${camelize(
                professional?.address?.neighborhood
              )}`}</h4>
              <h4 className="--text">{`Cidade: ${camelize(
                professional?.address?.city
              )}`}</h4>
              <h4 className="--text">{`Estado: ${camelize(
                professional?.address?.state
              )}`}</h4>
            </div>
          </ModalAddress>
        </Modal>
      )}

      {loadingReport ? (
        <LoaderSpinner />
      ) : (
        report && (
          <Modal open>
            <ModalReport>
              <ReportBoxWrapper>
                <ReportTitle>Reportar este especialista?</ReportTitle>
                <ReportIconArea>
                  <ReportIconAlert />
                </ReportIconArea>
                <ReportParagraph>
                  Reporte para o time Allminds que esse profissional não
                  compareceu a sessão online.
                </ReportParagraph>
                <Hr />
                <ReportButtonWrapper>
                  <ReportButton onClick={() => setReport(null)}>
                    Cancelar
                  </ReportButton>
                  <ReportButton
                    primary
                    onClick={() => handleReportProfessional(report.id)}
                  >
                    Reportar
                  </ReportButton>
                </ReportButtonWrapper>
              </ReportBoxWrapper>
            </ModalReport>
          </Modal>
        )
      )}

      {loadingReport ? (
        <LoaderSpinner />
      ) : (
        reportAlert && (
          <Modal open>
            <ModalReport>
              <ReportBoxWrapper>
                <IconArea>
                  <X
                    style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                    onClick={() => setReportAlert(false)}
                  />
                </IconArea>
                <ReportTitle>Reporte efetuado!</ReportTitle>
                <ReportIconArea>
                  <ReportIconCheck />
                </ReportIconArea>
                <Hr />
                <ReportParagraph>
                  Este profissional foi reportado ao equipe de administradores
                  da Allminds por não comparecer a sessão online agendada.
                </ReportParagraph>
              </ReportBoxWrapper>
            </ModalReport>
          </Modal>
        )
      )}

      {modalPostPaymentAlert && (
        <ModalAlert
          confirm={() => {
            if (user.type === 'client') {
              getPixData();
              setTypePayment('advance');
            } else toast.warning('Pagamento não registrado');
            setModalPostPaymentAlert(false);
          }}
          goBack={() => setModalPostPaymentAlert(false)}
          message={
            <Fragment>
              <p>
                <strong>
                  Você deseja fazer a antecipação do pagamento via PIX?
                </strong>
              </p>
              <br />
              <p>{`Caso o pagamento não seja realizado a cobrança será realizada no cartão de crédito cadastrado no dia ${moment(
                payment?.postPaidDate
              ).format('DD/MM/YYYY')}?`}</p>
            </Fragment>
          }
          title="Pagamento pós pago"
        />
      )}
    </Event>
  );
}
