export const PtBr = {
  about_us_description_title:
    'Para você que preza por saúde emocional e uma boa performance acadêmica e profissional, criamos a Allmindz.',
  about_us_description_text:
    'Uma plataforma que reúne profissionais de saúde mental e educação, além de conteúdos e cursos especializados.',
  about_us_schedule_title: 'Quer agendar um atendimento online ou presencial?',
  about_us_schedule_cards: [
    { type: 'Psicólogos(a)', complement: undefined },
    { type: 'Psiquiatras(a)', complement: undefined },
    { type: 'Neuropediatras(a)', complement: undefined },
    { type: 'Professores(a)', complement: '(Reforço escolar)' },
    { type: 'Psicopedagogos(a)', complement: undefined },
    { type: 'Orientador(a) Profissional', complement: undefined },
  ],
  about_us_help_title: 'Como podemos ajudar:',
  about_us_help_cards: [
    { type: 'Desenvolva habilidades socioemocionais', icon: 'smile_1' },
    {
      type: 'Lide melhor com emoções, sintomas ansiosos e questões relacionadas à autoestima',
      icon: 'hand_1',
    },
    {
      type: 'Aprimore suas habilidades cognitivas e motoras',
      icon: 'hand-write',
    },
    {
      type: 'Utilize ferramentas e estratégias que favoreecm o processo de aprendizaem',
      icon: 'pen',
    },
    {
      type: 'Acesse orientação educacional, profissional e de carreita',
      icon: 'book_open',
    },
    {
      type: 'prepare-se emcional e academicamente para provas, vestibulares e concursos',
      icon: 'smile_2',
    },
  ],
  about_us_schedule_button: 'Quero Agendar',
  about_us_platform_title: 'Nossa plataforma é segura e conveniente',
  about_us_platfrom_cards: [
    { type: 'Escolha como e com quem será seu atendimento ', icon: 'smile_2' },
    {
      type: 'Marque ou cancele as consultas online. Receba notificações no seu celular',
      icon: 'smile_2',
    },
    { type: 'Defina como prefere fazer o pagamento', icon: 'smile_2' },
    {
      type: 'Aproveite a sua sessão presencial ou online (videochamadas encriptadas)',
      icon: 'smile_2',
    },
    {
      type: 'Expanda a sua mente com a nossa biblioteca de conteúdos e cursos',
      icon: 'smile_2',
    },
    {
      type: 'Converse com seu profissional por chat, 24/7, antes ou após os atendimentos',
      icon: 'smile_2',
    },
  ],
  days_name_availability: [
    'no Domingo',
    'na Segunda Feira',
    'na Terça Feira',
    'na Quarta Feira',
    'na Quinta Feira',
    'na Sexta Feira',
    'no Sábado',
  ],
  days_name: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  days_name2: {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  },
  days_name_convert: {
    Sunday: 'Domingo',
    Monday: 'Segunda',
    Tuesday: 'Terça',
    Wednesday: 'Quarta',
    Thursday: 'Quinta',
    Friday: 'Sexta',
    Saturday: 'Sábado',
  },
  days_abs: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  months_name: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  months_name_convert: {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro',
  },
  // input text
  input_email: 'Email',
  input_password: 'Senha',
  register_input_firstname: 'Nome',
  register_input_birthday: 'Data de Nascimento',
  register_input_lastname: 'Sobrenome',
  register_input_member: 'Convenio com',
  register_input_clinic: 'Clínica',
  register_input_email: 'Email',
  register_input_username:
    'Nome para link de busca personalizado (Obs: sem espaços e caracteres especiais)',
  register_input_phone: 'Telefone',
  register_input_cpf_rg: 'CPF',
  register_input_cpf_cnpj: 'CPF ou CNPJ',
  register_input_password: 'Senha',
  register_input_confirm_password: 'Repita a senha',

  register_input_legalGuardianFullName: 'Nome Completo do(a) responsável',
  register_input_legalGuardianEmail: 'Email do(a) responsável',
  register_input_legalGuardianDocumentNumber: 'CPF ou RG do(a) responsável',
  register_input_legalGuardianPhone: 'Telefone do(a) responsável',

  register_input_online: 'Vou atender somente online.',
  register_input_appointmentTypes: 'Tipos de atendimento',
  register_input_cep: 'CEP',
  register_input_street: 'Endereço',
  register_input_neighborhood: 'Bairro',
  register_input_complement: 'Complemento',
  register_input_street_number: 'Número',
  register_input_country: 'País',
  register_input_city: 'Cidade',

  register_input_job: 'Profissão',
  register_input_formation: 'Formação',
  register_input_job_register: 'N de Registro Profissional',
  register_input_specialty: 'Especializações',
  register_input_reasons: 'Motivos',
  register_input_approaches: 'Abordagens',
  register_input_bio: 'Bio',
  register_input_about_you: 'Fale sobre sua experiência.',
  register_input_age_group: 'Faixa etária',
  register_input_experience_time: 'Tempo de experiência em anos',
  register_input_session_type: 'Tipo de sessão',
  register_input_presetation_video: 'Video de apresentação',
  register_input_currency: 'R$',
  register_input_attendance_price: 'Preço por atendimento',
  register_input_attendance_time: 'Tempo de atendimento',
  register_input_free_first_try: 'Primeiro atendimento gratuito',
  register_input_free_days: 'Quais dias da semana você está disponível?',
  register_input_time_block:
    'Defina qual horário você começa e termina seus atendimentos:',
  register_input_subscribe: 'Selecionar Plano',
  register_input_subscribe_free_trial: {
    title: 'Gratuito',
    value: {
      price: 0,
      unidade: 'mês',
      data: 'free',
      tplanName: 'Gratuito',
    },
  },
  register_input_subscribe_methods: [
    {
      title: 'Gestão',
      value: {
        price: 99,
        unidade: 'mês',
        data: 'platinum',
        tplanName: 'Gestão',
      },
    },
  ],
  register_input_subscribe_methods_founder: [
    {
      title: 'Ouro Fundador',
      value: {
        price: 75,
        unidade: 'mês',
        data: 'founder_gold',
        tplanName: 'Ouro Fundador',
      },
    },
    {
      title: 'Gestão Fundador',
      value: {
        price: 262.5,
        unidade: 'mês',
        data: 'founder_platinum',
        tplanName: 'Gestão Fundador',
      },
    },
  ],
  register_input_payment: {
    title: 'Forma de Pagamento',
    newTitle: 'Nova forma de pagamento',
  },
  register_input_payment_methods: ['Cartão de Crédito'],
  register_input_card_number: 'Número do Cartão',
  register_input_card_cvv: 'CVV',
  register_input_card_validade: 'Data de validade',
  register_input_card_holder_name:
    'Titular do Cartão (Do mesmo jeito que está no cartão)',
  register_button_upload: 'Upload',
  register_button_select: 'Selecionar',

  dashboard_filter_buttons: ['Tudo', 'Carreira', 'Relacionamento'],

  // empty state
  event_empty_description: 'Você ainda não tem serviços',
  see_professional_profile: 'Visitar perfil',
  edit_professional_profile: 'Editar perfil',

  pages_text: {
    new_bank_account_header: 'Adicionar Conta Bancária',
    new_bank_account_subheader:
      'Cadastre uma conta para receber seus pagamentos',

    time_blocks: {
      title: 'Disponibilidade',
      subtitle:
        'Defina os horários que não estará disponivel para atender pela Allminds',
    },
  },

  cupons_dashboard: {
    active_cupons: 'Cupons ativos',
    empty_state_text: 'Você ainda não tem cupons ...',
    delete_cupon_info: '*Para apagar cupons clique no cupom e aperte em apagar',
    page_title: 'Cupons',
    page_subtitle: 'Crie cupons de desconto e ofereça para seus clientes',
  },

  bank_account: {
    bankCode_placeholder: 'Selecione o banco',
    agency_placeholder: 'Agência',
    account_placeholder: 'Conta',
    accountType_placeholder: 'Tipo de conta',
  },

  button_text: {
    cancel: 'Cancelar',
    save: 'Salvar',
    lock: 'Bloquear',
    unlock: 'Desbloquear',
    create_cupon: 'Criar cupom',
  },
  register_input_schoolType: 'Tipo do conveniado',

  // select options text
  dashboard_filter_select_options: {
    totalRevenue: 'Receita total',
    avgRevenuePerProfessional: 'Receita média por profissional',
    appointmentsCompleted: 'Consultas realizadas',
    appointmentsPerProfessional: 'Consultas realizadas por profissional',
    avgValuePerAppointment: 'Valor médio por consulta',
    percentSchedulesCanceled: 'Percentual de agendamentos cancelados',
    schedulesCanceled: 'Agendamentos cancelados',
    registeredProfessionals: 'Profissionais cadastrados',
    totalClients: 'Total de clientes',
  },
};
