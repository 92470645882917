/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import InviteForm from '../../../components/FormikElements/InviteForm';
import { Flatlist } from '../../../components/List';
import { PatientListItem } from '../../../components/ListItens';
import api from '../../../services/api';
import useWindowSize from '../../../utils/WindowSize';
import { Main } from './styles';

export default function ClientList() {
  const [patients, setPatients] = useState([]);
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPatients = async () => {
      try {
        const { data } = await api.get('/clinics/patients', {
          signal: controller.signal,
        });
        setPatients(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar lista de pacientes');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();

    return () => controller.abort();
  }, []);

  const submitInvite = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            name: values.name,
            email: values.email,
            phone: values.phone,
            city: values.city,
            message: values.message,
            receiver: 'client',
          },
        },
      };

      const { data } = await api.post('/clinics/invites', request);
      if (data) {
        toast.success('Convite enviado com sucesso!');
      }
    } catch (error) {
      toast.error('Não foi possivel enviar o convite!');
    } finally {
      setLoading(false);
      form.resetForm();
    }
  };

  const schemaInvite = Yup.object({
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 caracteres')
      .required('Nome é obrigatório!'),
    email: Yup.string()
      .email('Email deve ser um Email válido')
      .required('Email é obrigatório!'),
    phone: Yup.string().optional(),
    city: Yup.string().optional(),
    message: Yup.string().optional(),
  });

  const form = useFormik({
    initialValues: {
      id: null,
      name: '',
      email: '',
      phone: '',
      city: '',
      message: '',
      newSplitPercentage: 0,
      newOwnMeetingLink: null,
      newPreferOwnMeetingLink: false,
    },
    validationSchema: schemaInvite,
    onSubmit: (values) => submitInvite(values),
    validateOnChange: true,
  });

  return (
    <Main>
      <Flatlist
        type="patient"
        data={patients}
        filterObject={(item) => item.attributes.client.firstName}
        placeholder="Pesquise um Cliente"
        renderItem={(item, index) => (
          <PatientListItem key={index} data={item} width={width} clinic />
        )}
        loading={loading}
      />

      <InviteForm form={form} type="client" />
    </Main>
  );
}
