import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BigFont,
  ScrollPageStyle,
  SmallFont,
} from '../../../styles/globalStyles';

export const MainScroll = styled(ScrollPageStyle)`
  width: 100%;
`;

export const MainCard = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.newPrimary};
  border: 1px solid ${({ theme }) => theme.newPrimaryAlt};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  ${({ gridarea }) => gridarea && `grid-area: ${gridarea}`};
  justify-content: center;
  padding: 0.2rem 1rem;
  width: 100%;
`;

export const CardTitle = styled(SmallFont)`
  color: ${({ theme }) => theme.black};
  font-size: 0.95rem;
  font-size: 700;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
`;

export const CardValue = styled(BigFont)`
  color: ${({ theme }) => theme.primary};
  font-size: 1.1rem;
`;

export const ContainerImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const BannerImage = styled.img`
  border-radius: 1rem;
  height: 100%;
  max-height: 10rem;
  object-fit: cover;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    height: 10rem;
  }
`;

export const ArtClinic = styled(BannerImage)`
  max-height: max-content;
  margin: 2rem 0;
`;

export const LogoImage = styled(BannerImage)`
  max-width: 10rem;
  max-height: 5rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    max-width: 5rem;
    max-height: 10rem;
  }
`;

export const MainDashboard = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    'revenueRealized appointmentsCompleted percentOnlineAppointments totalClients registeredProfessionals'
    'revenueForecasted appointmentsScheduled percentRevenuePix reviews avgRevenuePerProfessional'
    'totalRevenue avgValuePerAppointment percentRevenueCreditCard percentPositiveReviews appointmentsPerProfessional'
    'percentRevenueExternal . . . .';
  grid-template-columns: repeat(5, 1fr);
  margin: 2rem 0;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'revenueRealized appointmentsCompleted percentOnlineAppointments'
      'revenueForecasted appointmentsScheduled percentRevenuePix'
      'totalRevenue avgValuePerAppointment percentRevenueCreditCard'
      'totalClients registeredProfessionals reviews'
      'avgRevenuePerProfessional percentPositiveReviews appointmentsPerProfessional';
  }
`;

export const ButtonFilterWrapper = styled.div`
  border-radius: 0.2rem;
  border: 1px solid ${({ theme }) => theme.newPrimaryAlt};
  display: flex;
`;

export const ButtonFilterParameter = styled.button`
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.primary : theme.white};
  border-right-color: ${({ theme }) => theme.black};
  border-right-style: solid;
  border-right-width: 1px;
  color: ${({ theme, active }) => (active ? theme.white : theme.black)};
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.primary : theme.newPrimaryAlt};
    color: ${({ theme, active }) => (active ? theme.white : theme.black)};
  }

  &:last-child {
    border-right: none;
  }
`;

export const FilterWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const GraphicWrapper = styled.div`
  overflow: auto;
  padding: 1rem 0.5rem;

  &::-webkit-scrollbar {
    background-color: ${({ primary }) => (primary ? '#eaecf4' : '#ced0db')};
    border-radius: 5px;
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ primary }) => (primary ? '#eaecf4' : '#ced0db')};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999ebc;
    border-radius: 5px;
    height: 2rem;
  }
`;

export const CloseButton = styled.button`
  align-items: center;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
`;

export const ClinicImage = styled.div`
  background-color: ${({ theme }) => theme.white};
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.newGray};
  bottom: 0;
  height: 9rem;
  left: 1rem;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  width: 9rem;
`;
