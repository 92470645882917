import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
} from '../../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: center;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  width: 100%;

  .--input-wrapper {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
      gap: 2rem;
      grid-template-columns: 1fr;
    }

    &-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: ${({ theme }) => theme.secondary};
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding: 10px 0;
      color: ${({ theme }) => theme.darkGray};
    }

    &-link {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      align-self: center;
      padding: 50px 0 10px 0;
      color: ${({ theme }) => theme.lightGray};
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    row-gap: 1rem;
  }
`;

export const MainPlans = styled.div`
  width: 100%;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-items: stretch;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    gap: 1rem;
    .subscribe {
      padding: 0;
    }
  }
`;

export const MainPayment = styled.div`
  width: 100%;
  display: flex;

  .subscribe {
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
  }

  .subscribe-form {
    max-width: 800px;
    width: 100%;
  }

  .--input-wrapper {
    align-self: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    .subscribe {
      align-items: center;
      justify-content: center;
      width: 90%;
    }
  }
`;

export const ContainerLeft = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerSignature = styled.div`
  background-color: ${({ theme }) => theme.primary_blue};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 2rem;
  padding: 2rem;
  width: 100%;

  .--signature-title {
    color: ${({ theme }) => theme.white};
    font-size: 1.5rem;
    padding: 1.5rem 0;
  }
  .--signature-type {
    color: ${({ theme }) => theme.white};
    font-size: 2rem;
    padding: 1.5rem 0;
  }
  .--signature-text {
    color: ${({ theme }) => theme.white};
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    .--signature-title {
      font-size: 1.5rem;
    }
    .--signature-type {
      font-size: 2rem;
    }
    .--signature-text {
    }
  }
`;

export const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
`;

export const ContainerPayment = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerCardPayment = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const WrapperSignature = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .--card-credit-list {
    background-color: ${({ theme }) => theme.newPrimary};
    height: 4rem;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
    border-radius: 0.5rem;
  }
`;

export const HeaderElement = styled.div`
  width: 100%;
  display: flex;
  height: 150px;
  padding: 40px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0px 0px 50% 50% / 0px 0px 15px 15px;

  .--title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    align-self: center;
    color: ${({ theme }) => theme.primaryAlt};
  }

  .--text {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    align-self: center;
    padding-top: 10px;
    color: ${({ theme }) => theme.primaryAlt};
  }
`;

export const ButtonPlan = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, secondary }) => secondary && theme.primary_blue};
  border-radius: 2rem;
  color: ${({ theme }) => theme.primary_blue};
  cursor: pointer;
  font-weight: 700;
  height: fit-content;
  margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0')};
  padding: 0.7rem 2rem;
  width: fit-content;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0')};
    width: 100%;
  }
`;

export const ButtonCancelPlan = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, secondary }) => secondary && theme.pink};
  border-radius: 2rem;
  color: ${({ theme }) => theme.pink};
  cursor: pointer;
  font-weight: 700;
  height: fit-content;
  margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0')};
  padding: 0.7rem 2rem;
  width: fit-content;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0')};
    width: 80%;
  }
`;

export const ButtonPlanWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 100%;
`;

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
`;
