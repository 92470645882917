/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormikControl from '../../../../components/FormikControl';
import PaymentForm from '../../../../components/FormikElements/PaymentForm';
import { SubscribeFormik } from '../../../../components/FormikElements/SubscribeForm';
import { CreditCardListItem } from '../../../../components/ListItens';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { NewSelect } from '../../../../components/SelectList';
import useAuth from '../../../../hooks/auth';
import useEvent from '../../../../hooks/event';
import api from '../../../../services/api';
import theme from '../../../../theme';
import { PLAN_LEVEL_FULL_ACCESS } from '../../../../utils/constantsLevelAccess';
import { getPreferencialPayments } from '../../../../utils/preferencialPayments';
import { ListCardPayment } from '../../ProfessionalUpdate/Subscribe';
import {
  ButtonConfirm,
  ConfigWrapper,
  ContentContainer,
  RowLine,
  TitleH1,
} from '../styles';

export function UpdateTypesReceipts({ form, user, validateInfos }) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    form;

  const preferentialPaymentTypes = useMemo(() => {
    if (values?.clinicId) {
      switch (values?.preferentialPayment) {
        case 'pix':
          return ['pix'];
        case 'credit_card':
          return ['credit_card'];
        default:
          return ['pix', 'credit_card', 'all_payment'];
      }
    }
    return ['pix', 'credit_card', 'all_payment'];
  }, [values?.clinicId]);

  const getPymentMethod = (type) => {
    switch (type) {
      case 'pix': {
        setFieldValue('acceptPix', true);
        setFieldValue('acceptCredit', false);
        return;
      }
      case 'credit_card': {
        setFieldValue('acceptCredit', true);
        setFieldValue('acceptPix', false);
        return;
      }
      case 'all_payment': {
        setFieldValue('acceptPix', true);
        setFieldValue('acceptCredit', true);
        return;
      }
      default: {
        setFieldValue('acceptPix', false);
        setFieldValue('acceptCredit', false);
      }
    }
  };

  useEffect(() => {
    const validateInfo =
      user?.acceptCredit === values?.acceptCredit &&
      user?.acceptPix === values?.acceptPix &&
      user?.prePaid === values?.prePaid &&
      user?.postPaid === values?.postPaid &&
      user?.externalPayment === values?.externalPayment &&
      user?.postPaidDay === values?.postPaidDay &&
      user?.preferentialPayment === values?.preferentialPayment;

    validateInfos(validateInfo);
    getPymentMethod(values?.preferentialPayment);
  }, [values]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Formas de recebimento</TitleH1>
        <RowLine />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '0 10px 20px 0',
        }}
      >
        {(PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel) ||
          values?.clinicPrePaid) && (
          <FormControlLabel
            name="prePaid"
            control={<Checkbox checked={values.prePaid} />}
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Aceitar pagamento PRÉ-PAGO (Padrão)
              </Typography>
            }
            onChange={handleChange}
          />
        )}
        {(PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel) ||
          values?.clinicPostPaid) && (
          <FormControlLabel
            name="postPaid"
            control={<Checkbox checked={values.postPaid} />}
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Aceitar pagamento PÓS-PAGO
              </Typography>
            }
            onChange={handleChange}
          />
        )}
        {(PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel) ||
          values?.clinicExternalPayment) && (
          <FormControlLabel
            name="externalPayment"
            control={<Checkbox checked={values.externalPayment} />}
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Aceitar pagamento EXTERNO
              </Typography>
            }
            onChange={handleChange}
          />
        )}
      </div>

      {values.postPaid && (
        <FormikControl
          control="input"
          disabled={values?.clinicId}
          errors={errors.postPaidDay}
          label="Dia de cobrança para agendamentos pós-pagos"
          name="postPaidDay"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.postPaidDay}
          type="number"
          upLabel
          value={values?.postPaidDay}
        />
      )}

      <div
        style={{
          marginTop: '2rem',
        }}
      >
        <NewSelect
          bgColor={theme.white}
          defaultValue={values.preferentialPayment}
          errors={errors.preferentialPayment}
          label="Formas de recebimentos"
          name="preferentialPayment"
          onChange={({ value }) => {
            setFieldValue('preferentialPayment', value);
          }}
          options={preferentialPaymentTypes?.map((type) => ({
            label: getPreferencialPayments(type),
            value: type,
          }))}
          placeholder="Selecione a forma de recebimento"
          touched={touched.preferentialPayment}
        />
      </div>
    </ConfigWrapper>
  );
}

export default function PaymentConfig() {
  const history = useHistory();
  const { user, setAuthData } = useAuth();

  const handleContinue = async (values) => {
    try {
      const {
        planName,
        cardNumber,
        cardCvv,
        cardExpiration_date,
        cardHolderName,
      } = values;
      if (
        ['silver', 'gold', 'platinum'].includes(planName) &&
        (cardNumber?.length < 16 ||
          cardCvv?.length < 3 ||
          cardExpiration_date?.length < 4)
      ) {
        toast.error('Dados do cartão são requeridos.');
      } else {
        if (values.planName === 'free') {
          const subscription = {
            data: {
              type: 'subscription',
              attributes: {
                planName: 'free',
              },
            },
          };
          if (user.subscription.id) {
            subscription.data.id = user.subscription.id;
            const { data } = await api.put(
              `professionals/subscriptions/${user.subscription.id}`,
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          } else {
            const { data } = await api.post(
              'professionals/subscriptions',
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          }
          toast.success(
            `Plano gratuito selecionado! Por favor autentique-se novamente.`
          );
        } else {
          const subscription = {
            data: {
              type: 'subscription',
              attributes: {
                planName,
                creditCardAttributes: {
                  cardNumber,
                  cardExpiration_date,
                  cardHolderName,
                  cardCvv,
                },
              },
            },
          };
          if (user.subscription.id) {
            subscription.data.id = user.subscription.id;
            const { data } = await api.put(
              `professionals/subscriptions/${user.subscription.id}`,
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          } else {
            const { data } = await api.post(
              'professionals/subscriptions',
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          }
          if (planName === 'gold') {
            toast.success(
              `Plano ouro assinado com sucesso! Por favor autentique-se novamente.`
            );
          } else if (planName === 'platinum') {
            toast.success(
              `Plano gestão assinado com sucesso! Por favor autentique-se novamente.`
            );
          } else if (planName === 'silver') {
            toast.success(
              `Plano prata assinado com sucesso! Por favor autentique-se novamente.`
            );
          }
        }
        history.push('/profissional/conectar-se');
      }
    } catch (error) {
      toast.error('Não foi possível cadastrar assinatura');
    }
  };

  return (
    <ContentContainer>
      <SubscribeFormik
        subscribe={user.subscription}
        handleContinue={handleContinue}
      />
    </ContentContainer>
  );
}

export function PaymentCardsList({ ...props }) {
  const { form, formAddCard, setFormAddCard } = props;
  const { user } = useAuth();
  const { creditCards, setCreditCards } = useEvent();
  const [loading, setLoading] = useState(true);
  const [filterDefaults, setFilterDefaults] = useState([]);
  const [patchCreditCard, setPatchCreditCard] = useState({});
  const [selected, setSelected] = useState(null);

  const handleDeleteCard = async (id) => {
    try {
      await api.delete(`${user.type}s/credit_cards/${id}`);
      toast.success('Cartão deletado com sucesso.');
      const newCreditCards = creditCards?.filter((item) => item.id !== id);
      setCreditCards(newCreditCards);
    } catch (error) {
      toast.error('Não foi possivel deletar o cartão');
    }
  };

  const handleClickDefault = async (id) => {
    try {
      const { data } = await api.patch(
        `${user?.type}s/credit_cards/${id}/set_default`
      );
      setPatchCreditCard(data.data);
      toast.success('Cartão de crédito definido como padrão');
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao definir cartão como padrão');
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getCreditCards = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`${user.type}s/credit_cards`, {
          signal: controller.signal,
        });
        setCreditCards(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao buscar histórico de cartões');
      } finally {
        setLoading(false);
      }
    };
    getCreditCards();

    return () => controller.abort();
  }, [patchCreditCard]);

  useEffect(() => {
    const filterDefaultsCard = creditCards?.filter(
      (item) => item.attributes.default === true
    );
    setFilterDefaults(filterDefaultsCard);
    form.setFieldValue('setDefault', filterDefaultsCard?.length === 0);
  }, [creditCards]);

  return (
    <ConfigWrapper>
      {loading ? (
        <LoaderSpinner />
      ) : formAddCard ? (
        <Fragment>
          <ButtonConfirm
            type="button"
            secondary
            onClick={() => setFormAddCard(false)}
          >
            Voltar
          </ButtonConfirm>
          <PaymentForm form={form} loading={loading} />
        </Fragment>
      ) : (
        <Fragment>
          <ButtonConfirm type="button" onClick={() => setFormAddCard(true)}>
            Adicionar forma de pagamento
          </ButtonConfirm>
          <ListCardPayment
            data={creditCards}
            renderItem={(item, index) => (
              <CreditCardListItem
                key={index}
                data={item}
                handleClickDefault={handleClickDefault}
                handleDeleteCard={handleDeleteCard}
                filterDefaults={filterDefaults}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          />
        </Fragment>
      )}
    </ConfigWrapper>
  );
}
