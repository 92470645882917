import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SelectMUI from '@mui/material/Select';
import React from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import theme from '../../theme';
import { TextError } from '../FormikControl/FormikComponents/styles';
import {
  Container,
  ContainerSelect,
  FieldLabel,
  StyledField,
  StyledLabel,
  StyledOption,
} from './styles';

export function SelectList({ ...props }) {
  const {
    bgColor,
    defaultValue,
    placeholder,
    stylesProp,
    sign,
    options,
    ...rest
  } = props || [];

  const theme = useTheme();

  const colourStyles = {
    control: () => ({
      alignItems: 'center',
      backgroundColor: bgColor || theme.newPrimaryAlt,
      border: `1px solid ${theme.ExtralightGray}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: 'Poppins',
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? theme.primary_blue
            : isFocused
              ? theme.newPrimaryAlt
              : undefined,
        color: isDisabled
          ? theme.newPrimaryAlt
          : isSelected
            ? theme.white
            : theme.black,
        cursor: isDisabled ? 'not-allowed' : 'pointer',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? theme.primary_blue
              : theme.primaryAlt
            : undefined,
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: theme.primary_blue,
        color: theme.white,
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      fontFamily: 'Poppins',
      color: theme.white,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      backgroundColor: theme.pink,
      color: theme.white,
      ':hover': {
        cursor: 'pointer',
      },
    }),
  };

  const menuPortalTarget = document.getElementById('root');

  return (
    <Container sign={sign} className="--filter-select">
      <Select
        defaultValue={defaultValue}
        noOptionsMessage={() => 'Sem mais opções'}
        className="--select"
        styles={colourStyles}
        options={options}
        placeholder={placeholder}
        maxMenuHeight={250}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        {...rest}
      />
    </Container>
  );
}

export function SelectModal({ stylesProp, ...props }) {
  const { placeholder } = props || [];
  const theme = useTheme();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderWidth: 0,
      borderRaius: 0,
      backgroundColor: theme.color3,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.darkGray,
    }),
  };
  return (
    <Container>
      <Select
        noOptionsMessage={() => 'Sem mais opções'}
        className="--select"
        {...props}
        styles={stylesProp || customStyles}
        placeholder={placeholder}
      />
    </Container>
  );
}

export function SelectComponent({ ...props }) {
  const { label, options, name } = props;

  return (
    <Container>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledField as="select" id={name} name={name} {...props}>
        {options?.map((option) => (
          <StyledOption key={option.label} value={option}>
            {option.label}
          </StyledOption>
        ))}
      </StyledField>
    </Container>
  );
}

export function NewSelect({ ...props }) {
  const { bgColor, defaultValue, errors, isMulti, label, name, touched } =
    props;

  const customStyle = {
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),
    control: () => ({
      alignItems: 'center',
      backgroundColor: bgColor || theme.newPrimaryAlt,
      border: `1px solid ${theme.ExtralightGray}`,
      borderRadius: '0.5rem',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    option: (provided) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      padding: 10,
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: theme.primary_blue,
      color: 'white',
      borderRadius: '0.5rem',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
    }),
  };

  const menuPortalTarget = document.getElementById('body');

  return (
    <ContainerSelect>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
      {isMulti ? (
        <Select
          id={name}
          bgColor={bgColor}
          maxMenuHeight={250}
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget}
          noOptionsMessage={() => 'Sem mais opções'}
          styles={customStyle}
          {...props}
        />
      ) : (
        <Select
          id={name}
          bgColor={bgColor}
          maxMenuHeight={250}
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget}
          styles={customStyle}
          value={props.options?.filter(
            (option) => option.value === defaultValue
          )}
          {...props}
        />
      )}
      {errors && touched && <TextError>{errors}</TextError>}
    </ContainerSelect>
  );
}

export function SelectSmall({ ...props }) {
  const { label, options, ...rest } = props;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        backgroundColor: theme.white,
        color: theme.black,
        fontFamily: 'Poppins',
        fontSize: '1rem',
      },
    },
  };

  return (
    <FormControl fullWidth size="small" variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <SelectMUI
        labelId="demo-simple-select-label"
        MenuProps={MenuProps}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </SelectMUI>
    </FormControl>
  );
}
