/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import { Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Camera, X } from 'react-feather';
import { toast } from 'react-toastify';
import PDF from '../../../../assets/contrato_clinicas_allminds_2024.pdf';
import AllPagesPDFViewer from '../../../../components/AllPagesPDF';
import { InputColorPicker } from '../../../../components/ColorPiker';
import FormikControl from '../../../../components/FormikControl';
import { BoxWrapper } from '../../../../components/FormikElements/styles';
import {
  ImageCropper,
  NewImageBanner,
} from '../../../../components/ImageInput';
import { NewCheckBoxInput } from '../../../../components/Input';
import { CustomPackagesItemList } from '../../../../components/ListItens';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { NewSelect } from '../../../../components/SelectList';
import useAuth from '../../../../hooks/auth';
import api, { brasilApi, viaCepApi } from '../../../../services/api';
import { PtBr } from '../../../../services/pt_br';
import { EmptyContainer, IconAreaModal } from '../../../../styles/globalStyles';
import theme from '../../../../theme';
import { getPreferencialPayments } from '../../../../utils/preferencialPayments';
import { EmptyText, EmptyTitle } from '../../../Client/EletronicDiary/styles';
import { addLeftZeros } from '../../../Professional/BankAccount';
import {
  ArtBannerWrapper,
  BannerWrapper,
  ButtonUploadBanner,
  ConfigDataForm,
  ConfigWrapper,
  ContainerBanner,
  ContainerImage,
  ContainerTitleBank,
  CustomPlansTH,
  RowLine,
  RowWrapper,
  TermDocument,
  TextError,
  TitleH1,
} from '../../../Professional/Config/styles';
import {
  CustonButton,
  EmptyImage,
  GridItem,
  MainCustom,
  RowItem,
  WrapperCustom,
} from '../styles';

const TITLE_MESSAGE_LINK_PROFILE =
  'Informe se deseja mostrar essa informação no link de compartilhamento personalizado';

const TITLE_MESSAGE_ART_BANNER =
  'Adicione uma arte personalizada para o sua clínica';

export function UpdateBasicData({
  form,
  storeAsBase64,
  user,
  validateInfos,
  handleHiddenFields,
}) {
  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [newArt, setNewArt] = useState(values?.secondaryBanner ? true : false);
  const [firstStep, setFirstStep] = useState(true);
  const [documentNumber, setDocumentNumber] = useState('');
  const [documentNumberMask, setDocumentNumberMask] =
    useState('99.999.999/9999-99');
  const [activeBanner, setActiveBanner] = useState(
    values?.banner ? true : false
  );

  const formatedDate = (date) => moment(date).format('DD/MM/YYYY');

  useEffect(() => {
    const validateInfo =
      user?.firstName === values?.firstName &&
      user?.lastName === values?.lastName &&
      formatedDate(user?.birthday) === values?.birthday &&
      user?.documentNumber === values?.documentNumber &&
      user?.phone === values?.phone &&
      values?.hiddenFields.every((field) => user?.hiddenFields.includes(field));
    const validateErrors =
      errors?.firstName ||
      errors?.lastName ||
      errors?.birthday ||
      errors?.phone ||
      errors?.documentNumber;
    setValidateForm(validateErrors || validateInfo);

    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.firstName,
      values?.lastName,
      values?.birthday,
      values?.phone,
    ]?.every((value) => value === '');
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Informações Básicas</TitleH1>
        <RowLine />
      </div>
      <ContainerBanner>
        <BannerWrapper>
          <FormControlLabel
            name="banner"
            control={
              <Checkbox checked={!values.hiddenFields.includes('banner')} />
            }
            label={
              <Tooltip title={TITLE_MESSAGE_LINK_PROFILE}>
                <Typography>
                  Mostrar banner da clínica no link de perfil{' '}
                  <span
                    style={{
                      color: theme.pink,
                      fontWeight: 'bold',
                      marginLeft: '0.5rem',
                    }}
                  >
                    ?
                  </span>
                </Typography>
              </Tooltip>
            }
            onChange={(event) => handleHiddenFields(event, 'banner')}
          />
          {!activeBanner && (
            <Fragment>
              <InputColorPicker
                label="Selecione a cor do banner"
                type="color"
                value={values?.bannerColor}
                onChange={({ target }) =>
                  setFieldValue('bannerColor', target.value)
                }
              />

              <Tooltip title="Adicionar Banner">
                <ButtonUploadBanner
                  type="button"
                  onClick={() => {
                    setActiveBanner(true);
                  }}
                >
                  <Camera />
                </ButtonUploadBanner>
              </Tooltip>
            </Fragment>
          )}
        </BannerWrapper>
        {activeBanner && (
          <Fragment>
            <span>Recomendado 1366px x 170px (Tamanho máximo: 2MB)</span>
            <NewImageBanner
              active={values?.banner}
              size="large"
              src={values?.banner}
              name="banner"
              onClick={() => {
                setFieldValue('banner', null);
                setFieldValue(
                  'bannerColor',
                  values?.bannerColor || user?.bannerColor || theme.primary
                );
                setActiveBanner(false);
              }}
              onChange={({ target }) => {
                const file = target.files[0];
                const maxSize = 2 * 1024 * 1024;
                if (file && file.size > maxSize) {
                  toast.error(
                    'Tamanho máximo permitido: 2MB! Verifique o arquivo selecionado para que seja possível realizar o upload.'
                  );
                  return;
                }
                storeAsBase64(file, 'banner');
                setFieldValue('bannerColor', null);
              }}
            />
          </Fragment>
        )}
        <TextError>{errors?.banner}</TextError>
      </ContainerBanner>

      <ArtBannerWrapper>
        <FormControlLabel
          name="secondaryBanner"
          control={<Checkbox checked={newArt} />}
          label={
            <Tooltip title={TITLE_MESSAGE_ART_BANNER}>
              <Typography>
                Adicionar ART personalizada{' '}
                <span
                  style={{
                    color: theme.pink,
                    fontWeight: 'bold',
                    marginLeft: '0.5rem',
                  }}
                >
                  ?
                </span>
              </Typography>
            </Tooltip>
          }
          onChange={({ target }) => setNewArt(target.checked)}
        />

        {newArt && (
          <Fragment>
            <span>Recomendado 1366px x 170px (Tamanho máximo: 2MB)</span>
            <NewImageBanner
              active={values?.secondaryBanner}
              size="large"
              src={values?.secondaryBanner}
              name="secondaryBanner"
              onClick={() => {
                setFieldValue('secondaryBanner', null);
              }}
              onChange={({ target }) => {
                const file = target.files[0];
                const maxSize = 2 * 1024 * 1024;
                if (file && file.size > maxSize) {
                  toast.error(
                    'Tamanho máximo permitido: 2MB! Verifique o arquivo selecionado para que seja possível realizar o upload.'
                  );
                  return;
                }
                storeAsBase64(file, 'secondaryBanner');
              }}
            />
          </Fragment>
        )}
      </ArtBannerWrapper>

      <GridItem>
        <RowItem>
          <ContainerImage>
            <ImageCropper
              src={values?.avatar}
              name="avatar"
              onCrop={(crop) => setFieldValue('avatar', crop)}
            />
            <TextError>{errors?.avatar}</TextError>
          </ContainerImage>
        </RowItem>

        <FormikControl
          control="textarea"
          errors={errors.description}
          label="Descrição da clínica"
          rows="5"
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.description}
          upLabel
          value={values.description}
        />
      </GridItem>

      <FormikControl
        control="input"
        errors={errors.name}
        label={PtBr.register_input_firstname}
        name="name"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.name}
        upLabel
        value={values.name}
      />

      <FormikControl
        control="input"
        errors={errors.email}
        label={PtBr.register_input_email}
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.email}
        upLabel
        value={values.email}
      />

      <FormikControl
        control="input"
        errors={errors.phone}
        label="Número de telefone"
        mask="(99) 99999-9999"
        name="phone"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.phone}
        upLabel
        value={values.phone}
      />

      <FormikControl
        control="input"
        errors={errors.documentNumber}
        label={PtBr.register_input_cpf_cnpj}
        mask={documentNumberMask}
        name="documentNumber"
        onChange={(e) => {
          handleChange(e);
          setDocumentNumber(e.target.value.replace(/\D/g, ''));
          if (e.target.value.replace(/\D/g, '') === '')
            setDocumentNumberMask('99.999.999/9999-99');
        }}
        onBlur={(e) => {
          handleBlur(e);
          if (e.target.value === '')
            setDocumentNumberMask('99.999.999/9999-99');
          if (documentNumber.length === 11) {
            setDocumentNumberMask('999.999.999-99');
          } else if (documentNumber.length === 14) {
            setDocumentNumberMask('99.999.999/9999-99');
          }
        }}
        touched={touched.documentNumber}
        upLabel
        value={values.documentNumber}
      />
    </ConfigWrapper>
  );
}

export function UpdateAddress({ form, user, validateInfos }) {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);
  const [cep, setCep] = useState('');
  const onlyNumbers = (str) => str.replace(/[_]/g, '');

  useEffect(() => {
    const controller = new AbortController();
    const handleCEP = async () => {
      if (onlyNumbers(cep)?.length === 9) {
        try {
          const { data } = await viaCepApi.get(`${values?.cep}/json/`, {
            signal: controller.signal,
          });
          if (data) {
            setFieldValue('cep', onlyNumbers(cep));
            setFieldValue('street', data.logradouro);
            setFieldValue('complement', 'N/A');
            setFieldValue('neighborhood', data.bairro);
            setFieldValue('city', data.localidade);
            setFieldValue('state', data.uf);
            setFieldValue('country', 'Brasil');
          }
        } catch (error) {
          if (!controller.signal.aborted) toast.error('CEP inválido');
        }
      }
    };
    handleCEP();

    return () => controller.abort();
  }, [cep]);

  const validateComplement = () => {
    if (values?.complement === '') {
      setFieldValue('complement', 'N/A');
    }
  };

  useEffect(() => {
    const validateInfo =
      user?.address.street === values?.street &&
      user?.address.street_number === values?.street_number &&
      user?.address.complement === values?.complement &&
      user?.address.neighborhood === values?.neighborhood &&
      user?.address.cep === values?.cep &&
      user?.address.country === values?.country &&
      user?.address.state === values?.state &&
      user?.address.city === values?.city;
    const validateErrors = [
      errors?.street,
      errors?.street_number,
      errors?.complement,
      errors?.neighborhood,
      errors?.cep,
      errors?.country,
      errors?.state,
      errors?.city,
    ]?.some((error) => error !== undefined);
    setValidateForm(validateErrors || validateInfo);
    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.street,
      values?.street_number,
      values?.complement,
      values?.neighborhood,
      values?.cep,
      values?.country,
      values?.state,
      values?.city,
    ]?.every((value) => value === '');
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>
          {user?.type === 'client' ? 'Endereço' : 'Endereço Comercial'}
        </TitleH1>
        <RowLine />
      </div>
      <FormikControl
        name="cep"
        label="CEP"
        upLabel
        type="text"
        control="input"
        mask="99999-999"
        value={values?.cep}
        onChange={handleChange}
        onInput={({ target }) => {
          setCep(target?.value);
        }}
        onBlur={handleBlur}
      />
      <TextError>{errors?.cep && touched?.cep && errors?.cep}</TextError>
      <FormikControl
        name="street"
        label="Endereço"
        upLabel
        type="text"
        control="input"
        value={values?.street}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextError>
        {errors?.street && touched?.street && errors?.street}
      </TextError>
      <FormikControl
        name="street_number"
        label="Número"
        upLabel
        type="text"
        control="input"
        value={values?.street_number}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextError>
        {errors?.street_number &&
          touched?.street_number &&
          errors?.street_number}
      </TextError>
      <FormikControl
        name="neighborhood"
        label="Bairro"
        upLabel
        type="text"
        control="input"
        value={values?.neighborhood}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextError>
        {errors?.neighborhood && touched?.neighborhood && errors?.neighborhood}
      </TextError>
      <FormikControl
        upLabel
        control="input"
        name="complement"
        value={values?.complement}
        label={PtBr.register_input_complement}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
          validateComplement();
        }}
      />
      <TextError>
        {errors?.complement && touched?.complement && errors?.complement}
      </TextError>
      <FormikControl
        name="city"
        label="Cidade"
        upLabel
        type="text"
        control="input"
        value={values?.city}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextError>{errors?.city && touched?.city && errors?.city}</TextError>
      <FormikControl
        name="state"
        label="Estado"
        upLabel
        type="text"
        control="input"
        value={values?.state}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextError>{errors?.state && touched?.state && errors?.state}</TextError>
      <FormikControl
        name="country"
        label="País"
        upLabel
        type="text"
        control="input"
        value={values?.country}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextError>
        {errors?.country && touched?.country && errors?.country}
      </TextError>
    </ConfigWrapper>
  );
}

export function UpdateResponsibleData({
  form,
  storeAsBase64,
  user,
  validateInfos,
  handleHiddenFields,
}) {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);

  useEffect(() => {
    const validateInfo =
      user?.responsibleAvatar === values?.responsibleAvatar &&
      user?.responsibleName === values?.responsibleName &&
      user?.responsibleDocumentNumber === values?.responsibleDocumentNumber &&
      user?.responsibleAbout === values?.responsibleAbout &&
      values?.hiddenFields.every((field) => user?.hiddenFields.includes(field));
    const validateErrors = [
      errors?.responsibleName,
      errors?.responsibleAbout,
    ]?.some((error) => error !== undefined);
    setValidateForm(validateErrors || validateInfo);
    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.responsibleName,
      values?.responsibleDocumentNumber,
      values?.responsibleAbout,
    ]?.every((value) => value === '');
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Dados Responsáveis Técnico</TitleH1>
        <RowLine />
      </div>
      <ContainerImage>
        <FormControlLabel
          name="responsibleAvatar"
          control={
            <Checkbox
              checked={!values.hiddenFields.includes('responsibleAvatar')}
            />
          }
          label={
            <Tooltip title={TITLE_MESSAGE_LINK_PROFILE}>
              <Typography>
                Mostrar foto do responsável no link de perfil{' '}
                <span
                  style={{
                    color: theme.pink,
                    fontWeight: 'bold',
                    marginLeft: '0.5rem',
                  }}
                >
                  ?
                </span>
              </Typography>
            </Tooltip>
          }
          onChange={(event) => handleHiddenFields(event, 'responsibleAvatar')}
        />
        <ImageCropper
          acceptType=".jpeg, .jpg"
          name="responsibleAvatar"
          onCrop={(crop) => setFieldValue('responsibleAvatar', crop)}
          src={values?.responsibleAvatar}
        />
        <TextError>{errors.responsibleAvatar}</TextError>
      </ContainerImage>

      <div>
        <FormControlLabel
          name="responsibleName"
          control={
            <Checkbox
              checked={!values.hiddenFields.includes('responsibleName')}
            />
          }
          label={
            <Tooltip title={TITLE_MESSAGE_LINK_PROFILE}>
              <Typography>
                Mostrar nome do responsável no link de perfil{' '}
                <span
                  style={{
                    color: theme.pink,
                    fontWeight: 'bold',
                    marginLeft: '0.5rem',
                  }}
                >
                  ?
                </span>
              </Typography>
            </Tooltip>
          }
          onChange={(event) => handleHiddenFields(event, 'responsibleName')}
        />
        <FormikControl
          bgColor="#fff"
          control="input"
          errors={errors.responsibleName}
          label="Nome do responsável"
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          name="responsibleName"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.responsibleName}
          upLabel
          value={values.responsibleName}
        />
      </div>

      <div>
        <FormControlLabel
          name="responsibleDocumentNumber"
          control={
            <Checkbox
              checked={
                !values.hiddenFields.includes('responsibleDocumentNumber')
              }
            />
          }
          label={
            <Tooltip title={TITLE_MESSAGE_LINK_PROFILE}>
              <Typography>
                Mostrar CRP do responsável no link de perfil{' '}
                <span
                  style={{
                    color: theme.pink,
                    fontWeight: 'bold',
                    marginLeft: '0.5rem',
                  }}
                >
                  ?
                </span>
              </Typography>
            </Tooltip>
          }
          onChange={(event) =>
            handleHiddenFields(event, 'responsibleDocumentNumber')
          }
        />
        <FormikControl
          bgColor="#fff"
          control="input"
          errors={errors.responsibleDocumentNumber}
          label="CRP do responsável"
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          name="responsibleDocumentNumber"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.responsibleDocumentNumber}
          upLabel
          value={values.responsibleDocumentNumber}
        />
      </div>

      <div>
        <FormControlLabel
          name="responsibleAbout"
          control={
            <Checkbox
              checked={!values.hiddenFields.includes('responsibleAbout')}
            />
          }
          label={
            <Tooltip title={TITLE_MESSAGE_LINK_PROFILE}>
              <Typography>
                Mostrar descrição do responsável no link de perfil{' '}
                <span
                  style={{
                    color: theme.pink,
                    fontWeight: 'bold',
                    marginLeft: '0.5rem',
                  }}
                >
                  ?
                </span>
              </Typography>
            </Tooltip>
          }
          onChange={(event) => handleHiddenFields(event, 'responsibleAbout')}
        />
        <FormikControl
          bgColor="#fff"
          control="textarea"
          errors={errors.responsibleAbout}
          label="Descrição do responsável"
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          name="responsibleAbout"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.responsibleAbout}
          upLabel
          value={values.responsibleAbout}
        />
      </div>
    </ConfigWrapper>
  );
}

export function UpdateBankAccount({
  form,
  setUpdateBank,
  updateBank,
  user,
  validateInfos,
}) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    form;
  const [loading, setLoading] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [bank, setBank] = useState(null);
  const [documentNumberType, setDocumentNumberType] = useState(
    user?.paymentAccount?.documentNumber.length === 18 ? 'CNPJ' : 'CPF'
  );

  useEffect(() => {
    const fetchBankCodes = async () => {
      try {
        await brasilApi
          .get()
          .then(({ data, errors }) => {
            if (errors) {
              throw new Error('Erro ao carregar bancos');
            }
            setBankList(
              data?.map((bank) => ({
                label: bank.fullName,
                value: { code: bank.code, fullName: bank.fullName },
              }))
            );

            const bankFullName = data?.filter(
              (bank) => addLeftZeros(bank.code, 3) === values?.bankCode
            )[0]?.fullName;
            setBank({
              label: bankFullName,
              value: bankFullName,
            });
          })
          .catch((error) => toast.error(error));
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBankCodes();
  }, []);

  useEffect(() => {
    const validateInfo =
      user?.paymentAccount?.agenciaDv === values?.agenciaDv &&
      user?.paymentAccount?.bankAg === values?.bankAg &&
      user?.paymentAccount?.bankCc === values?.bankCc &&
      user?.paymentAccount?.bankCode === values?.bankCode &&
      user?.paymentAccount?.contaDv === values?.contaDv &&
      user?.acceptCredit === values?.acceptCredit &&
      user?.acceptPix === values?.acceptPix &&
      user?.paymentAccount?.documentNumber === values?.paymentDocumentNumber;
    validateInfos(validateInfo);
  }, [values]);

  useEffect(() => {
    setFieldValue('agenciaDv', user?.paymentAccount?.agenciaDv);
    setFieldValue('bankAg', user?.paymentAccount?.bankAg);
    setFieldValue('bankCc', user?.paymentAccount?.bankCc);
    setFieldValue('bankCode', user?.paymentAccount?.bankCode);
    setFieldValue('contaDv', user?.paymentAccount?.contaDv);
  }, [user]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Dados Bancários</TitleH1>
        <RowLine />
      </div>

      <ContainerTitleBank>
        <NewCheckBoxInput
          label="Atualizar dados bancários"
          checked={updateBank}
          onChange={() => setUpdateBank(!updateBank)}
        />
      </ContainerTitleBank>

      {loading ? (
        <p>Carregando dados bancários...</p>
      ) : (
        <Fragment>
          <NewSelect
            defaultValue={bank?.value}
            errors={errors.bankCode}
            isDisabled={!updateBank}
            label="Banco"
            name="bankName"
            placeholder="Selecione o banco"
            touched={touched.bankCode}
            onChange={(e) => {
              const { value } = e;
              const code = bankList?.filter(
                (bank) => bank.value.fullName === value
              )[0]?.value?.code;
              setFieldValue('bankCode', addLeftZeros(code, 3));
              setBank(e);
            }}
            options={bankList?.map((bank) => {
              return { label: bank.label, value: bank.value.fullName };
            })}
          />

          <RowWrapper>
            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors.bankAg}
              label="Agência"
              name="bankAg"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.bankAg}
              type="text"
              upLabel
              value={values?.bankAg}
            />

            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors.agenciaDv}
              label="Dígito"
              name="agenciaDv"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.agenciaDv}
              type="text"
              upLabel
              value={values?.agenciaDv}
            />
          </RowWrapper>

          <RowWrapper>
            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors.bankCc}
              label="Conta"
              name="bankCc"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.bankCc}
              type="text"
              upLabel
              value={values?.bankCc}
            />

            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors.contaDv}
              label="Dígito"
              name="contaDv"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.contaDv}
              type="text"
              upLabel
              value={values?.contaDv}
            />
          </RowWrapper>

          <NewSelect
            defaultValue={values?.accountType}
            errors={errors.accountType}
            isDisabled={!updateBank}
            label="Tipo de conta"
            name="accountType"
            placeholder="Selecione o tipo de conta"
            touched={touched.accountType}
            onChange={(e) => {
              setFieldValue('accountType', e.value);
            }}
            options={[
              {
                label: 'Conta Corrente',
                value: 'conta_corrente',
              },
              {
                label: 'Conta Poupança',
                value: 'conta_poupança',
              },
            ]}
          />

          <FormikControl
            control="input"
            disabled={!updateBank}
            errors={errors.paymentName}
            label="Nome completo do titular da conta"
            name="paymentName"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.paymentName}
            type="text"
            upLabel
            value={values?.paymentName}
          />

          <div
            style={{
              margin: '1rem 0',
              width: '100%',
            }}
          >
            <FormikControl
              bgColor="#fff"
              control="select"
              label="Tipo de documento"
              labelFamily="Poppins"
              labelSize="0.9rem"
              labelWeight="600"
              name="documentNumberType"
              options={['CNPJ', 'CPF']}
              onChange={({ target }) => {
                setDocumentNumberType(target.value);
              }}
              upLabel
              value={values?.documentNumberType}
              defaultValue="CNPJ"
            />
          </div>

          <FormikControl
            bgColor="#fff"
            control="input"
            errors={errors.paymentDocumentNumber}
            label={documentNumberType === 'CNPJ' ? 'CNPJ' : 'CPF'}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            mask={
              documentNumberType === 'CNPJ'
                ? '99.999.999/9999-99'
                : '999.999.999-99'
            }
            name="paymentDocumentNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.paymentDocumentNumber}
            upLabel
            value={values?.paymentDocumentNumber}
          />
        </Fragment>
      )}
    </ConfigWrapper>
  );
}

export function UpdateTypesReceipts({ form, user, validateInfos }) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    form;
  const preferentialPaymentTypes = ['pix', 'credit_card', 'all_payment'];

  const getPymentMetrod = (type) => {
    switch (type) {
      case 'pix': {
        setFieldValue('acceptPix', true);
        setFieldValue('acceptCredit', false);
        return;
      }
      case 'credit_card': {
        setFieldValue('acceptCredit', true);
        setFieldValue('acceptPix', false);
        return;
      }
      case 'all_payment': {
        setFieldValue('acceptPix', true);
        setFieldValue('acceptCredit', true);
        return;
      }
      default: {
        setFieldValue('acceptPix', false);
        setFieldValue('acceptCredit', false);
      }
    }
  };

  useEffect(() => {
    const validateInfo =
      user?.acceptCredit === values?.acceptCredit &&
      user?.acceptPix === values?.acceptPix &&
      user?.prePaid === values?.prePaid &&
      user?.postPaid === values?.postPaid &&
      user?.externalPayment === values?.externalPayment &&
      user?.postPaidDay === values?.postPaidDay;
    validateInfos(validateInfo);
    getPymentMetrod(values?.preferentialPayment);
  }, [values]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <TitleH1>Formas de recebimento</TitleH1>
        <RowLine />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '0 10px 20px 0',
        }}
      >
        <FormControlLabel
          name="prePaid"
          control={<Checkbox checked={values.prePaid} />}
          label={
            <Typography style={{ fontWeight: 'bold' }}>
              Aceitar pagamento PRÉ-PAGO (Padrão)
            </Typography>
          }
          onChange={handleChange}
        />
        <FormControlLabel
          name="postPaid"
          control={<Checkbox checked={values.postPaid} />}
          label={
            <Typography style={{ fontWeight: 'bold' }}>
              Aceitar pagamento PÓS-PAGO
            </Typography>
          }
          onChange={handleChange}
        />
        <FormControlLabel
          name="externalPayment"
          control={<Checkbox checked={values.externalPayment} />}
          label={
            <Typography style={{ fontWeight: 'bold' }}>
              Aceitar pagamento EXTERNO
            </Typography>
          }
          onChange={handleChange}
        />
      </div>

      {values.postPaid && (
        <FormikControl
          control="input"
          errors={errors.postPaidDay}
          label="Dia de cobrança para agendamentos pós-pagos"
          name="postPaidDay"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.postPaidDay}
          type="number"
          upLabel
          value={values?.postPaidDay}
        />
      )}

      <div
        style={{
          marginTop: '2rem',
        }}
      >
        <NewSelect
          bgColor={theme.white}
          defaultValue={values.preferentialPayment}
          errors={errors.preferentialPayment}
          label="Formas de recebimentos aceitas pela clínica"
          name="preferentialPayment"
          onChange={({ value }) => {
            setFieldValue('preferentialPayment', value);
          }}
          options={preferentialPaymentTypes?.map((type) => {
            return { label: getPreferencialPayments(type), value: type };
          })}
          placeholder="Selecione a forma de recebimento"
          touched={touched.preferentialPayment}
        />
      </div>
    </ConfigWrapper>
  );
}

export function UpdatePassword({ form, validateInfos }) {
  const { errors, handleBlur, handleChange, touched, values } = form;

  const handleErrors = (errors, values) => {
    let pageHasErrors = false;
    if (values?.length > 0) {
      values.forEach((value) => {
        if (errors[`${value}`]) {
          pageHasErrors = true;
        }
      });
    } else {
      Object.keys(errors).forEach(() => {
        pageHasErrors = true;
      });
    }
    return pageHasErrors;
  };

  const validatePassword = [
    values?.password,
    values?.newPassword,
    values?.confirmPassword,
  ].includes('');

  useEffect(() => {
    validateInfos(validatePassword || handleErrors(errors, values));
  }, [errors, values]);

  return (
    <ConfigWrapper>
      <ConfigDataForm>
        <div style={{ padding: '0 10px 20px 0' }}>
          <TitleH1>Alterar minha senha</TitleH1>
          <RowLine />
        </div>
        <FormikControl
          control="input"
          label="Senha atual"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel
          value={values?.password}
        />
        <TextError>
          {errors?.password && touched?.password && errors?.password}
        </TextError>
        <RowLine />
        <FormikControl
          control="input"
          label="Nova senha"
          name="newPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel
          value={values?.newPassword}
        />
        <TextError>
          {errors?.newPassword && touched?.newPassword && errors?.newPassword}
        </TextError>
        <FormikControl
          control="input"
          label="Repetir nova senha"
          name="confirmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel
          value={values?.confirmPassword}
        />
        <TextError>
          {errors?.confirmPassword &&
            touched?.confirmPassword &&
            errors?.confirmPassword}
        </TextError>
      </ConfigDataForm>
    </ConfigWrapper>
  );
}

export function CustomPackages({ form, isRender }) {
  const { user } = useAuth();
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    values,
  } = form;
  const [loading, setLoading] = useState(true);
  const [customPackages, setCustomPackages] = useState([]);
  const [editCustomPackage, setEditCustomPackage] = useState(false);

  useEffect(() => {
    const fetchCustomPackages = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/${user?.type}s/appointment_packages`);
        const customPackages = data.data.map((customPackage) => ({
          id: customPackage.id,
          ...customPackage.attributes,
        }));
        setCustomPackages(customPackages);
      } catch (error) {
        toast.error('Erro ao buscar Planos personalizados');
      } finally {
        setLoading(false);
      }
    };
    fetchCustomPackages();
  }, [isRender]);

  const handleOpenEditCustomPackage = (customPackage) => {
    setEditCustomPackage(true);
    setFieldValue('packageId', customPackage.id);
    setFieldValue('editNamePackage', customPackage.name);
    setFieldValue(
      'editNumberOfAppointments',
      customPackage.numberOfAppointments
    );
    setFieldValue('editPricePackage', customPackage.price);
  };

  return (
    <MainCustom>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <WrapperCustom>
          <TitleH1>Pacotes personalizados</TitleH1>
          <RowLine />
          <table
            style={{
              borderSpacing: '0 0.7rem',
              borderCollapse: 'separate',
              width: '100%',
            }}
          >
            <tr style={{ width: '100%' }}>
              <CustomPlansTH>Nome</CustomPlansTH>
              <CustomPlansTH style={{ width: '30%' }}>
                Número de consultas
              </CustomPlansTH>
              <CustomPlansTH>Preço</CustomPlansTH>
              <CustomPlansTH>Ações</CustomPlansTH>
            </tr>

            {customPackages.length > 0 &&
              customPackages.map((customPackage) => (
                <CustomPackagesItemList
                  key={customPackage.id}
                  data={customPackage}
                  handleEdit={() => handleOpenEditCustomPackage(customPackage)}
                  handleDelete={() => {
                    setFieldValue('isDeletePackage', true);
                    setFieldValue('packageId', customPackage.id);
                  }}
                />
              ))}
          </table>
          {customPackages.length <= 0 && (
            <EmptyContainer>
              <EmptyImage />
              <EmptyTitle>
                Você verá seus Pacotes personalizados aqui
              </EmptyTitle>
              <EmptyText>
                Crie Pacotes personalizados para oferecer aos seus pacientes
              </EmptyText>
            </EmptyContainer>
          )}
        </WrapperCustom>
      )}
      <WrapperCustom>
        <div style={{ padding: '0 10px 20px 0' }}>
          <TitleH1>Criar Pacotes personalizados</TitleH1>
          <RowLine />
        </div>

        <FormikControl
          control="input"
          errors={errors.namePackage}
          label="Nome do pacote"
          name="namePackage"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.namePackage}
          upLabel
          value={values.namePackage}
        />
        <FormikControl
          control="input"
          errors={errors.numberOfAppointments}
          label="Número de sessões"
          name="numberOfAppointments"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.numberOfAppointments}
          upLabel
          value={values.numberOfAppointments}
        />
        <FormikControl
          control="input-currency"
          errors={errors.pricePackage}
          label="Preço do pacote"
          name="pricePackage"
          onChange={(_e, value) => setFieldValue('pricePackage', value)}
          touched={touched.pricePackage}
          upLabel
          value={Number(values?.pricePackage)}
        />
        <CustonButton type="submit">Criar Pacote</CustonButton>
      </WrapperCustom>

      {editCustomPackage && (
        <Modal open={editCustomPackage}>
          <BoxWrapper>
            <IconAreaModal style={{ cursor: 'default' }}>
              <X
                style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                onClick={() => setEditCustomPackage(false)}
              />
            </IconAreaModal>
            <WrapperCustom>
              <div style={{ padding: '0 10px 20px 0' }}>
                <TitleH1>Editar Pacote Personalizado</TitleH1>
                <RowLine />
              </div>
              <FormikControl
                control="input"
                errors={errors.editNamePackage}
                label="Nome do pacote"
                name="editNamePackage"
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touched.editNamePackage}
                upLabel
                value={values.editNamePackage}
              />
              <FormikControl
                control="input"
                errors={errors.editNumberOfAppointments}
                label="Número de sessões"
                name="editNumberOfAppointments"
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touched.editNumberOfAppointments}
                upLabel
                value={values.editNumberOfAppointments}
              />
              <FormikControl
                control="input-currency"
                errors={errors.editPricePackage}
                label="Preço do pacote"
                name="editPricePackage"
                onChange={(_e, value) =>
                  setFieldValue('editPricePackage', value)
                }
                touched={touched.editPricePackage}
                upLabel
                value={Number(values?.editPricePackage)}
              />
              <CustonButton
                type="submit"
                onClick={() => {
                  handleSubmit();
                  setEditCustomPackage(false);
                }}
              >
                Editar Pacote
              </CustonButton>
            </WrapperCustom>
          </BoxWrapper>
        </Modal>
      )}

      {values?.isDeletePackage && (
        <Modal open={values?.isDeletePackage}>
          <BoxWrapper>
            <IconAreaModal style={{ cursor: 'default' }}>
              <X
                style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                onClick={() => setFieldValue('isDeletePackage', false)}
              />
            </IconAreaModal>
            <WrapperCustom style={{ alignItems: 'center' }}>
              <div
                style={{
                  padding: '0 10px 20px 0',
                }}
              >
                <TitleH1>Deletar Pacote Personalizado</TitleH1>
                <RowLine />
              </div>
              <p style={{ padding: '0 2rem' }}>
                Tem certeza que deseja deletar o pacote personalizado? Essa ação
                não poderá ser desfeita.
              </p>
              <CustonButton
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                style={{ backgroundColor: theme.pink }}
              >
                Deletar Pacote
              </CustonButton>
            </WrapperCustom>
          </BoxWrapper>
        </Modal>
      )}
    </MainCustom>
  );
}

export function CustomPlans({ form, isRender }) {
  const { user } = useAuth();
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    values,
  } = form;
  const [loading, setLoading] = useState(true);
  const [customPlans, setCustomPlans] = useState([]);
  const [editCustomPlans, setEditCustomPlans] = useState(false);

  const optionsFrequency = [
    {
      label: 'Consultas semanais',
      value: 'weekly',
    },
    { label: 'Consultas quinzenais', value: 'biweekly' },
    { label: 'Consultas a cada 3 semanas', value: 'every_three_weeks' },
    { label: 'Consultas mensais', value: 'monthly' },
  ];

  useEffect(() => {
    const fetchCustomPlans = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/${user?.type}s/appointment_plans`);

        const customPlans = data.data.map((customPlan) => ({
          id: customPlan.id,
          ...customPlan.attributes,
        }));
        setCustomPlans(customPlans);
      } catch (error) {
        toast.error('Erro ao buscar Planos personalizados');
      } finally {
        setLoading(false);
      }
    };
    fetchCustomPlans();
  }, [isRender]);

  useEffect(() => {
    setFieldValue('userId', user?.id);
    setFieldValue('userType', user?.type);
  }, []);

  const handleOpenEditCustomPlan = (customPlan) => {
    setEditCustomPlans(true);
    setFieldValue('planId', customPlan.id);
    setFieldValue('editNamePlan', customPlan.name);
    setFieldValue('editAppointmentsPerWeek', customPlan.appointmentsPerWeek);
    setFieldValue('editFrequency', customPlan.frequency);
    setFieldValue('editPricePlan', customPlan.price);
    setFieldValue('userType', customPlan.userType);
    setFieldValue('userId', customPlan.userId);
  };

  return (
    <MainCustom>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <WrapperCustom>
          <TitleH1>Planos personalizados</TitleH1>
          <RowLine />
          <table
            style={{
              borderSpacing: '0 0.7rem',
              borderCollapse: 'separate',
              width: '100%',
            }}
          >
            <tr style={{ width: '100%' }}>
              <CustomPlansTH>Nome</CustomPlansTH>
              <CustomPlansTH style={{ width: '30%' }}>
                Frequência das consultas
              </CustomPlansTH>
              <CustomPlansTH>Preço</CustomPlansTH>
              <CustomPlansTH>Ações</CustomPlansTH>
            </tr>
            {customPlans.length > 0 &&
              customPlans.map((customPlan) => (
                <CustomPackagesItemList
                  key={customPlan.id}
                  data={customPlan}
                  handleEdit={() => handleOpenEditCustomPlan(customPlan)}
                  handleDelete={() => {
                    setFieldValue('isDeletePlan', true);
                    setFieldValue('planId', customPlan.id);
                  }}
                />
              ))}
          </table>
          {customPlans.length <= 0 && (
            <EmptyContainer>
              <EmptyImage />
              <EmptyTitle>Você verá seus Planos personalizados aqui</EmptyTitle>
              <EmptyText>
                Crie Planos personalizados para oferecer aos seus pacientes
              </EmptyText>
            </EmptyContainer>
          )}
        </WrapperCustom>
      )}
      <WrapperCustom style={{ gap: '1rem' }}>
        <div>
          <TitleH1>Criar Planos personalizados</TitleH1>
          <RowLine />
        </div>

        <FormikControl
          control="input"
          errors={errors.namePlan}
          label="Nome do plano"
          name="namePlan"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.namePlan}
          upLabel
          value={values.namePlan}
        />

        <NewSelect
          placeholder="Selecione a frequencia das consultas"
          label="Frequencia"
          name="frequency"
          onChange={(e) => setFieldValue('frequency', e.value)}
          defaultValue={values.frequency}
          options={optionsFrequency}
        />

        {/* <FormikControl
          control="input"
          errors={errors.appointmentsPerWeek}
          label="Número de sessões por semana"
          name="appointmentsPerWeek"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.appointmentsPerWeek}
          upLabel
          value={values.appointmentsPerWeek}
        /> */}

        <FormikControl
          control="input-currency"
          errors={errors.pricePlan}
          label="Preço do plano"
          name="pricePlan"
          onChange={(_e, value) => setFieldValue('pricePlan', value)}
          touched={touched.pricePlan}
          upLabel
          value={Number(values?.pricePlan)}
        />
        <CustonButton type="submit">Criar Plano</CustonButton>
      </WrapperCustom>

      {editCustomPlans && (
        <Modal open={editCustomPlans}>
          <BoxWrapper>
            <IconAreaModal style={{ cursor: 'default' }}>
              <X
                style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                onClick={() => setEditCustomPlans(false)}
              />
            </IconAreaModal>
            <WrapperCustom style={{ gap: '1rem' }}>
              <div>
                <TitleH1>Editar Plano Personalizado</TitleH1>
                <RowLine />
              </div>
              <FormikControl
                control="input"
                errors={errors.editNamePlan}
                label="Nome do plano"
                name="editNamePlan"
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touched.editNamePlan}
                upLabel
                value={values.editNamePlan}
              />

              <NewSelect
                placeholder="Selecione a frequencia das consultas"
                label="Frequencia"
                name="editFrequency"
                onChange={(e) => setFieldValue('editFrequency', e.value)}
                defaultValue={values.editFrequency}
                options={optionsFrequency}
              />
              {/* <FormikControl
                control="input"
                errors={errors.editAppointmentsPerWeek}
                label="Número de sessões"
                name="editAppointmentsPerWeek"
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touched.editAppointmentsPerWeek}
                upLabel
                value={values.editAppointmentsPerWeek}
              /> */}
              <FormikControl
                control="input-currency"
                errors={errors.editPricePlan}
                label="Preço do plano"
                name="editPricePlan"
                onChange={(_e, value) => setFieldValue('editPricePlan', value)}
                touched={touched.editPricePlan}
                upLabel
                value={Number(values?.editPricePlan)}
              />
              <CustonButton
                type="submit"
                onClick={() => {
                  handleSubmit();
                  setEditCustomPlans(false);
                }}
              >
                Editar Plano
              </CustonButton>
            </WrapperCustom>
          </BoxWrapper>
        </Modal>
      )}

      {values?.isDeletePlan && (
        <Modal open={values?.isDeletePlan}>
          <BoxWrapper>
            <IconAreaModal style={{ cursor: 'default' }}>
              <X
                style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                onClick={() => setFieldValue('isDeletePlan', false)}
              />
            </IconAreaModal>
            <WrapperCustom style={{ alignItems: 'center' }}>
              <div
                style={{
                  padding: '0 10px 20px 0',
                }}
              >
                <TitleH1>Deletar Plano Personalizado</TitleH1>
                <RowLine />
              </div>
              <p style={{ padding: '0 2rem' }}>
                Tem certeza que deseja deletar o plano personalizado? Essa ação
                não poderá ser desfeita.
              </p>
              <CustonButton
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                style={{ backgroundColor: theme.pink }}
              >
                Deletar Plano
              </CustonButton>
            </WrapperCustom>
          </BoxWrapper>
        </Modal>
      )}
    </MainCustom>
  );
}

export function Terms() {
  return (
    <TermDocument>
      <AllPagesPDFViewer pdf={PDF} />
    </TermDocument>
  );
}

export function DeleteAccount({ form }) {
  const { values, handleBlur, handleChange } = form;

  return (
    <ConfigWrapper>
      <ConfigDataForm>
        <div style={{ padding: '0 10px 20px 0' }}>
          <TitleH1>Excluir minha conta</TitleH1>
          <RowLine />
        </div>
        <FormikControl
          control="textarea"
          label="Motivo da exclusão"
          name="reason"
          onBlur={handleBlur}
          onChange={handleChange}
          rows="5"
          upLabel
          value={values.reason}
        />
      </ConfigDataForm>
    </ConfigWrapper>
  );
}
