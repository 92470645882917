/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Modal, Typography } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FooterButtons } from '../../../components/FooterButtons';
import FormikControl from '../../../components/FormikControl';
import { AddressFormik } from '../../../components/FormikElements/AddressForm';
import PaymentForm from '../../../components/FormikElements/PaymentForm';
import { InputWrapper } from '../../../components/FormikElements/styles';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import { TextError } from '../../../styles/globalStyles';
import theme from '../../../theme';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import handleErrors from '../../../utils/getValuesErrors';
import {
  LoginLink,
  LoginParagraph,
  LoginTitle,
} from '../../Public/Login/styles';
import {
  AddressWrapper,
  BFontBold,
  BoxWrapper,
  ButonCancel,
  Button,
  ButtonActiveDiv,
  ColumnItem,
  ContainerPlans,
  FontPlanPrice,
  GridCard,
  Hr,
  SFontDark,
} from './styles';

const BasicRegistrationValues = [
  'firstName',
  'lastName',
  'email',
  'birthday',
  'phone',
  'password',
  'confirm_password',
];

const CardRegistrationValues = [
  'planName',
  'cardNumber',
  'cardHolderName',
  'cardCvv',
  'cardExpiration_date',
  'documentNumber',
];

const AddressRegistrationValues = [
  'cep',
  'street',
  'street_number',
  'complement',
  'neighborhood',
  'city',
  'state',
  'country',
];

const dataCardsPlans = [
  {
    tPlanName: 'Trial',
    planName: 'trial',
    planPrice: 0,
    message: 'Teste a plataforma gratuitamente por 15 dias',
  },
  {
    tPlanName: 'Prata',
    planName: 'silver',
    planPrice: 59.9,
    message: 'Para psicólogos que almejam alcançar um novo patamar',
  },
  {
    tPlanName: 'Ouro',
    planName: 'gold',
    planPrice: 89.9,
    message: 'Ideal para otimizar a rotina de todo psicólogo',
  },
];

function ModalSubscription({ form, loading }) {
  const [step, setStep] = useState(0);
  const handleContinue = () => {
    if (!handleErrors(form.errors, AddressRegistrationValues) && step === 0) {
      setStep(step + 1);
    } else if (
      !handleErrors(form.errors, CardRegistrationValues) &&
      step === 1
    ) {
      form.setFieldValue('open', false);
      setStep(0);
    }
  };

  return (
    <Modal open>
      <BoxWrapper>
        {step === 1 && (
          <Fragment>
            <Typography
              variant="p"
              sx={{
                color: theme.secondary,
                fontWeight: 700,
                fontSize: '1.1rem',
              }}
            >
              Para seguir com a assinatura do plano, adicione um cartão para
              pagamento
            </Typography>
            <Hr />
            <PaymentForm form={form} loading={loading} subscription />
          </Fragment>
        )}
        {step === 0 && (
          <AddressWrapper>
            <Typography
              variant="p"
              sx={{
                color: theme.secondary,
                fontWeight: 700,
                fontSize: '1.1rem',
              }}
            >
              Para seguir com a assinatura do plano, informe os dados de
              endereço
            </Typography>
            <Hr />
            <LoginTitle>Endereço Comercial</LoginTitle>
            <AddressFormik form={form} />
          </AddressWrapper>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <ButonCancel
            type="button"
            onClick={() => {
              if (step === 1) {
                setStep(0);
              } else {
                form.setFieldValue('planName', 'trial');
                form.setFieldValue('open', false);
                setStep(0);
              }
            }}
            style={{
              width: '8rem',
            }}
          >
            Voltar
          </ButonCancel>
          <Button
            onClick={handleContinue}
            type="button"
            style={{
              width: '8rem',
            }}
          >
            Continuar
          </Button>
        </Box>
      </BoxWrapper>
    </Modal>
  );
}

function CardsPlans({ ...props }) {
  const { values, message, planName, planPrice, setFieldValue, tPlanName } =
    props;

  const active = useMemo(() => {
    return values.planName === planName;
  }, [values.planName]);

  return (
    <ContainerPlans active={active}>
      <ColumnItem>
        <BFontBold active={active}>{tPlanName}</BFontBold>
        <SFontDark active={active}>{message}</SFontDark>

        <FontPlanPrice active={active}>
          {convertCurrencyPtBr(planPrice)}
        </FontPlanPrice>

        {!active ? (
          <Button
            active={active}
            name={tPlanName}
            value={planName}
            onClick={({ target }) => {
              if (target.value !== 'trial') {
                setFieldValue('open', true);
              }
              setFieldValue('planName', target.value);
            }}
            type="button"
          >
            Selecionar
          </Button>
        ) : (
          <ButtonActiveDiv active={active}>Ativo</ButtonActiveDiv>
        )}
      </ColumnItem>
    </ContainerPlans>
  );
}

function PlansSubscription({ form }) {
  const { setFieldValue, values } = form;

  return (
    <GridCard>
      {dataCardsPlans.map((plan) => (
        <CardsPlans
          key={plan.planName}
          values={values}
          setFieldValue={setFieldValue}
          {...plan}
        />
      ))}
    </GridCard>
  );
}

export default function BasicRegistrationProfessionalScreen({
  clinic,
  form,
  next,
}) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    validateForm,
  } = form;
  const history = useHistory();

  useEffect(() => {
    validateForm();
  }, []);

  const checkEmail = async (email) => {
    await api.post('/professionals/check_email', {
      email: email,
    });
  };

  const handleContinue = async () => {
    if (!handleErrors(errors, BasicRegistrationValues)) {
      try {
        await checkEmail(values.email);
        setFieldValue('birthday', values.birthday);
        next();
      } catch (error) {
        if (error.response) {
          if (error.response.data.available === false) {
            toast.error('Email já está em uso!');
          }
        } else {
          toast.error(
            'Ocorreu um erro no formulário, verifique os dados inseridos!'
          );
        }
      }
    }
  };

  return (
    <InputWrapper>
      <LoginTitle>Dados Básicos</LoginTitle>
      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_firstname}
      />
      <TextError>
        {errors.firstName && touched.firstName && errors.firstName}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_lastname}
      />
      <TextError>
        {errors.lastName && touched.lastName && errors.lastName}
      </TextError>

      {clinic && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
            width: '100%',
          }}
        >
          <FormikControl
            bgColor="#fff"
            upLabel
            labelWeight="600"
            labelSize="0.9rem"
            labelFamily="Poppins"
            control="input"
            name="member"
            value={values?.clinicName}
            label={PtBr.register_input_clinic}
            disabled
          />
        </div>
      )}

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_email}
      />
      <TextError>{errors.email && touched.email && errors.email}</TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        control="input"
        name="phone"
        mask="(99)99999-9999"
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Número de telefone"
      />
      <TextError>{errors.phone && touched.phone && errors.phone}</TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        type="password"
        control="input"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_password}
      />
      <TextError>
        {errors.password && touched.password && errors.password}
      </TextError>

      <FormikControl
        bgColor="#fff"
        upLabel
        labelWeight="600"
        labelSize="0.9rem"
        labelFamily="Poppins"
        type="password"
        control="input"
        name="confirm_password"
        value={values.confirm_password}
        onChange={handleChange}
        onBlur={handleBlur}
        label={PtBr.register_input_confirm_password}
      />
      <TextError>
        {errors.confirm_password &&
          touched.confirm_password &&
          errors.confirm_password}
      </TextError>

      <PlansSubscription form={form} />

      <FooterButtons disableGoBack={true} next={handleContinue} />

      <LoginParagraph style={{ marginTop: '2rem' }}>
        Já possui uma conta?{' '}
        <LoginLink
          type="button"
          onClick={() => history.push('/profissional/conectar-se')}
        >
          Entrar
        </LoginLink>
      </LoginParagraph>

      {values.open && <ModalSubscription form={form} />}
    </InputWrapper>
  );
}
