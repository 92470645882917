/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { StaticButton } from '../../../components/Button';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { ModalAlert } from '../../../components/Modals/ModalAlerts';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api, { apiPublic } from '../../../services/api';
import theme from '../../../theme';
import {
  PLAN_LEVEL_CANCEL_ACCESS,
  PLAN_LEVEL_FREE_ACCESS,
  PLAN_LEVEL_LOW_ACCESS,
} from '../../../utils/constantsLevelAccess';
import getProfessionName from '../../../utils/getProfessionName';
import { getProfessions } from '../../../utils/getProfessionsFilter';
import {
  DeleteAccount,
  UpdateAddress,
  UpdateBankAccount,
  UpdateBasicData,
  UpdatePassword,
  UpdateProfessionalInfos,
} from './AccountConfig';
import Coupons from './AccountConfig/Coupons';
import Availability, { getSortedWorkdays } from './Availability';
import { PaymentCardsList, UpdateTypesReceipts } from './PaymentConfig';
import Terms from './Terms';
import {
  initialValuesProfessional,
  schemaAvailability,
  schemaBank,
  schemaBusinessAddress,
  schemaCreditCard,
  schemaInfoPersonal,
  schemaProfessional,
  schemaSecurity,
  schemaTypesReceipts,
  validateInfoAddress,
  validateInfoAvailability,
  validateInfoBankAccount,
  validateInfoPayment,
  validateInfoPerson,
  validateInfoProfessional,
} from './schemas';
import {
  CircleSpan,
  ConfigBody,
  ConfigMain,
  ConfigWrapper,
  MenuHeader,
  MenuOption,
  MobileHeader,
  MobileHeaderTitle,
  OptionText,
  RowLineOptions,
} from './styles';

export function MobileHeaderComponent({ ...props }) {
  const { title } = props;
  return (
    <MobileHeader>
      <MobileHeaderTitle>{title}</MobileHeaderTitle>
    </MobileHeader>
  );
}

export function TopMenuBar({ ...props }) {
  const location = useLocation();
  const { creditCards } = useEvent();
  const history = useHistory();
  const { options, user } = props;
  const [modalAlert, setModalAlert] = useState(false);
  const { state } = location;

  const selected = useMemo(() => {
    if (state?.selected) return state.selected;
    return 'Dados básicos';
  }, [state]);

  const permissionAccess = useMemo(() => {
    if (PLAN_LEVEL_CANCEL_ACCESS.includes(user.planLevel)) {
      return [
        'Pacotes personalizados',
        'Planos personalizados',
        'Formas de recebimento',
        'Cupons',
      ];
    }
    if (PLAN_LEVEL_FREE_ACCESS.includes(user.planLevel) && !user?.clinic?.id) {
      return [
        'Pacotes personalizados',
        'Planos personalizados',
        'Formas de recebimento',
      ];
    }
    return ['Pacotes personalizados', 'Planos personalizados'];
  }, []);

  const messageType = useMemo(() => {
    if (user.type === 'clinic') {
      return 'Você precisa de um plano de assinatura válido para continuar utilizando a plataforma. Clique abaixo para assinar agora!';
    }
    if (user.type === 'professional' && !user?.clinic?.id) {
      return `Você precisa de um plano de assinatura válido para continuar utilizando a plataforma. Clique em "Mais detalhes" para ${PLAN_LEVEL_FREE_ACCESS.includes(user.planLevel) ? 'ver todos os benefícios...' : 'assinar novamente.'}`;
    }
    if (user.type === 'professional' && user?.clinic?.id) {
      return 'Parece que a clinica atrelada ao seu usuário cancelou a assinatura, para voltar a ter acesso a essa funcionalidade é necessário que a clínica renove a assinatura ou que você crie um novo perfil individual e assine um plano diponível.';
    }
  }, [user.type, user?.clinic]);

  const handleClick = (item) => {
    if (
      PLAN_LEVEL_LOW_ACCESS.includes(user?.planLevel) &&
      permissionAccess.includes(item)
    ) {
      setModalAlert(true);
      history.push({
        pathname: '/professional/config',
        state: { selected: 'Dados básicos' },
      });
    } else if (
      !user?.documentNumber &&
      ['Dados bancários', 'Formas de pagamento'].includes(item)
    ) {
      toast.error('Por favor, atualize seu CPF para continuar.');
      history.push({
        pathname: '/professional/config',
        state: { selected: 'Dados básicos' },
      });
    } else {
      history.push({
        pathname: '/professional/config',
        state: { selected: item },
      });
    }
  };

  const validateInfo = useMemo(() => {
    return options.reduce((acc, option) => {
      switch (option) {
        case 'Dados básicos':
          acc[option] = validateInfoPerson(user);
          return acc;
        case 'Endereço comercial':
          acc[option] = validateInfoAddress(user?.address);
          return acc;
        case 'Dados profissionais':
          acc[option] = validateInfoProfessional(user);
          return acc;
        case 'Dados bancários':
          acc[option] = validateInfoBankAccount(user?.paymentAccount);
          return acc;
        case 'Disponibilidade':
          acc[option] = validateInfoAvailability(user?.availabilityRule);
          return acc;
        case 'Formas de pagamento':
          acc[option] = creditCards?.length === 0;
          return acc;
        case 'Formas de recebimento':
          acc[option] = validateInfoPayment(user);
          return acc;
        default:
          return acc;
      }
    }, {});
  }, [selected, user, creditCards]);

  return (
    <MenuHeader>
      {options?.map((item) => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => handleClick(item)}
          type="button"
          style={{
            backgroundColor: `${item === 'Excluir conta' && theme.red}`,
          }}
        >
          <OptionText
            active={selected === item}
            style={{
              color: `${item === 'Excluir conta' && theme.white}`,
            }}
          >
            {item}
          </OptionText>
          {validateInfo[item] && (
            <Tooltip title="Preencha as informações para ativar seu perfil">
              <CircleSpan />
            </Tooltip>
          )}
        </MenuOption>
      ))}

      {modalAlert && (
        <ModalAlert
          confirm={() => {
            if (user.type === 'professional') {
              history.push('/professional/subscription/update_plan');
            }
            setModalAlert(false);
          }}
          confirmPlan
          goBack={() => setModalAlert(false)}
          message={messageType}
        />
      )}
    </MenuHeader>
  );
}

function ConfigBodyComponent({ ...props }) {
  const location = useLocation();
  const {
    disabled,
    form,
    formAddCard,
    setFormAddCard,
    submitDeleteAccount,
    submitLoading,
    user,
    validateInfos,
  } = props;
  const [modalDelete, setModalDelete] = useState(false);
  const { state } = location;

  const selected = useMemo(() => {
    if (state?.selected) return state.selected;
    return 'Dados básicos';
  }, [state]);

  return (
    <ConfigBody>
      {selected === 'Dados básicos' && (
        <UpdateBasicData
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Endereço comercial' && (
        <UpdateAddress form={form} user={user} validateInfos={validateInfos} />
      )}
      {selected === 'Dados profissionais' && (
        <UpdateProfessionalInfos
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Dados bancários' && (
        <UpdateBankAccount form={form} validateInfos={validateInfos} />
      )}
      {selected === 'Formas de recebimento' && (
        <UpdateTypesReceipts
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Disponibilidade' && (
        <Availability config={true} form={form} />
      )}
      {selected === 'Formas de pagamento' && (
        <PaymentCardsList
          form={form}
          formAddCard={formAddCard}
          setFormAddCard={setFormAddCard}
        />
      )}
      {selected === 'Cupons' && <Coupons />}
      {selected === 'Segurança' && (
        <UpdatePassword form={form} validateInfos={validateInfos} />
      )}
      {selected === 'Termos de uso' && <Terms />}
      {selected === 'Excluir conta' && <DeleteAccount form={form} />}

      {![
        'Cupons',
        'Disponibilidade',
        'Formas de pagamento',
        'Termos de uso',
      ].includes(selected) && (
        <ConfigWrapper style={{ marginTop: '3rem' }}>
          {selected !== 'Excluir conta' ? (
            <StaticButton
              disabled={disabled}
              height="3rem"
              title={submitLoading ? 'Carregando...' : 'Salvar alterações'}
            />
          ) : (
            <StaticButton
              type="button"
              buttonColor={theme.red}
              onClick={() => setModalDelete(true)}
              height="3rem"
              title={submitLoading ? 'Carregando...' : 'Excluir conta'}
            />
          )}
        </ConfigWrapper>
      )}

      {modalDelete && (
        <ModalAlert
          goBack={() => setModalDelete(false)}
          message="Ao excluir sua conta, você perderá todos os seus dados, essa
                ação não pode ser desfeita."
          confirm={() => {
            submitDeleteAccount();
            setModalDelete(false);
          }}
        />
      )}
    </ConfigBody>
  );
}

export function ConfigScreen() {
  const history = useHistory();
  const location = useLocation();
  const {
    professionalUpdateAddress,
    professionalUpdateAvailability,
    professionalUpdateBankAccount,
    professionalUpdateBasicData,
    professionalUpdateProfessionalInfos,
    professionalUpdateTypesReceipts,
    signOut,
    updatePassword,
    updateProfessional,
    user,
  } = useAuth();
  const { state } = location;

  const selected = useMemo(() => {
    if (state?.selected) return state.selected;
    return 'Dados básicos';
  }, [state, user]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formAddCard, setFormAddCard] = useState(false);

  const options = useMemo(() => {
    const menuOptions = [
      'Dados básicos',
      'Endereço comercial',
      'Dados profissionais',
      'Dados bancários',
      'Disponibilidade',
      'Formas de pagamento',
      'Formas de recebimento',
      'Cupons',
      'Termos de uso',
      'Segurança',
      'Excluir conta',
    ];

    if (user?.clinic?.id) {
      return menuOptions.filter(
        (option) =>
          !['Pacotes personalizados', 'Planos personalizados'].includes(option)
      );
    }

    return menuOptions;
  }, [user]);

  useEffect(() => {
    const controllerAvailabilityRule = new AbortController();
    const controllerProfessions = new AbortController();
    const controllerCheckClinic = new AbortController();

    getUserAvailabilityRule(controllerAvailabilityRule);
    fetchProfessions(controllerProfessions);
    verifyHistoryState();
    updateInfoClinic();

    if (user?.clinic?.id) {
      getCheckClinic(controllerCheckClinic);
    }

    return () => {
      controllerAvailabilityRule.abort();
      controllerProfessions.abort();
      controllerCheckClinic.abort();
    };
  }, [user]);

  const schemas = useMemo(() => {
    switch (selected) {
      case 'Dados básicos':
        return schemaInfoPersonal;
      case 'Endereço comercial':
        return schemaBusinessAddress;
      case 'Dados profissionais':
        return schemaProfessional;
      case 'Dados bancários':
        return schemaBank;
      case 'Formas de recebimento':
        return schemaTypesReceipts;
      case 'Disponibilidade':
        return schemaAvailability;
      case 'Formas de pagamento':
        return schemaCreditCard;
      case 'Segurança':
        return schemaSecurity;
      default:
        return schemaInfoPersonal;
    }
  }, [selected]);

  const form = useFormik({
    initialValues: initialValuesProfessional(user),
    validationSchema: schemas,
    onSubmit: (values) => submitForm(values),
  });

  const getUserAvailabilityRule = useCallback(async (controller) => {
    try {
      setSubmitLoading(true);
      const { data } = await api.get('/professionals/availabilities/rule', {
        signal: controller.signal,
      });

      if (!data.data) return false;

      form.setFieldValue(
        'blockedTimes',
        parsedAppointmentsTimes(data?.data?.attributes?.blockedTimes)
      );
      form.setFieldValue(
        'appointmentDuration',
        data?.data?.attributes?.appointmentDuration
      );
      form.setFieldValue('breakTime', data?.data?.attributes?.breakTime);
      form.setFieldValue(
        'workdays',
        getSortedWorkdays(data?.data?.attributes?.workdays)
      );
      form.setFieldValue(
        'appointmentTypes',
        data?.data?.attributes?.appointmentTypes
      );
      form.setFieldValue(
        'appointmentTimeBlocks',
        parsedAppointmentsTimes(data?.data?.attributes?.appointmentTimeBlocks)
      );
    } catch (error) {
      if (!controller.signal.aborted)
        toast.error('Erro ao buscar as suas regras de disponibilidade');
    } finally {
      setSubmitLoading(false);
    }
  }, []);

  const fetchProfessions = useCallback(async (controller) => {
    try {
      const { data } = await api.get('/professions', {
        signal: controller.signal,
      });
      const professions = getProfessions(data.data.attributes.professions)?.map(
        (prof) => ({
          ...prof,
          name: getProfessionName(prof.name, 'toPt'),
        })
      );
      form.setFieldValue('jobs', professions);
    } catch (error) {
      if (!controller.signal.aborted)
        toast.error('Não foi possível carregar profissões');
    }
  }, []);

  const getCheckClinic = useCallback(
    async (controller) => {
      try {
        const { data } = await apiPublic.get(
          `/public/check_clinic_username?username=${user?.clinic?.username}`,
          {
            signal: controller.signal,
          }
        );
        if (data.data) {
          form.setFieldValue('clinicId', data.data.id);
          form.setFieldValue('clinicAcceptPix', data.data.attributes.acceptPix);
          form.setFieldValue(
            'clinicAcceptCredit',
            data.data.attributes.acceptCredit
          );
          form.setFieldValue('clinicPostPaid', data.data.attributes.postPaid);
          form.setFieldValue('clinicPrePaid', data.data.attributes.prePaid);
          form.setFieldValue(
            'clinicPostPaidDay',
            data.data.attributes.postPaidDay
          );
          form.setFieldValue(
            'clinicExternalPayment',
            data.data.attributes.externalPayment
          );
        }
      } catch (error) {
        toast.error('Clinica não encontrada, entre em contato com Allminds', {
          autoClose: false,
        });
      }
    },
    [user]
  );

  const verifyHistoryState = useCallback(() => {
    const selectedCash = history.location?.selected;
    if (selectedCash) {
      history.push({
        pathname: '/professional/config',
        state: { selected: selectedCash },
      });
    }
  }, []);

  const submitCreditCard = useCallback(async (values) => {
    setSubmitLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            cardNumber: values.cardNumber,
            cardHolderName: values.cardHolderName,
            cardCvv: values.cardCvv,
            cardExpiration_date: values.cardExpiration_date.replace('/', ''),
            default: values.setDefault,
          },
        },
      };
      await api.post('/professionals/credit_cards', request);
      toast.success('Cartão de crédito adicionado com sucesso');
      setFormAddCard(false);
    } catch (error) {
      toast.error(
        'Erro ao cadastrar cartão de crédito! Você precisa completar os dados pessoais para cadastrar um cartão.'
      );
      history.push({
        pathname: '/professional/config',
        state: { selected: 'Dados básicos' },
      });
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/professional/config',
        state: { selected: 'Formas de pagamento' },
      });
    }
  }, []);

  const parsedAppointmentsTimes = (appointmentsTimes) => {
    const parsedAppointments = Object.entries(appointmentsTimes).reduce(
      (acc, [key, value]) => {
        const hours = value.map((item) => moment(item).format('HH:mm'));
        if (hours.length > 0) {
          acc[key] = hours;
          return acc;
        }
        return acc;
      },
      {}
    );

    return parsedAppointments;
  };

  const submitDeleteAccount = async () => {
    setSubmitLoading(true);
    try {
      await api.delete(`/professionals/${user.id}`, {
        data: { reason: form.values.reason },
      });
      toast.success('Conta excluída com sucesso');
      signOut();
    } catch (error) {
      toast.error('Erro ao excluir conta');
    } finally {
      setSubmitLoading(false);
    }
  };

  const validateInfos = (values) => {
    setDisabled(values);
  };

  const updateInfoClinic = useCallback(() => {
    if (form?.values?.clinicAcceptPix && !form?.values?.clinicAcceptCredit) {
      return form?.setFieldValue('preferentialPayment', 'pix');
    }
    if (!form?.values?.clinicAcceptPix && form?.values?.clinicAcceptCredit) {
      return form?.setFieldValue('preferentialPayment', 'credit_card');
    }
    if (form?.values?.clinicAcceptPix && form?.values?.clinicAcceptCredit) {
      return form?.setFieldValue('preferentialPayment', 'all_payment');
    }
  }, [form?.values?.clinicAcceptPix, form?.values?.clinicAcceptCredit]);

  const updateMethod = useCallback(
    (values, id, method) => {
      switch (method) {
        case 'Dados básicos':
          return professionalUpdateBasicData(values, id);
        case 'Endereço comercial':
          return professionalUpdateAddress(values, id);
        case 'Dados profissionais':
          return professionalUpdateProfessionalInfos(values, id);
        case 'Dados bancários':
          return professionalUpdateBankAccount(values);
        case 'Formas de recebimento':
          return professionalUpdateTypesReceipts(values, id);
        case 'Disponibilidade':
          return professionalUpdateAvailability(values, id);
        case 'Formas de pagamento':
          return submitCreditCard(values);
        case 'Segurança':
          return updatePassword(values, id);
        default:
          return updateProfessional(values, id);
      }
    },
    [selected, user]
  );

  const submitForm = async (values) => {
    values.avatar === user?.avatar && delete values.avatar;
    try {
      setSubmitLoading(true);
      const success = await updateMethod(values, user.id, selected);
      if (success) {
        toast.success(`${selected} atualizados com sucesso.`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/professional/config',
        state: { selected: selected },
      });
    }
  };

  return (
    <FormikProvider value={form}>
      <ConfigMain onSubmit={form.handleSubmit}>
        <TopMenuBar options={options} user={user} />
        <RowLineOptions />
        {submitLoading ? (
          <LoaderSpinner />
        ) : (
          <ConfigBodyComponent
            disabled={disabled}
            form={form}
            submitLoading={submitLoading}
            user={user}
            validateInfos={validateInfos}
            submitDeleteAccount={submitDeleteAccount}
            setFormAddCard={setFormAddCard}
            formAddCard={formAddCard}
          />
        )}
      </ConfigMain>
    </FormikProvider>
  );
}
