/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import valid from 'card-validator';
import { FormikProvider, useFormik } from 'formik';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import EllipseBottom from '../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../images/ellipse-top.svg';
import Logo from '../../../images/logo-mind-white.svg';
import { apiPublic } from '../../../services/api';
import { BREAKPOINT_MD, InstagramIcon } from '../../../styles/globalStyles';
import useWindowSize from '../../../utils/WindowSize';
import camelize from '../../../utils/camelize';
import validateCPF from '../../../utils/validateCPF';
import {
  BackgroundImg,
  ImageContainer,
  ImgEllipseBottom,
  ImgEllipseTop,
  LogoImg,
} from '../../Public/Login/styles';
import {
  IconAreaInstagram,
  SocialIconsWrapper,
} from '../../Public/Profile/styles';
import AcceptanceTerm from '../../Register/AcceptanceTerm';
import BasicRegistration from '../../Register/BasicRegistrationProfessional';
import { LoginWrapper, Main, ProfessionalForm } from './styles';

export default function ProfessionalSignUp() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [validateClinic, setValidateClinic] = useState(false);
  const { backgroundImg } = useEvent();
  const { signUpProfessional } = useAuth();
  const { width } = useWindowSize();

  const clinic = useMemo(() => {
    const clinicNameUrl = new URLSearchParams(location.search)?.get(
      'clinicName'
    );
    if (clinicNameUrl) {
      const clinicNameArray = clinicNameUrl.split('-');
      const clinicId = clinicNameArray[0];
      clinicNameArray.shift(); // Remove a primeira posição do array

      return {
        clinicId,
        clinicName: camelize(clinicNameArray.join(' ')),
        url: clinicNameUrl,
      };
    }

    return null;
  }, [location.search]);

  const demoCode = useMemo(() => {
    const demoCodeUrl = new URLSearchParams(location.search)?.get(
      'demoAccessCode'
    );

    if (demoCodeUrl) return demoCodeUrl;
    return null;
  }, [location.search]);

  useEffect(() => {
    const getCheckClinic = async () => {
      try {
        const { data } = await apiPublic.get(
          `/public/check_clinic_username?username=${clinic.url}`
        );
        if (data.data) {
          form.setFieldValue('acceptPix', data.data.attributes.acceptPix);
          form.setFieldValue('acceptCredit', data.data.attributes.acceptCredit);
          form.setFieldValue('postPaid', data.data.attributes.postPaid);
          form.setFieldValue('postPaidDay', data.data.attributes.postPaidDay);
          form.setFieldValue(
            'externalPayment',
            data.data.attributes.externalPayment
          );
          setValidateClinic(true);
        }
      } catch (error) {
        toast.error('Clinica não encontrada, entre em contato com Allminds', {
          autoClose: false,
        });
      }
    };

    if (clinic?.clinicId) {
      getCheckClinic();
    }
  }, [clinic]);

  useEffect(() => {
    const getEmailDemo = async () => {
      setLoading(true);
      try {
        const { data } = await apiPublic.get(
          `/public/check_demo_access_code?code=${demoCode}`
        );
        if (data.data) {
          form.setFieldValue('email', data.data.attributes.email);
        }
      } catch (error) {
        toast.error(
          'Código de acesso inválido, entre em contato com Allminds',
          {
            autoClose: false,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    if (demoCode) {
      getEmailDemo();
    }
  }, [demoCode]);

  const submitForm = async (values) => {
    setLoading(true);
    try {
      const success = await signUpProfessional(values);
      if (success) {
        history.push('/profissional/sucesso');
      } else {
        toast.error(
          'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
        );
      }
    } catch (error) {
      toast.error(
        'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
      );
    } finally {
      setLoading(false);
    }
  };

  const schema = Yup.object().shape({
    // basic registration
    firstName: Yup.string().required('Nome é obrigatório!'),
    lastName: Yup.string().required('Sobrenome é obrigatório!'),
    email: Yup.string()
      .email('Email invalido')
      .required('Email é obrigatório!'),
    phone: Yup.string().required('Telefone é obrigatório!'),
    password: Yup.string()
      .required('Senha é Requerida')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
    confirm_password: Yup.string()
      .required('Repita a senha')
      .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais.'),

    // address
    cep: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('CEP é obrigatório.'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    street: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('Endereço é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    street_number: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('Endereço é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    complement: Yup.string().optional().nullable(),
    neighborhood: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('Bairro é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    city: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('Cidade é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    state: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('Estado é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    country: Yup.string().when('planName', {
      is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
      then: Yup.string().nullable().required('Pais é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),

    // accept term
    termId: Yup.string().required('Termo é obrigatório'),
    accepted: Yup.boolean().required('Aceitar o termo é obrigatório!'),
    read: Yup.boolean().required('Ler o termo é obrigatório!'),

    // subscription
    planName: Yup.string().nullable().optional(),
    documentNumber: Yup.string()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          if (!value) return true;
          return validateCPF(value || '') || null;
        },
      })
      .when('planName', {
        is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
        then: Yup.string().nullable().required('CPF é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardNumber: Yup.string()
      .nullable()
      .test(
        'test-number',
        'Cartão inválido',
        (value) => !value || valid.number(value).isValid
      )
      .when('planName', {
        is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
        then: Yup.string()
          .nullable()
          .required('Número do cartão é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardHolderName: Yup.string()
      .nullable()
      .when('planName', {
        is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
        then: Yup.string().nullable().required('Nome é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardCvv: Yup.string()
      .nullable()
      .test(
        'test-cvv',
        'Código de segurança inválido',
        (value) => !value || valid.cvv(value).isValid
      )
      .when('planName', {
        is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
        then: Yup.string().nullable().required('CVV é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardExpiration_date: Yup.string()
      .nullable()
      .test(
        'test-date',
        'Data inválida',
        (value) => !value || valid.expirationDate(value).isValid
      )
      .when('planName', {
        is: (planName) => ['silver', 'gold', 'platinum'].includes(planName),
        then: Yup.string()
          .nullable()
          .required('Data de expiração é obrigatória.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
  });

  const form = useFormik({
    initialValues: {
      // basic registration
      clinicId: clinic?.clinicId || clinic,
      clinicName: clinic?.clinicName || clinic,
      demoAccessCode: demoCode,
      email: '',
      firstName: '',
      lastName: '',
      documentNumber: '',
      password: '',
      phone: '',

      // address
      cep: '',
      street: '',
      street_number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',

      // accept term
      termId: '',
      accepted: false,
      read: false,

      // subscription
      planName: demoCode ? 'demo' : 'trial',
      cardNumber: null,
      cardHolderName: null,
      cardCvv: null,
      cardExpiration_date: null,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => submitForm(values),
  });

  useEffect(() => {
    const toggleModal = () => {
      setLoading(false);
    };
    toggleModal();
    if (form.values.clinicId && step === 6) {
      setStep((prevStep) => (prevStep += 1));
    }
  }, [step]);

  function next() {
    setStep((prevStep) => (prevStep += 1));
  }

  function goBack() {
    setStep((prevStep) => (prevStep -= 1));
  }

  return (
    backgroundImg && (
      <FormikProvider value={form}>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <Main step={step}>
            <ProfessionalForm>
              <LoginWrapper step={step}>
                <ImageContainer>
                  <LogoImg src={Logo} alt="Logo" />
                </ImageContainer>
                {step === 0 && !loading && (
                  <BasicRegistration
                    next={next}
                    form={form}
                    clinic={validateClinic}
                  />
                )}
                {step === 1 && !loading && (
                  <AcceptanceTerm goBack={goBack} form={form} />
                )}
              </LoginWrapper>
            </ProfessionalForm>
            <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
            {width > BREAKPOINT_MD && ![1].includes(step) && (
              <Fragment>
                <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
                <BackgroundImg style={{ zIndex: 0 }} src={backgroundImg} />
              </Fragment>
            )}
            <SocialIconsWrapper align={[1].includes(step)}>
              {/* <Tooltip title="Fale com a gente">
                <IconAreaWhatsApp
                  href="https://wa.me/5571999960614"
                  target="_blank"
                >
                  <WhatsAppIcon fontSize="medium" sx={{ color: '#fff' }} />
                </IconAreaWhatsApp>
              </Tooltip> */}
              <Tooltip title="Siga-nos no Instagram">
                <IconAreaInstagram
                  href="https://www.instagram.com/allminds.app/"
                  target="_blank"
                >
                  <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
                </IconAreaInstagram>
              </Tooltip>
            </SocialIconsWrapper>
          </Main>
        )}
      </FormikProvider>
    )
  );
}
