import React, { Suspense, useEffect } from 'react';
import LoaderSpinner from '../components/LoaderSpinner';
import DefaultRoutes from './routes';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

export default function Router() {
  const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
      const tagManagerArgs = {
        dataLayer: {
          event: 'pageview',
          page: location.pathname,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }, [location]);

    return null;
  };

  return (
    <Suspense fallback={<LoaderSpinner />}>
      <TrackPageView />
      <DefaultRoutes />
    </Suspense>
  );
}
