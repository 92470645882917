/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/auth';
import api from '../../services/api';
import {
  ButtonScrollToTop,
  EmptyContainer,
  EmptyDashBoard,
  EmptyFont,
  EmptyMessage,
  EmptyProfessional,
  EmptySpan,
  EmptyTransaction,
  ListWrapper,
  ScrollToTop,
} from '../../styles/globalStyles';
import getConvertFrequency from '../../utils/getConvertFrequency';
import { CategoryRowItem, PostBanner, PostRowItem } from '../ListItens';
import LoaderSpinner from '../LoaderSpinner';
import { DashBoardSearchBar, DefaultSearchBar } from '../SearchBar';
import TableHistory from '../TableHistory';
import {
  Container,
  LibraryWrapper,
  Line,
  ListContainer,
  PostListWrapper,
  SearchWrapper,
} from './styles';

function EmptyImage({ ...props }) {
  const { type } = props;

  switch (type) {
    case 'patient':
      return (
        <EmptyContainer>
          <EmptyDashBoard />
          <EmptyFont>
            Você ainda não possui nenhum <EmptySpan>cliente</EmptySpan>{' '}
          </EmptyFont>
        </EmptyContainer>
      );
    case 'professional':
      return (
        <EmptyContainer>
          <EmptyProfessional />
          <EmptyFont>
            Você ainda não possui nenhum <EmptySpan>profissional</EmptySpan>
          </EmptyFont>
        </EmptyContainer>
      );
    case 'transaction':
      return (
        <EmptyContainer>
          <EmptyTransaction />
          <EmptyFont>
            Parece que você ainda não possui nenhuma{' '}
            <EmptySpan>transação</EmptySpan>
          </EmptyFont>
        </EmptyContainer>
      );
    case 'message':
      return (
        <EmptyContainer>
          <EmptyMessage />
          <EmptyFont>
            Você ainda não possui <EmptySpan>mensagens</EmptySpan>
          </EmptyFont>
        </EmptyContainer>
      );
    case 'schedule':
      return (
        <EmptyContainer>
          <EmptyDashBoard />
          <EmptyFont>
            Você ainda não possui nenhuma{' '}
            <EmptySpan>consulta realizada</EmptySpan>
          </EmptyFont>
        </EmptyContainer>
      );
    case 'statement':
      return (
        <EmptyContainer>
          <EmptyDashBoard />
          <EmptyFont>
            Você ainda não possui nenhum <EmptySpan>lançamento</EmptySpan> para
            o período selecionado
          </EmptyFont>
        </EmptyContainer>
      );
    default:
      return <EmptyMessage />;
  }
}

export function Flatlist({ ...props }) {
  const {
    placeholder,
    data,
    filterData,
    renderItem,
    filterObject,
    dateSearch,
    searchType,
    type,
    loading,
  } = props;
  const [filter, setFilter] = useState('');
  const [result, setResult] = useState(data);
  const scrollRef = useRef(null);

  const dateFormat = (date) =>
    type === 'client'
      ? `${moment(date.attributes.startDate, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )}`
      : moment(date.attributes.startDate, 'YYYY-MM-DD').format(
          'DD [de] MMMM [de] YYYY'
        );

  const search = (item) =>
    `${dateFormat(item)} ${moment(item.attributes.startTime, 'HH:mm:ss').format(
      'HH:mm'
    )} ${item.attributes[`${searchType}`]?.firstName} ${
      searchType !== 'client' && item?.attributes[searchType]?.profession
    }`;

  const dataService = dateSearch
    ? data?.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          search: search(item),
        },
      }))
    : data;

  useEffect(() => {
    const filterList = () => {
      const tmp = dataService?.filter((item) =>
        (filterData ? item[`${filterData}`] : filterObject(item))
          ?.toLowerCase()
          .includes(filter?.toLowerCase())
      );

      setResult(tmp);
    };
    filterList();
  }, [filter]);

  useEffect(() => {
    if (data?.length > 0) {
      setResult(data);
    }
  }, [data]);

  function scrollToTop() {
    return scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Container>
      <DefaultSearchBar
        autoFocus
        disabled={loading}
        onInput={({ target }) => setFilter(target.value)}
        placeholder={placeholder}
        type="text"
      />
      <Line />
      {loading ? (
        <LoaderSpinner logo />
      ) : (
        <Fragment>
          {data?.length > 0 ? (
            <ListContainer>
              {result?.length === 0 ? (
                <EmptyContainer>
                  <EmptyTransaction />
                  <EmptyFont>
                    Não foram encontrados resultados para esta busca!
                  </EmptyFont>
                </EmptyContainer>
              ) : (
                <ListWrapper ref={scrollRef}>
                  {result?.map(renderItem)}
                </ListWrapper>
              )}
              {!loading && (
                <Tooltip title="Voltar ao topo">
                  <ButtonScrollToTop onClick={scrollToTop}>
                    <ScrollToTop />
                  </ButtonScrollToTop>
                </Tooltip>
              )}
            </ListContainer>
          ) : (
            <EmptyImage type={type} />
          )}
        </Fragment>
      )}
    </Container>
  );
}

export function FlatlistStatements({ ...props }) {
  const {
    placeholder,
    data,
    filterData,
    filterObject,
    dateSearch,
    type,
    loading,
    modalControl,
  } = props;
  const [filter, setFilter] = useState('');
  const [result, setResult] = useState(data);
  const scrollRef = useRef(null);

  const search = (item) => item.description;

  const dataService = dateSearch
    ? data?.map((item) => ({
        id: item.id,
        attributes: {
          ...item,
          search: search(item),
        },
      }))
    : data;

  useEffect(() => {
    const filterList = () => {
      const tmp = dataService?.filter((item) =>
        (filterData ? item[`${filterData}`] : filterObject(item))
          ?.toLowerCase()
          .includes(filter?.toLowerCase())
      );

      setResult(tmp);
    };
    filterList();
  }, [filter]);

  useEffect(() => {
    if (data?.length > 0) {
      setResult(data);
    }
  }, [data]);

  function scrollToTop() {
    return scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Container>
      <DefaultSearchBar
        autoFocus
        disabled={loading}
        onInput={({ target }) => setFilter(target.value)}
        placeholder={placeholder}
        type="text"
      />
      <Line />
      {loading ? (
        <LoaderSpinner logo />
      ) : (
        <Fragment>
          {data?.length > 0 ? (
            <ListContainer>
              {result?.length === 0 ? (
                <EmptyContainer>
                  <EmptyTransaction />
                  <EmptyFont>
                    Não foram encontrados resultados para esta busca!
                  </EmptyFont>
                </EmptyContainer>
              ) : (
                <TableHistory
                  ref={scrollRef}
                  data={data}
                  modalControl={modalControl}
                />
              )}
              {!loading && (
                <Tooltip title="Voltar ao topo">
                  <ButtonScrollToTop onClick={scrollToTop}>
                    <ScrollToTop />
                  </ButtonScrollToTop>
                </Tooltip>
              )}
            </ListContainer>
          ) : (
            <EmptyImage type={type} />
          )}
        </Fragment>
      )}
    </Container>
  );
}

export function Defaultlist({ ...props }) {
  const { data, renderItem } = props;
  const [result, setResult] = useState(data);

  useEffect(() => {
    setResult(data);
  }, [data]);

  return <Container>{result?.map(renderItem)}</Container>;
}

export function Library({ ...props }) {
  const { id } = props;
  const history = useHistory();
  const { user } = useAuth();
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilter] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const filterPosts = async () => {
      try {
        if (id !== 'noCategory') {
          const { data } = await api.get(`/professionals/categories/${id}`, {
            signal: controller.signal,
          });
          setCategory(data.data);
          const { data: postList } = await api.get(
            `/professionals/categories/${id}/posts`,
            {
              signal: controller.signal,
            }
          );
          setResult(postList.data);
          setPosts(postList.data);
        } else {
          setCategory('No Category');
          const { data: postList } = await api.get(`/professionals/posts`, {
            signal: controller.signal,
          });
          setResult(postList.data);
          setPosts(postList.data);
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao carregar conteúdos');
      } finally {
        setLoading(false);
      }
    };
    filterPosts();

    return () => controller.abort();
  }, [loading]);

  useEffect(() => {
    const filterPosts = () => {
      const tmp = posts?.filter(
        (item) =>
          item.attributes.title
            .toLowerCase()
            .includes(filterText.toLowerCase()) ||
          item.attributes.body.toLowerCase().includes(filterText.toLowerCase())
      );
      setResult(tmp);
    };
    filterPosts();
  }, [filterText]);

  return (
    loading ||
    (id && (
      <Fragment>
        <SearchWrapper>
          <DefaultSearchBar
            type="text"
            placeholder="Conteúdos"
            onInput={({ target }) => {
              setFilter(target.value);
            }}
          />
        </SearchWrapper>
        <PostListWrapper style={{ paddingBottom: '5%' }}>
          {result?.length === 0 ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <PostBanner item={id !== '0' ? category : 0} type="book-open" />
              <LibraryWrapper>
                {result?.map((item) => (
                  <PostRowItem
                    key={item.id}
                    item={item}
                    type="book-open"
                    onClick={() => history.push(`/${type}/content/${item.id}`)}
                  />
                ))}
              </LibraryWrapper>
            </Fragment>
          )}
        </PostListWrapper>
      </Fragment>
    ))
  );
}

export function PostList({ ...props }) {
  const { onClick } = props;
  const [posts, setPosts] = useState([]);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilter] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const filterPosts = async () => {
      try {
        const { data } = await api.get('/professionals/categories', {
          signal: controller.signal,
        });
        setResult(data.data);
        setPosts(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao carregar conteúdos');
      } finally {
        setLoading(false);
      }
    };
    filterPosts();

    return () => controller.abort();
  }, [loading]);

  useEffect(() => {
    const filterPosts = () => {
      const tmp = posts?.filter(
        (item) =>
          item?.attributes?.title?.includes(filterText) ||
          item?.attributes?.body?.includes(filterText)
      );
      setResult(tmp);
    };
    filterPosts();
  }, [filterText]);

  return (
    loading || (
      <Fragment>
        <SearchWrapper>
          <DashBoardSearchBar
            type="text"
            value={filterText}
            placeholder="Conteúdos"
            onInput={({ target }) => {
              setFilter(target.value);
            }}
            onClickButton={(e) => setFilter(e)}
          />
        </SearchWrapper>
        <PostListWrapper>
          {result?.length === 0 ? (
            <Fragment></Fragment>
          ) : (
            <LibraryWrapper>
              {result?.map((item) => {
                return (
                  <CategoryRowItem
                    key={item.id}
                    item={item}
                    type="itens"
                    onClick={(id) => onClick(id)}
                  />
                );
              })}
              <CategoryRowItem
                item={'noCategory'}
                type="itens"
                onClick={() => onClick('noCategory')}
              />
            </LibraryWrapper>
          )}
        </PostListWrapper>
      </Fragment>
    )
  );
}

export function FlatlistPlans({ ...props }) {
  const {
    placeholder,
    data,
    filterData,
    renderItem,
    filterObject,
    dateSearch,
    loading,
  } = props;
  const [filter, setFilter] = useState('');
  const [result, setResult] = useState(data);
  const scrollRef = useRef(null);

  const dataSearch = data.map((data) => ({
    ...data,
    planName: data.attributes.appointmentPlan.name,
    professionalName: `${data.attributes.professional.first_name} ${data.attributes.professional.last_name}`,
    startDate: moment(data.attributes.startDate).format('DD/MM/YYYY'),
    frequency: getConvertFrequency(data.attributes.appointmentPlan.frequency),
    price: data.attributes.appointmentPlan.price,
  }));

  const search = (item) => {
    const searchString = item.planName
      .toLowerCase()
      .concat(
        ' ',
        item.professionalName.toLowerCase(),
        ' ',
        item.startDate.toLowerCase()
      )
      .concat(' ', item.frequency.toLowerCase(), ' ', item.price.toString());
    return searchString;
  };

  const dataService = dateSearch
    ? dataSearch?.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          search: search(item),
        },
      }))
    : data;

  useEffect(() => {
    const filterList = () => {
      const tmp = dataService?.filter((item) =>
        (filterData ? item[`${filterData}`] : filterObject(item))
          ?.toLowerCase()
          .includes(filter?.toLowerCase())
      );

      setResult(tmp);
    };
    filterList();
  }, [filter]);

  useEffect(() => {
    if (data?.length > 0) {
      setResult(data);
    }
  }, [data]);

  function scrollToTop() {
    return scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Container>
      <DefaultSearchBar
        autoFocus
        disabled={loading}
        onInput={({ target }) => setFilter(target.value)}
        placeholder={placeholder}
        type="text"
      />
      <Line />
      {loading ? (
        <LoaderSpinner logo />
      ) : (
        <Fragment>
          {data?.length > 0 ? (
            <ListContainer>
              {result?.length === 0 ? (
                <EmptyContainer>
                  <EmptyTransaction />
                  <EmptyFont>
                    Não foram encontrados resultados para esta busca!
                  </EmptyFont>
                </EmptyContainer>
              ) : (
                <ListWrapper ref={scrollRef}>
                  {result?.map(renderItem)}
                </ListWrapper>
              )}
              {!loading && (
                <Tooltip title="Voltar ao topo">
                  <ButtonScrollToTop onClick={scrollToTop}>
                    <ScrollToTop />
                  </ButtonScrollToTop>
                </Tooltip>
              )}
            </ListContainer>
          ) : (
            <EmptyImage type="professional" />
          )}
        </Fragment>
      )}
    </Container>
  );
}
