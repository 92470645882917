import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box } from '@mui/material';
import {
  BookOpen,
  DollarSign,
  Edit,
  Eye,
  EyeOff,
  Info,
  MapPin,
  MessageSquare,
  MinusCircle,
  PlusCircle,
  Trash,
  Video,
  X,
} from 'react-feather';
import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ExtrBigFont,
  SmallFont,
} from '../../styles/globalStyles';
import { SmallButton } from '../Button';

export const Container = styled.button`
  width: 100%;
  height: 260px;
  border: none;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
`;

export const BannerContainer = styled.section`
  width: 100%;
  height: 160px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
`;

export const BannerDescription = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const PatientImage = styled.div`
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    display: flex;
    position: absolute;
    top: 0.5rem;
    left: 1rem;
  }
`;

export const PostImage = styled.img`
  flex: 1;
  width: 100%;
  height: 150px;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: column;
`;

export const ClinicImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const PostDescription = styled.div`
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
`;

export const RowLine = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BoldText = styled(SmallFont)`
  text-align: left;
  color: ${({ theme }) => theme.secondary};
`;

export const LightText = styled(SmallFont)`
  text-align: left;
  color: ${({ theme }) => theme.ExtralightGray};
`;

export const BoldTextWhite = styled(BoldText)`
  font-weight: 500;
  color: ${({ theme }) => theme.offWhite};
`;

export const LightTextWhite = styled(LightText)`
  color: ${({ theme }) => theme.offWhite};
`;

export const BookOpenIcon = styled(BookOpen)`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: ${({ theme }) => theme.darkGray};
`;

export const Wrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 0.7rem;
  border: ${({ theme, schedule }) =>
    schedule === 'true' ? 'transparent' : `1px solid ${theme.color10}`};
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  padding: ${({ schedule }) => (schedule === 'true' ? '0' : '0.7rem 0.625rem')};
  width: 100%;
`;

export const OpenedContent = styled.section`
  align-items: center;
  background-color: ${({ theme, schedule }) =>
    schedule === 'true' ? theme.newSecondary : theme.newPrimary};
  border-radius: 0.625rem;
  border: 1px solid
    ${({ theme, schedule }) => (schedule === 'true' ? theme.white : 'none')};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  padding: ${({ schedule }) => (schedule === 'true' ? '0.5rem' : '1rem 1rem')};
  position: relative;
  width: 100%;

  .schedule-title {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .schedule-date {
    color: ${({ theme }) => theme.primary_blue};
    font-size: 0.6rem;
    font-weight: 900;
  }

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    flex-flow: row;
    justify-content: flex-start;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .schedule-title {
      font-size: 0.8rem;
    }
    padding: 1rem;
  }
`;

export const NotificationContent = styled.div`
  align-items: center;
  background-color: ${({ theme, read }) =>
    read === null ? theme.newPrimaryAlt : theme.newPrimary};
  cursor: pointer;
  border-radius: 0.625rem;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  text-decoration: none;
  width: 100%;

  .title {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    .title {
      font-size: 0.8rem;
    }
    .subtitle {
      font-size: 0.7rem;
    }
  }
`;

export const ProfessionalContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2rem;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    margin-top: 2rem;
    margin-left: 0;
    width: 100%;
  }
`;

export const ProfessionalContentSchedules = styled(ProfessionalContent)``;

export const ProfessionalInformation = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1rem;
  width: 100%;

  .title {
    margin-right: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 0;
    .title {
      font-size: 1rem;
    }
  }
`;

export const ProfessionalSchedule = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 0;
    margin-top: 0.5rem;
    .schedule-title {
      font-size: 1rem;
    }
  }
`;

export const SFontDark = styled(SmallFont)`
  color: ${({ theme, date }) => (date ? theme.primary_blue : theme.black)};
  font-weight: ${({ date }) => (date ? '900' : 'normal')};
  font-size: ${({ date }) => date === 'true' && '0.6rem'};
  max-width: 300px;
  overflow-y: hidden;
  white-space: pre-line;
  word-wrap: break-word;
`;

export const BFontBold = styled(BigFont)`
  word-wrap: break-word;
  white-space: pre-line;
  color: ${({ theme }) => theme.secondary};
`;

export const GFontBold = styled(ExtrBigFont)`
  color: ${({ theme }) => theme.secondary};
  font-size: 1.8rem;
  white-space: pre-line;
  word-wrap: break-word;
`;

export const InvoiceButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.primary_blue};
  color: ${({ theme }) => theme.primary_blue};
  cursor: pointer;
  display: flex;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  padding: 0 10px;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.primary_blue};

    color: ${({ theme }) => theme.white};
  }
`;

export const Image = styled.img`
  width: ${({ size }) => size || '80px'};
  height: ${({ size }) => size || '80px'};
  display: flex;
  border-radius: ${({ radius }) => radius || '40px'};
`;

export const Message = styled(MessageSquare)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.black};
`;

export const InfoIcon = styled(Info)`
  width: 18px;
  height: 18px;
  color: ${({ theme, active }) =>
    active === 'true' ? theme.primary_blue : theme.black};
`;

export const EditIcon = styled(Edit)`
  width: 18px;
  height: 18px;
  color: ${({ theme, active }) =>
    active === 'true' ? theme.primary_blue : theme.black};
`;

export const ColumnItem = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: flex-start;
  }
`;

export const RowItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    margin-left: 0;
    flex-flow: row wrap;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-content: center;
  }
`;

export const RowItemPatient = styled(RowItem)`
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-content: flex-end;
    top: 0.5rem;
    right: 0.5rem;
    position: absolute;
    width: 100%;
  }
`;

export const RowItemSchedule = styled(RowItemPatient)``;

export const Button = styled(SmallButton)`
  width: 120px;
  height: 35px;
  margin-top: 5px;
  background-color: ${({ theme }) => theme.white};

  &:hover {
    transition: 0.3s;
    .-hover {
      color: ${({ theme }) => theme.primaryAlt};
    }
  }
  .up-archive {
    min-width: 280px;
    label {
      min-width: 280px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
    }
    input {
      min-width: 280px;
      cursor: pointer;
      display: none;
    }
  }
`;

export const MessageButton = styled.button`
  margin-left: 0.325rem;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'transparent'};
  border: 1px solid ${({ theme }) => theme.black};
`;

export const IconArea = styled.div``;

export const MessageWrapper = styled.button`
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.white};
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 75px;
  width: 100%;
  cursor: default;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-content: center;
  }
`;

export const DiaryWrapper = styled.section`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const MessageContent = styled.section`
  align-items: center;
  background-color: ${({ theme, active }) =>
    active === 'true' ? theme.primary_blue : theme.newPrimary};
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.newPrimaryAlt};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  min-height: 3.5rem;
  padding: 10px 20px;
  transition: 0.3s;
  width: 100%;
  .--text {
    color: ${({ theme, active }) => active === 'true' && theme.white};
  }
`;

export const MessageInformation = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;

  .--title {
    margin-left: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;

    .--title,
    .--date {
      font-size: 0.8rem;
    }
  }
`;

export const MessageInformationAnnotation = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;

  .--date {
    color: ${({ theme, active }) =>
      active === 'true' ? theme.white : theme.black};
  }
  .--title {
    color: ${({ theme, active }) =>
      active === 'true' ? theme.white : theme.primary_blue};
    font-weight: 600;
  }
`;

export const Finance = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.color10};
  display: flex;
  justify-content: ${({ payment }) =>
    payment === 'true' ? 'space-evenly' : 'space-between'};
  margin-bottom: 0.7rem;
  padding: 0.7rem;
  width: 100%;

  .primary {
    justify-content: ${({ payment }) =>
      payment === 'true' ? 'flex-start' : 'space-evenly'};
    width: ${({ payment }) => (payment === 'true' ? '30%' : '50%')};
  }

  .secondary {
    justify-content: ${({ payment }) =>
      payment === 'true' ? 'flex-end' : 'space-around'};
    width: ${({ payment }) => (payment === 'true' ? '30%' : '50%')};
  }

  .client {
    align-items: center;
    justify-content: ${({ payment }) => payment === 'true' && 'center'};
    width: ${({ payment }) => payment === 'true' && '40%'};
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;

    .primary {
      justify-content: ${({ payment }) =>
        payment === 'true' ? 'center' : 'space-between'};
      margin-bottom: 0.7rem;
      margin-left: 0;
      width: 100%;
    }

    .secondary {
      justify-content: ${({ payment }) => payment === 'true' && 'center'};
      margin-left: 0;
      width: 100%;
    }

    .client {
      align-items: center;
      justify-content: center;
      margin-left: 0;
      width: 100%;
    }
  }
`;

export const FinancePayment = styled(Finance)`
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr 0.8fr;
  align-items: center;
  justify-content: center;
  width: 100%;

  .--finance-type {
    text-align: center;
    width: 100%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;

    .--finance-type {
      width: fit-content;
      text-align: left;
    }
  }
`;

export const FinanceType = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CreditCard = styled.section`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.secondary_blue : theme.newPrimary};
  border-radius: 0.7rem;
  display: flex;
  min-height: 3rem;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 100%;
  cursor: ${({ active, selected }) =>
    active && selected === false ? 'pointer' : 'default'};

  .brand {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .text-card {
    color: ${({ theme, selected }) => (selected ? theme.white : theme.black)};
    margin-left: 1rem;
    font-weight: 700;
    font-size: 1rem;
    cursor: ${({ active, selected }) =>
      active && selected === false ? 'pointer' : 'default'};
  }
  .default {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    .text-card {
      font-size: 0.8rem;
    }
  }
`;

export const FinanceInformation = styled.section`
  display: flex;
  align-items: ${({ date }) => (date === 'true' ? 'flex-start' : 'center')};
  justify-content: center;
`;

export const PayInIcon = styled(PlusCircle)`
  width: 27px;
  height: 27px;
  color: ${({ theme }) => theme.primary};
`;

export const PayOutIcon = styled(MinusCircle)`
  width: 27px;
  height: 27px;
  color: ${({ theme }) => theme.pink};
`;

export const FavoriteIcon = styled(StarBorderIcon)`
  width: 1.3rem;
  height: 1.3rem;
  color: ${({ theme }) => theme.primary_blue};
`;

export const ViewCardIcon = styled(Eye)`
  width: 1.3rem;
  height: 1.3rem;
  margin: 0.1rem;
  color: ${({ theme }) => theme.primary_blue};
`;

export const UnViewCardIcon = styled(EyeOff)`
  width: 1.3rem;
  height: 1.3rem;
  margin: 0.1rem;
  color: ${({ theme }) => theme.primary_blue};
`;

export const FavoriteIconSelected = styled(StarIcon)`
  width: 1.3rem;
  height: 1.3rem;
  color: ${({ theme }) => theme.primary_blue};
`;

export const DeleteIcon = styled(DeleteForeverIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.pink};
`;

export const IconAreaFinance = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.white : theme.newPrimary};
  border: 1px solid ${({ theme }) => theme.black};
  cursor: pointer;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 0.5rem;
  padding: 0.25rem 0.25rem;
  margin: 0 0.25rem;
`;

export const MFontFinance = styled(BigFont)`
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
`;

export const SMFontFinanceDarkGray = styled(SmallFont)`
  color: ${({ theme }) => theme.black};
`;

export const Invoice = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.7rem;
  display: flex;
  padding: 0.7rem 1rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.color10};

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0.7rem 0;
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

export const InvoiceType = styled.section`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InvoiceInformation = styled.section`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export const StatusTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2em;
  width: max-content;
  padding: 6px 15px 6px 15px;
  height: 32px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 144%;
  letter-spacing: -0.02em;
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-right: 0;
  }
`;

export const SMFontInvoiceDarkGray = styled(SmallFont)`
  color: ${({ theme }) => theme.darkGray};
`;

export const SMFontInvoiceSecondary = styled(SmallFont)`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: ${({ theme }) => theme.secondary};
`;

export const BankAccount = styled.div`
  flex: 1;
  width: 100%;
  max-height: 100px;
  max-width: 500px;
  display: flex;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.primary : theme.color10)};

  &:hover {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

export const BABankName = styled(BigFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const BABankNumber = styled(SmallFont)`
  font-weight: 300;
  color: ${({ theme }) => theme.darkGray};
`;

export const DeleteButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.pink};
  color: ${({ theme }) => theme.pink};
  display: flex;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.5rem;
  transition: 0.3s;
  width: 2rem;
`;

export const CanceledInfo = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.pink};
  color: ${({ theme }) => theme.pink};
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.5rem;
  transition: 0.3s;
`;

export const EditButton = styled(DeleteButton)`
  border: 1px solid ${({ theme }) => theme.primary_blue};
  color: ${({ theme }) => theme.primary_blue};
`;

export const DiaryButtonDelete = styled(Trash)`
  height: 2rem;
  width: 2rem;
  color: ${({ theme, active }) =>
    active === 'true' ? theme.primary_blue : '#999EBC'};
`;

export const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0;
  min-height: 80px;
  padding: 10px 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const ReviewImage = styled.section`
  height: 60px;
  width: 60px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ReviewContent = styled.section`
  flex: 9;
  display: flex;
  padding-left: 15px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SMFontBDark = styled(SmallFont)`
  font-weight: 800;
  color: ${({ theme }) => theme.secondary};
`;

export const LinkArea = styled.a`
  margin-left: 0.325rem;
  margin-top: 0.325rem;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'transparent'};
  border: 1px solid ${({ theme }) => theme.black};
  .--icon {
    color: ${({ theme }) => theme.black};
  }
`;

export const CameraIcon = styled(Video)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.ExtralightGray};
`;

export const MapPinIcon = styled(MapPin)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.ExtralightGray};
`;

export const PaymentIcon = styled(DollarSign)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.pink};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 1rem 0 3rem 0;
`;

export const BoxWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  max-height: 90%;
  max-width: 80%;
  padding: 1rem 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
`;

export const CloseIcon = styled(X)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
`;

export const IconAreaModal = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-end;
`;

export const CustomPackagesMain = styled.tr`
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.color10};
  width: 100%;
`;

export const CustomPackagesTD = styled.td`
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  padding: 0.5rem 1rem;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StatementsWrapper = styled.tr`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.color10};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;
`;

export const ColumnItemStatement = styled.td`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
  width: fit-content;
`;
