import {
  AccountBalance,
  Assignment,
  AttachMoney,
  CalendarMonth,
  EventNote,
  Group,
  Home,
  Logout,
  Message,
  Person,
  ReceiptLong,
  RequestQuote,
  Settings,
  Source,
  ViewInAr,
} from '@mui/icons-material';
import React from 'react';

export const sideBarClient = [
  {
    title: 'Painel',
    path: `/client/dashboard`,
    className: 'nav-text',
    icon: <Home />,
    active: 'false',
  },
  {
    title: 'Buscar Profissionais',
    path: `/client/professionals`,
    className: 'nav-text',
    icon: <Group />,
    active: 'false',
  },
  {
    title: 'Agenda',
    path: `/client/calendar`,
    className: 'nav-text',
    icon: <CalendarMonth />,
    active: 'false',
  },
  {
    title: 'Relatório de Consultas',
    path: `/client/schedules_report`,
    className: 'nav-text',
    icon: <EventNote />,
    active: 'false',
  },
  {
    title: 'Conteúdos',
    path: `/client/contents`,
    className: 'nav-text',
    icon: <Source />,
    active: 'false',
  },
  {
    title: 'Mensagens',
    path: `/client/messages`,
    className: 'nav-text',
    icon: <Message />,
    active: 'false',
  },
  {
    title: 'Carteira',
    path: `/client/finances`,
    className: 'nav-text',
    icon: <AccountBalance />,
    active: 'false',
  },
  {
    title: 'Pacotes e Assinaturas',
    path: `/client/packages-assignments`,
    className: 'nav-text',
    icon: <ViewInAr />,
    active: 'false',
  },
  {
    title: 'Sair',
    path: `/conectar-se`,
    className: 'nav-text',
    icon: <Logout />,
    active: 'true',
  },
];

export const sideBarProfessional = [
  {
    title: 'Painel',
    path: `/professional/dashboard`,
    className: 'nav-text',
    icon: <Home />,
    active: 'false',
  },
  {
    title: 'Clientes',
    path: `/professional/patients`,
    className: 'nav-text',
    icon: <Group />,
    active: 'false',
  },
  {
    title: 'Agenda',
    path: `/professional/calendar`,
    className: 'nav-text',
    icon: <CalendarMonth />,
    active: 'false',
  },
  {
    title: 'Perfil',
    path: `/professional/profile`,
    className: 'nav-text',
    icon: <ReceiptLong />,
    active: 'false',
  },
  {
    title: 'Relatório de Consultas',
    path: `/professional/schedules_report`,
    className: 'nav-text',
    icon: <EventNote />,
    active: 'false',
  },
  {
    title: 'Conteúdos',
    path: `/professional/contents`,
    className: 'nav-text',
    icon: <Source />,
    active: 'false',
  },
  {
    title: 'Mensagens',
    path: `/professional/messages`,
    className: 'nav-text',
    icon: <Message />,
    active: 'false',
  },
  {
    title: 'Carteira',
    path: `/professional/finances`,
    className: 'nav-text',
    icon: <AccountBalance />,
    active: 'false',
  },
  {
    title: 'Gestão financeira',
    path: `/professional/statement`,
    className: 'nav-text',
    icon: <RequestQuote />,
    active: 'false',
  },
  {
    title: 'Pacotes e Assinaturas',
    path: `/professional/packages-assignments`,
    className: 'nav-text',
    icon: <ViewInAr />,
    active: 'false',
  },
  {
    title: 'Planos de Assinatura',
    path: `/professional/subscription`,
    className: 'nav-text',
    icon: <Assignment />,
    active: 'false',
  },
  {
    title: 'Configurações',
    path: `/professional/config`,
    className: 'nav-text',
    icon: <Settings />,
    active: 'false',
  },
  {
    title: 'Perfil Público',
    path: `/professional/profile`,
    className: 'nav-text',
    icon: <Person />,
    active: 'false',
  },
  {
    title: 'Sair',
    path: `/conectar-se`,
    className: 'nav-text',
    icon: <Logout />,
    active: 'true',
  },
];

export const sideBarClinic = [
  {
    title: 'Painel',
    path: `/clinic/dashboard`,
    className: 'nav-text',
    icon: <Home />,
    active: 'false',
  },
  {
    title: 'Clientes',
    path: `/clinic/patients`,
    className: 'nav-text',
    icon: <Group />,
    active: 'false',
  },
  {
    title: 'Profissionais',
    path: `/clinic/professionals`,
    className: 'nav-text',
    icon: <Group />,
    active: 'false',
  },
  {
    title: 'Agenda',
    path: `/clinic/calendar`,
    className: 'nav-text',
    icon: <CalendarMonth />,
    active: 'false',
  },
  {
    title: 'Perfil',
    path: `/clinic/profile`,
    className: 'nav-text',
    icon: <ReceiptLong />,
    active: 'false',
  },
  {
    title: 'Relatório de Consultas',
    path: `/clinic/schedules_report`,
    className: 'nav-text',
    icon: <EventNote />,
    active: 'false',
  },
  {
    title: 'Financeiro',
    path: `/clinic/finances`,
    className: 'nav-text',
    icon: <AttachMoney />,
    active: 'false',
  },
  {
    title: 'Gestão financeira',
    path: `/clinic/statement`,
    className: 'nav-text',
    icon: <RequestQuote />,
    active: 'false',
  },
  {
    title: 'Profissionais Cadastrados',
    path: `/clinic/all-professionals`,
    className: 'nav-text',
    icon: <Group />,
    active: 'false',
  },
  {
    title: 'Planos de Assinatura',
    path: `/clinic/subscription`,
    className: 'nav-text',
    icon: <Assignment />,
    active: 'false',
  },
  {
    title: 'Sair',
    path: `/conectar-se`,
    className: 'nav-text',
    icon: <Logout />,
    active: 'true',
  },
];
