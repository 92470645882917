/* eslint-disable react-hooks/exhaustive-deps */
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import useAuth from '../../hooks/auth';
import useEvent from '../../hooks/event';
import {
  BREAKPOINT_MD,
  BoxScroll,
  BoxScrollNavBar,
} from '../../styles/globalStyles';
import useWindowSize from '../../utils/WindowSize';
import { HeaderItemText, UserAvatar, UserImage } from '../Header/styles';
import ModalAcceptanceTerm, {
  AcceptanceNewTerm,
} from '../Modals/ModalAcceptanceTerm';

import {
  PLAN_LEVEL_CANCEL_ACCESS,
  PLAN_LEVEL_FREE_ACCESS,
  PLAN_LEVEL_LOW_ACCESS,
} from '../../utils/constantsLevelAccess';
import { ModalAlert } from '../Modals/ModalAlerts';
import {
  sideBarClient,
  sideBarClinic,
  sideBarProfessional,
} from './sideBarData';
import {
  Container,
  IconArea,
  Main,
  MenuSideBar,
  MenuSideBarWrapper,
  NavBarItemList,
  NavBarItemText,
  NavHelpButton,
  NavMenuItems,
  NavMenuMobile,
  NavMenuWeb,
  ProfileImage,
} from './styles';

export default function Sidebar({ children }) {
  const history = useHistory();
  const location = useLocation();
  const { signOut, user, updateProfessional, setLoading } = useAuth();
  const { setScheduleInfo } = useEvent();
  const { width } = useWindowSize();
  const [sidebar, setSidebar] = useState(false);
  const [activate, setActivate] = useState(location.pathname);
  const [acceptTerm, setAcceptTerm] = useState(
    user?.type === 'professional' && (!user?.term?.id || user?.term?.id === 1)
  );
  const [newTerm, setNewTerm] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;

  const messageType = useMemo(() => {
    if (user.type === 'clinic') {
      return 'Você precisa de um plano de assinatura válido para continuar utilizando a plataforma. Clique abaixo para assinar agora!';
    }
    if (user.type === 'professional' && !user?.clinic?.id) {
      return `Você precisa de um plano de assinatura válido para continuar utilizando a plataforma. Clique em "Mais detalhes" para ${PLAN_LEVEL_FREE_ACCESS.includes(user.planLevel) ? 'ver todos os benefícios...' : 'assinar novamente.'}`;
    }
    if (user.type === 'professional' && user?.clinic?.id) {
      return 'Parece que a clinica atrelada ao seu usuário cancelou a assinatura, para voltar a ter acesso a essa funcionalidade é necessário que a clínica renove a assinatura ou que você crie um novo perfil individual e assine um plano diponível.';
    }
  }, [user.type, user?.clinic]);

  useEffect(() => {
    setActivate(location.pathname);
  }, [location.pathname]);

  useEffect(() => {}, [activate]);

  const form = useFormik({
    initialValues: {
      read: false,
      accepted: false,
      termId: '',
    },
    onSubmit: async (values) => {
      if (values.read && values.accepted) {
        setLoading(true);
        try {
          const success = await updateProfessional(
            { termId: values.termId },
            user?.id
          );
          if (success) toast.success('Termo de uso aceito com sucesso');
          else toast.error('Não foi possível atualizar o termo de uso');
        } catch (error) {
          toast.error('Não foi possível atualizar o termo de uso');
        } finally {
          setNewTerm(false);
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (!form.values.read && form.values.accepted) {
      toast.info('Para prosseguir, leia e aceite o termo de uso.', {
        position: 'top-center',
        autoClose: 5000,
      });
      form.setFieldValue('accepted', false);
    }
  }, [form.values.accepted]);

  const pageMeeting =
    width > BREAKPOINT_MD && location.pathname.includes('meeting');

  const pageType = pageMeeting
    ? 'true'
    : type === 'professional'
      ? 'true'
      : 'false';

  const permissionNoAccess = useMemo(() => {
    if (
      PLAN_LEVEL_CANCEL_ACCESS.includes(user?.planLevel) &&
      type === 'professional'
    ) {
      return [
        `/${user?.type}/dashboard`,
        `/${user?.type}/patients`,
        `/${user?.type}/calendar`,
        `/${user?.type}/schedules_report`,
        `/${user?.type}/contents`,
        `/${user?.type}/messages`,
        `/${user?.type}/statement`,
        `/${user?.type}/packages-assignments`,
      ];
    }
    if (
      PLAN_LEVEL_CANCEL_ACCESS.includes(user?.planLevel) &&
      type === 'clinic'
    ) {
      return [
        `/${user?.type}/dashboard`,
        `/${user?.type}/patients`,
        `/${user?.type}/professionals`,
        `/${user?.type}/calendar`,
        `/${user?.type}/schedules_report`,
        `/${user?.type}/statement`,
      ];
    }
    if (
      PLAN_LEVEL_FREE_ACCESS.includes(user?.planLevel) &&
      type === 'professional'
    )
      return [
        `/${user?.type}/statement`,
        `/${user?.type}/packages-assignments`,
      ];
    return [];
  }, []);

  const navList = useMemo(() => {
    if (type === 'client') {
      if (width > BREAKPOINT_MD) {
        return sideBarClient.filter(
          (menuList) => !['Configurações'].includes(menuList.title)
        );
      }
      return sideBarClient;
    }
    if (type === 'professional') {
      const sideBarProfessionalMobile = user?.clinic?.id
        ? sideBarProfessional.filter(
            (menuList) =>
              ![
                'Configurações',
                'Perfil Público',
                'Pacotes e Assinaturas',
                'Planos de Assinatura',
              ].includes(menuList.title)
          )
        : sideBarProfessional.filter(
            (menuList) =>
              !['Configurações', 'Perfil Público'].includes(menuList.title)
          );
      if (width > BREAKPOINT_MD) {
        return sideBarProfessionalMobile;
      }

      return user?.clinic?.id
        ? sideBarProfessional.filter(
            (menuList) =>
              !['Pacotes e Assinaturas', 'Planos de Assinatura'].includes(
                menuList.title
              )
          )
        : sideBarProfessional;
    }
    if (type === 'clinic') {
      if (width > BREAKPOINT_MD) {
        return sideBarClinic.filter(
          (menuList) => !['Profissionais Cadastrados'].includes(menuList.title)
        );
      }
      return sideBarClinic;
    }
  }, [width, type, pageMeeting]);

  function ItemList({ item }) {
    return item.href ? (
      <NavHelpButton title="WhatsApp" href={item.href} target="_blank">
        <IconArea className={item.className}>{item.icon}</IconArea>
        <NavBarItemText className={item.className}>{item.title}</NavBarItemText>
      </NavHelpButton>
    ) : (
      <NavBarItemList
        active={item.path === activate ? 'true' : 'false'}
        onClick={() => {
          if (item.title === 'Sair') {
            setScheduleInfo(null);
            signOut();
          } else {
            if (
              PLAN_LEVEL_LOW_ACCESS.includes(user.planLevel) &&
              permissionNoAccess.includes(item.path)
            ) {
              setModalAlert(true);
            }
            setSidebar(false);
          }
        }}
        title={item.title}
        to={{
          pathname:
            PLAN_LEVEL_LOW_ACCESS.includes(user.planLevel) &&
            permissionNoAccess.includes(item.path)
              ? PLAN_LEVEL_CANCEL_ACCESS.includes(user.planLevel)
                ? `/${user.type}/profile`
                : `/${user.type}/dashboard`
              : item.path,
        }}
        typepage={pageType}
      >
        <IconArea className={item.className}>{item.icon}</IconArea>

        <NavBarItemText className={item.className}>{item.title}</NavBarItemText>
      </NavBarItemList>
    );
  }

  function NavBarMobile({ ...props }) {
    const { isVisible } = props;

    return (
      isVisible && (
        <NavMenuMobile typepage={pageType}>
          <MenuSideBarWrapper>
            <MenuSideBar onClick={() => setSidebar(false)}>
              <MenuOpenIcon
                fontSize="large"
                style={{
                  color: pageType === 'true' ? '#000' : '#fff',
                }}
              />
            </MenuSideBar>
          </MenuSideBarWrapper>
          <BoxScroll padding="3rem">
            <NavMenuItems>
              <ProfileImage
                typepage={pageType}
                meeting={
                  width > BREAKPOINT_MD && location.pathname.includes('meeting')
                }
              >
                <UserAvatar>
                  <UserImage size="100%" src={user?.avatar} />
                </UserAvatar>
                <HeaderItemText>{`${user?.firstName}`}</HeaderItemText>
              </ProfileImage>
              {navList?.map((item) => (
                <ItemList key={item.title} item={item} />
              ))}
            </NavMenuItems>
          </BoxScroll>
        </NavMenuMobile>
      )
    );
  }

  function NavBarWeb() {
    return (
      <NavMenuWeb active={sidebar}>
        <BoxScrollNavBar>
          <NavMenuItems>
            {navList?.map((item) => (
              <ItemList key={item.title} item={item} />
            ))}
          </NavMenuItems>
        </BoxScrollNavBar>
      </NavMenuWeb>
    );
  }

  if (newTerm) {
    return <AcceptanceNewTerm form={form} />;
  }

  if (acceptTerm) {
    return (
      <ModalAcceptanceTerm
        handleModal={setAcceptTerm}
        setNewTerm={setNewTerm}
      />
    );
  }

  return (
    <Main meeting={activate.includes('meeting')}>
      <Header sidebar={sidebar} setSidebar={setSidebar} />
      {width <= BREAKPOINT_MD || activate.includes('meeting') ? (
        <NavBarMobile isVisible={sidebar} />
      ) : (
        <NavBarWeb />
      )}
      <Container meeting={activate.includes('meeting')}>{children}</Container>

      {modalAlert && (
        <ModalAlert
          confirmPlan
          confirm={() => {
            if (user.type === 'professional') {
              history.push('/professional/subscription/update_plan');
            }
            setModalAlert(false);
          }}
          goBack={() => setModalAlert(false)}
          message={messageType}
        />
      )}
    </Main>
  );
}
