import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/auth';
import api from '../../../../services/api';
import validateCPF from '../../../../utils/validateCPF';
import BankAccount from '../../../Register/BankAccount';

export default function UpdateBankAccountForm() {
  const { setAuthData, user } = useAuth();
  const history = useHistory();

  const schema = Yup.object({
    documentNumber: Yup.string()
      .required('Número do documento é obrigatório')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test: function (value) {
          return validateCPF(value || '');
        },
      }),
    bankCode: Yup.string().required('Banco é obrigatório'),
    bankAg: Yup.string().required('Agência do conta é obrigatório'),
    agenciaDv: Yup.string().optional().nullable(),
    bankCc: Yup.string().required('Número da conta é obrigatório'),
    contaDv: Yup.string().optional().nullable(),
    accountType: Yup.string().required('Tipo da conta é obrigatório'),
    name: Yup.string().required('Nome Completo é obrigatório'),
  });

  const handleValues = async (values) => {
    try {
      const request = {
        attributes: {
          ...values,
        },
      };
      delete request.attributes['bankName'];

      await api.post(`/professionals/payment_accounts`, {
        data: request,
      });
      toast.success('Conta cadastrada com sucesso!');
      setAuthData({
        ...user,
        bankAccount: { ...values, id: 1, professionalId: user?.id },
      });
    } catch (error) {
      toast.error('Erro ao cadastrar valores!');
    }
  };

  function cancelForm() {
    history.push('/professional/dashboard');
  }

  const form = useFormik({
    initialValues: {
      documentNumber: '',
      name: '',
      bankAg: '',
      accountType: '',
      bankCc: '',
      bankCode: '',
      agenciaDv: null,
      contaDv: null,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        handleValues(values);
      }, 400);
    },
  });

  return (
    <FormikProvider value={form}>
      <BankAccount
        update={true}
        form={form}
        SubmitForm={(values) => handleValues(values)}
        cancelForm={cancelForm}
      />
    </FormikProvider>
  );
}
