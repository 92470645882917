export const PLAN_CANCEL = 0;
export const PLAN_FREE = 1;
export const PLAN_SILVER = 2;
export const PLAN_GOLD = 3;
export const PLAN_PLATINUM = 4;
export const PLAN_DEMO = 5;
export const PLAN_LEVEL_CANCEL_ACCESS = [PLAN_CANCEL];
export const PLAN_LEVEL_FREE_ACCESS = [PLAN_FREE];
export const PLAN_LEVEL_FULL_ACCESS = [
  PLAN_SILVER,
  PLAN_GOLD,
  PLAN_PLATINUM,
  PLAN_DEMO,
];
export const PLAN_LEVEL_LOW_ACCESS = [PLAN_CANCEL, PLAN_FREE];
