import React from 'react';
import useAuth from '../../hooks/auth';
import useEvent from '../../hooks/event';
import defaultAvatar from '../../images/noAvatar.png';
import convertCurrencyPtBr from '../../utils/convertCurrencyPtBr';
import getPlanName from '../../utils/getPlanName';
import {
  BFontBold,
  Button,
  ButtonActiveDiv,
  ColumnItem,
  ContainerPlans,
  FontPlanPrice,
  Main,
  MdFontBold,
  PlanDetails,
  SFontDark,
  UserImage,
} from './styles';

export function handleUserNames(user) {
  const social = user?.socialName || user?.social_name;
  const firstName = user?.firstName || user?.first_name;
  const lastName = user?.lastName || user?.last_name;
  const fullName = `${firstName} ${
    lastName?.split(' ')[lastName?.split(' ')?.length - 1]
  }`;
  return social || fullName;
}

export function CardsPlans({ ...props }) {
  const { infoCardCredit } = useEvent();
  const {
    active,
    isSubscription,
    message,
    handleModal,
    planName,
    planPrice,
    setFieldValue,
    submitPlan,
    tPlanName,
    values,
  } = props;
  const setActive = values === planName && active;

  return (
    <ContainerPlans>
      <ColumnItem>
        <BFontBold>
          {tPlanName === 'Grátis'
            ? 'Plano gratuito'
            : `Individual ${tPlanName}`}
        </BFontBold>
        <SFontDark>{message}</SFontDark>

        <FontPlanPrice>{convertCurrencyPtBr(planPrice)}</FontPlanPrice>

        {!active ? (
          <Button
            disabled={isSubscription ? true : setActive}
            active={active}
            name={tPlanName}
            value={planName}
            onClick={({ target }) => {
              submitPlan && setFieldValue(planName, infoCardCredit);
              setFieldValue('planName', target.value);
              handleModal(true);
            }}
          >
            Assinar Plano
          </Button>
        ) : (
          <ButtonActiveDiv active={active}>Plano Ativo</ButtonActiveDiv>
        )}
      </ColumnItem>
    </ContainerPlans>
  );
}

export function UserSubscribe({ ...props }) {
  const {
    isSubscription,
    handleModal,
    plans,
    setFieldValue,
    submitPlan,
    values,
  } = props;
  const { user } = useAuth();

  return (
    <Main>
      <CardsPlans
        active={
          user?.subscription?.id &&
          user?.subscription?.pagarmePlan === plans?.planName
        }
        isSubscription={isSubscription}
        message={plans?.message}
        handleModal={handleModal}
        planName={plans?.planName}
        planPrice={plans?.planPrice}
        setFieldValue={setFieldValue}
        submitPlan={submitPlan}
        tPlanName={getPlanName(plans?.planName)}
        values={values}
      />
      {plans?.detail && (
        <PlanDetails>
          <MdFontBold>Valor por profissional</MdFontBold>
          <MdFontBold>{convertCurrencyPtBr(plans?.detail)}</MdFontBold>
        </PlanDetails>
      )}
    </Main>
  );
}

export function UserAvatar({ src, size, search }) {
  return <UserImage src={src || defaultAvatar} size={size} search={search} />;
}
