import { Modal } from '@material-ui/core';
import React from 'react';
import IconWarning from '../../../images/alert-triangle-icon.svg';
import theme from '../../../theme';
import { SmallButton } from '../../Button';
import {
  Container,
  ContainerButtons,
  Icon,
  IconArea,
  ModalContainer,
  TextAlert,
  TitleAlert,
  TitleWrapper,
} from './styles';

export function ModalSubscription({
  buttonsHeight = '2.5rem',
  confirm,
  goBack,
  message,
  title = 'Atenção',
  type = 'confirm',
}) {
  return (
    <Modal open>
      <ModalContainer>
        <Container>
          <TitleWrapper>
            <IconArea>
              <Icon src={IconWarning} alt="Atenção" />
            </IconArea>
            <TitleAlert>{title}</TitleAlert>
          </TitleWrapper>
          <TextAlert>{message}</TextAlert>
          <ContainerButtons>
            <SmallButton
              width="30%"
              height={buttonsHeight}
              color={type === 'confirm' ? theme.pink : theme.pink}
              type="button"
              title="Voltar"
              onClick={goBack}
            />
            <SmallButton
              width="30%"
              height={buttonsHeight}
              color={type === 'confirm' ? theme.primary : theme.pink}
              type="button"
              title="Confirmar"
              onClick={(data) => confirm(data)}
            />
          </ContainerButtons>
        </Container>
      </ModalContainer>
    </Modal>
  );
}
