import { Modal, Typography } from '@material-ui/core';
import valid from 'card-validator';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { CloseIcon, IconAreaModal } from '../../../styles/globalStyles';
import theme from '../../../theme';
import {
  PLAN_CANCEL,
  PLAN_LEVEL_CANCEL_ACCESS,
} from '../../../utils/constantsLevelAccess';
import getPlanName from '../../../utils/getPlanName';
import PaymentForm from '../PaymentForm';
import {
  BoxWrapper,
  Button,
  CheckBoxWrapper,
  LinkWrapper,
  Main,
} from '../styles';
import TablePlan from './TablePlan';

export function SubscribeCard({ ...props }) {
  const { item, values } = props;
  return (
    <CheckBoxWrapper
      full={values.planName === (item.value?.data || item.title)}
      {...props}
    >
      <LinkWrapper
        full={values.planName === item.value?.data}
        href="https://allminds.app/specialist#plans"
        target="_blank"
      >
        saiba mais
      </LinkWrapper>
    </CheckBoxWrapper>
  );
}

export function SubscribeFormik({ ...props }) {
  const history = useHistory();
  const { user } = useAuth();
  const { infoCardCredit } = useEvent();

  const {
    plan,
    currentPlan,
    creditCards,
    handleContinue,
    loading,
    setLoading,
  } = props;
  const [modal, setModal] = useState(false);

  const schema = Yup.object({
    planName: Yup.string().when('userType', {
      is: (value) => value !== 'client',
      then: Yup.string().nullable().required('Plano é obrigatório.'),
      otherwise: Yup.string().nullable(),
    }),
    payment_method: Yup.string().required('Forma de pagamento é obrigatório.'),
    cardNumber: Yup.string().test(
      'test-number',
      'Cartão inválido',
      (value) => valid.number(value).isValid
    ),
    cardHolderName: Yup.string().required('Nome é obrigatório.'),
    cardCvv: Yup.string().test(
      'test-cvv',
      'Código de segurança inválido',
      (value) => valid.cvv(value).isValid
    ),
    cardExpiration_date: Yup.string().test(
      'test-date',
      'Data inválida',
      (value) => valid.expirationDate(value).isValid
    ),
  });

  const validatedDateEnd = moment(
    currentPlan?.attributes?.currentPeriodEnd,
    'DD/MM/YYYY'
  ).isSame(moment(), 'day');

  const handleModal = (planName) => {
    const creditCardDefault = creditCards?.find(
      (item) => item.attributes.default === true
    );
    const validCard = infoCardCredit || creditCardDefault?.id;
    const validPlan =
      user?.subscription?.pagarmePlan?.includes('founder_gold') ||
      user?.subscription?.pagarmePlan?.includes('founder_platinum');

    if (planName === 'free') {
      setModal(true);
    } else if (
      ['silver', 'gold', 'platinum'].includes(planName) &&
      !validatedDateEnd
    ) {
      toast.error(
        `Não é possível alterar o plano antes do termino da data final ${currentPlan?.attributes?.currentPeriodEnd}`
      );
    } else if (validCard && !validPlan) {
      setModal(true);
    } else {
      validPlan
        ? toast.error('Atualização de plano indisponível')
        : toast.error(
            <div>
              É necessário adicionar um cartão de crédito para efetivar a
              assinatura de um plano.
              <button
                onClick={() =>
                  history.push({
                    pathname: '/professional/config',
                    state: { selected: 'Formas de pagamento' },
                  })
                }
                style={{
                  background: theme.primary,
                  border: 'none',
                  borderRadius: '0.5rem',
                  color: theme.white,
                  cursor: 'pointer',
                  marginLeft: '1rem',
                  padding: '0.25rem 1rem',
                }}
              >
                Adicionar cartão de crédito
              </button>
            </div>,
            { autoClose: false }
          );
    }
  };

  const handleClick = async (values) => {
    setLoading(true);
    const creditCardDefault = creditCards?.find(
      (item) => item.attributes.default === true
    );

    try {
      const request = {
        data: {
          type: 'subscription',
          attributes: {
            planName: values.planName,
            creditCardId: infoCardCredit || creditCardDefault?.id,
          },
        },
      };
      let data;
      if (user?.planLevel === PLAN_CANCEL || !user?.subscription?.id) {
        const { data: subscriptionData } = await api.post(
          `${user.type}s/subscriptions`,
          request
        );
        data = subscriptionData;
      } else {
        request.data.id = String(user?.subscription.id);
        const { data: subscriptionData } = await api.put(
          `${user.type}s/subscriptions/${user?.subscription?.id}`,
          request
        );
        data = subscriptionData;
      }

      if (data.data) {
        toast.success(
          `Plano ${getPlanName(values?.planName)} assinado com sucesso! Por favor autentique-se novamente.`
        );
        const updateUser = {
          ...user,
          subscription: data.data.attributes,
        };

        localStorage.setItem('user', JSON.stringify(updateUser));
      }
    } catch (error) {
      toast.error('Não foi possível cadastrar assinatura');
    } finally {
      setLoading(false);
      setModal(false);
    }
  };

  const handleValues = (values) => {
    values.cardNumber = values.cardNumber?.replace(/\s+/g, '');
    values.cardExpiration_date = values.cardExpiration_date?.replace(
      /[/]/g,
      ''
    );
    values.setDefault = creditCards?.length === 0;
    handleContinue(values);
  };

  const form = useFormik({
    initialValues: {
      planName: user?.subscription?.pagarmePlan,
      userType: user?.type,
      payment_method: 'Cartão de Crédito',
      cardNumber: '',
      cardHolderName: '',
      cardCvv: '',
      cardExpiration_date: '',
      setDefault: !infoCardCredit || creditCards?.length === 0 || false,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      handleValues(values);
    },
  });

  const { handleSubmit, values, setFieldValue } = form;

  return (
    <Main className="subscribe">
      <FormikProvider value={form}>
        {plan ? (
          <section className="update-plan">
            <TablePlan
              currentPlan={currentPlan}
              handleModal={handleModal}
              setFieldValue={setFieldValue}
              values={values}
            />
            <div className="description">
              <p>
                ➀ : Aumento de rendimentos por meio da venda de treinamentos e
                cursos para a AllMinds e seus usuários.
              </p>

              <p>
                ➁ : Na página de busca por profissionais ("Nossos
                Especialistas"), quando os usuários fazem uma pesquisa, o seu
                perfil aparecerá no topo, meio ou embaixo na listagem de
                profissionais, a depender do seu plano e dos filtros
                selecionados pelo usuário.
              </p>

              <p>
                ➂ : Custo de processamento de pagamento varia de acordo com a
                forma de pagamento selecionada e é abatido do valor pago pelo
                usuário (cartão de crédito a vista - 3,2% ; Boleto - R$ 3,19 e
                Pix - 1,05% ). Saques tem uma taxa de R$ 3,67, exceto para o
                Bradesco (gratuito).
              </p>
            </div>
          </section>
        ) : (
          <form className="subscribe-form" onSubmit={handleSubmit}>
            <section className="--input-wrapper">
              <PaymentForm form={form} loading={loading} />
            </section>
          </form>
        )}
      </FormikProvider>

      {modal && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon
                onClick={() => {
                  setModal(false);
                }}
              />
            </IconAreaModal>
            <div className="--content">
              <Typography variant="h6">
                Assinatura do plano {getPlanName(values?.planName)}
              </Typography>
              <Typography variant="p" sx={{ mt: 2 }}>
                {PLAN_LEVEL_CANCEL_ACCESS.includes(user?.planLevel)
                  ? 'Você está reativando sua assinatura. Deseja continuar?'
                  : `Você está adquirindo o plano ${getPlanName(values?.planName)}. Deseja continuar?`}
              </Typography>
            </div>
            <div className="--buttons">
              <Button
                onClick={() => {
                  setModal(false);
                  setFieldValue('planName', '');
                }}
                secondary={'true'}
              >
                Cancelar
              </Button>
              <Button onClick={() => handleClick(values)}>Confirmar</Button>
            </div>
          </BoxWrapper>
        </Modal>
      )}
    </Main>
  );
}
